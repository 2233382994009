import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import {
  createFormBody,
  stringIsNullOrEmpty,
  isObjectEmpty,
} from "../../util/Util";
import { showMessage, setSaveAdminLog } from "../../redux/AppAction";
import ApiEngine from "../../util/ApiEngine";
import { useForm } from "react-hook-form";
import moment from "moment";
import DateTime from "react-datetime";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>
const ReferralDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const { register, handleSubmit, errors, setValue } = useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [referralCodes, setReferralCodes] = useState([]);
  const [referralCodeId, setReferralCodeId] = useState();
  const [referralCodeDetail, setReferralCodeDetail] = useState({});
  const [expiryDate, setExpiryDate] = useState(null);
  var _dispatch = useDispatch();
  let _history = useHistory();

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_REFERRAL_CODES + "?memberId=" + props.id
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setReferralCodes(responseJson[ApiKey._API_DATA_KEY]);
    }

    let productResponseJson = await ApiEngine.get(
      ApiUrl._API_GET_GROUP_PRODUCT_WITH_MAX_RATES + "?memberId=" + props.id
    );

    if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
      setProducts(productResponseJson[ApiKey._API_DATA_KEY]);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function getReferralCodeDetail() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_REFERRAL_CODES +
        "?id=" +
        referralCodeId +
        "&memberId=" +
        props.id
    );

    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      setModalVisible(true);
      setReferralCodeDetail(responseJson[ApiKey._API_DATA_KEY][0]);
      setExpiryDate(
        moment(responseJson[ApiKey._API_DATA_KEY][0]["expiryDate"]).format(
          "YYYY-MM-DD"
        )
      );
    } else {
      _dispatch(showMessage(false, t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!modalVisible) {
      setReferralCodeId();
      setReferralCodeDetail({});
    }
  }, [modalVisible]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!isObjectEmpty(referralCodeDetail)) {
      referralCodeDetail.memberReferralInfos.map((info) => {
        setValue("bonus-" + info.productId, info.bonus);
        setValue("commission-" + info.productId, info.commission);
      });
    }
  }, [referralCodeDetail]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(referralCodeId)) {
      getReferralCodeDetail();
    }
  }, [referralCodeId]);

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data) => {
    let params = {
      memberId: props.id,
      expiryDate: expiryDate,
    };

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    if (!stringIsNullOrEmpty(referralCodeId)) {
      params["id"] = referralCodeId;
      _dispatch(setSaveAdminLog("EDIT_BO_GENERATE_QR", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_GENERATE_QR", _history.location.pathname, params));
    }

    let formBody = createFormBody(params);
    let responseJson = await ApiEngine.post(
      ApiUrl._API_GENERATE_REFERRAL_CODE,
      formBody
    );

    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setModalVisible(false);
      init();
    }
  };

  async function deleteReferralCode(referralCodeId) {
    let params = {
      referralCodeId: referralCodeId,
    };
    let formBody = createFormBody(params);
    let responseJson = await ApiEngine.post(
      ApiUrl._API_DELETE_REFERRAL_CODE,
      formBody
    );
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setModalVisible(false);
      init();
    }
  }

  return (
    <div>
      <Modal
        isOpen={modalVisible}
        toggle={() => {
          setModalVisible(!modalVisible);
        }}
        centered={true}
      >
        <form onSubmit={handleSubmit(submitForm)}>
          <ModalHeader
            toggle={() => {
              setModalVisible(!modalVisible);
            }}
          >
            {t(
              !stringIsNullOrEmpty(referralCodeId) ? "EDIT_QR" : "GENERATE_QR"
            )}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              {products.map((product, productIndex) => {
                return (
                  <div className="col-lg-12" key={"referral" + productIndex}>
                    <label>
                      <b>{product.productName}</b>
                    </label>
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        {t("BONUS")} (%)
                      </label>
                      <div className="col-md-7">
                        <input
                          type="number"
                          className="form-control m-b-5"
                          ref={register({
                            required: "PLEASE_ENTER_BONUS",
                            min: {
                              value: 0,
                              message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                            max: {
                              value: 100,
                              message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                            },
                            max: {
                              value: product.maxBonus,
                              message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN",
                            },
                          })}
                          name={"bonus-" + product.productId}
                          defaultValue={0}
                        />
                        {errors["bonus-" + product.productId] && (
                          <div className="invalid-feedback">
                            {t(errors["bonus-" + product.productId].message, {
                              value: product.maxBonus,
                            })}
                          </div>
                        )}
                      </div>
                      <label className="col-md-4 col-form-label">
                        {t("COMMISSION")} (%)
                      </label>
                      <div className="col-md-7">
                        <input
                          type="number"
                          step="0.1"
                          className="form-control"
                          ref={register({
                            required: "PLEASE_ENTER_COMMISSION",
                            min: {
                              value: 0,
                              message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                            max: {
                              value: 100,
                              message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                            },
                            max: {
                              value: product.maxCommission,
                              message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN",
                            },
                          })}
                          name={"commission-" + product.productId}
                          defaultValue={0}
                        />
                        {errors["commission-" + product.productId] && (
                          <div className="invalid-feedback">
                            {t(
                              errors["commission-" + product.productId].message,
                              { value: product.maxCommission }
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <hr />
                  </div>
                );
              })}
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    {t("EXPIRY_DATE")}
                  </label>
                  <div className="col-md-7">
                    <DateTime
                      inputProps={{ className: "form-control", readOnly: true }}
                      name="endDate"
                      dateFormat="YYYY-MM-DD"
                      closeOnSelect={true}
                      timeFormat={false}
                      value={expiryDate}
                      onChange={(e) => {
                        setExpiryDate(e.format("YYYY-MM-DD"));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {!stringIsNullOrEmpty(referralCodeId) && <><button type="button" className="btn btn-primary" onClick={() => deleteReferralCode(referralCodeId)}>
              {t("DELETE")}
            </button>{" "}</>}
            <button type="submit" className="btn btn-primary">
              {t(!stringIsNullOrEmpty(referralCodeId) ? "EDIT" : "GENERATE")}
            </button>{" "}
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                setModalVisible(!modalVisible);
              }}
            >
              {t("CANCEL")}
            </button>
          </ModalFooter>
        </form>
      </Modal>
      <div className="row">
        <div className="col-lg-12 m-b-5 text-right">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setModalVisible(!modalVisible)}
          >
            {t("GENERATE_QR")}
          </button>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            {referralCodes.length > 0 &&
              referralCodes.map((referralCode, codeIndex) => {
                return (
                  <div className="col-lg-2">
                    <div className="card" style={{ alignItems: "center" }}>
                      <div
                        className="card-body"
                        style={{ textAlign: "center", paddingTop: "0px" }}
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "0.5em",
                              }}
                            >
                              <b>
                                {t("CODE")}: {referralCode.code}
                              </b>
                            </div>
                          </div>
                          {referralCode.memberReferralInfos.map(
                            (info, infoIndex) => {
                              return (
                                <div
                                  className="card"
                                  style={{ width: "100%", margin: "5px" }}
                                  key={"info" + infoIndex}
                                >
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <b>{info.productName}</b>
                                    </div>
                                    <div className="col-lg-6">{t("BONUS")}</div>
                                    <div className="col-lg-6">
                                      {info.bonus} %
                                    </div>
                                    <div className="col-lg-6">
                                      {t("COMMISSION")}
                                    </div>
                                    <div className="col-lg-6">
                                      {info.commission} %
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                          <div
                            className="col-lg-12"
                            style={{ textAlign: "center" }}
                          >
                            <label>
                              {t("EXPIRY_DATE")} :{" "}
                              {moment(referralCode.expiryDate).format(
                                "DD-MM-YYYY"
                              )}
                            </label>
                          </div>
                          <div className="col-lg-12">
                            <button
                              type="button"
                              className="btn btn-warning"
                              onClick={() => setReferralCodeId(referralCode.id)}
                            >
                              {t("EDIT")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {referralCodes.length == 0 && (
              <div style={{ textAlign: "center", width: "100%" }}>
                <h3>{t("NO_DATA_FOUND")}</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralDetail;
