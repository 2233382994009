import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
} from "../../util/Constant";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { createFormBody, createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util.js";
import moment from 'moment';
import Dropzone from "react-dropzone";

/// <summary>
/// Author : -
/// </summary>

const PromptPaySettings = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const [initCheck, setInitCheck] = useState(false);
  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
  } = useForm();

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    _dispatch(setBusy());
    init();
    setInitCheck(true);
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=settings";
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      data.map((keyValuePair, index) => {
        if (keyValuePair['key'] === 'PromptPayStartTime') {
          let startTimeStr = keyValuePair['value'];
          setStartHour(moment(startTimeStr, 'HH:mm:ss').get('hours'));
          setStartMinute(moment(startTimeStr, 'HH:mm:ss').get('minutes'));
        }
        if (keyValuePair['key'] === 'PromptPayEndTime') {
          let endTimeStr = keyValuePair['value'];
          setEndHour(moment(endTimeStr, 'HH:mm:ss').get('hours'));
          setEndMinute(moment(endTimeStr, 'HH:mm:ss').get('minutes'));
        }
        if (keyValuePair['key'] === 'PromptPayStatus') {
          let trueOrFalse = (keyValuePair['value'] === 'true');
          setStatus(trueOrFalse);
        }
      })
    }
    _dispatch(setIdle());
  }

  const submitForm = async (data) => {
    let params = {
      'startHour': data.startHour.length === 2 ? data.startHour : "0" + data.startHour,
      'startMinute': data.startMinute.length === 2 ? data.startMinute : "0" + data.startMinute,
      'endHour': data.endHour.length === 2 ? data.endHour : "0" + data.endHour,
      'endMinute': data.endMinute.length === 2 ? data.endMinute : "0" + data.endMinute,
      'status': data.status
    };
    _dispatch(setSaveAdminLog("EDIT_BO_PROMPT_PAY_SETTINGS", _history.location.pathname, params));
    let formBody = createMultiPartFormBody(params);
    let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_SETTINGS + "?type=PromptPay";
    let responseJson = await ApiEngine.post(apiUrl, formBody);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
  }

  return (
    <div>
      <h1 className="page-header">
        {t("PROMPT_PAY_SETTINGS")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          {initCheck && <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="col-lg-8">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("START_TIME")}</b>
                    </label>
                    <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control"
                          name="startHour"
                          value={startHour}
                          onChange={(e) => setStartHour(e.target.value)}
                          min={0}
                          max={23}
                          ref={register({
                            min: {
                              value: 0,
                              message:
                                "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                            max: {
                              value: 23,
                              message:
                                "PLEASE_ENTER_VALUE_NOT_MORE_THAN_23",
                            },
                          })}
                        />
                        {errors.startHour && (
                          <div className="invalid-feedback">
                            {t(errors.startHour.message)}
                          </div>
                        )}
                      </div>
                      :
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control"
                          name="startMinute"
                          value={startMinute}
                          onChange={(e) => setStartMinute(e.target.value)}
                          min={0}
                          max={59}
                          ref={register({
                            min: {
                              value: 0,
                              message:
                                "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                            max: {
                              value: 59,
                              message:
                                "PLEASE_ENTER_VALUE_NOT_MORE_THAN_59",
                            },
                          })}
                        />
                        {errors.startMinute && (
                          <div className="invalid-feedback">
                            {t(errors.startMinute.message)}
                          </div>
                        )}
                      </div>
                      (GMT +8)
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("END_TIME")}</b>
                    </label>
                    <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control"
                          name="endHour"
                          value={endHour}
                          onChange={(e) => setEndHour(e.target.value)}
                          min={0}
                          max={23}
                          ref={register({
                            min: {
                              value: 0,
                              message:
                                "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                            max: {
                              value: 23,
                              message:
                                "PLEASE_ENTER_VALUE_NOT_MORE_THAN_23",
                            },
                          })}
                        />
                        {errors.endHour && (
                          <div className="invalid-feedback">
                            {t(errors.endHour.message)}
                          </div>
                        )}
                      </div>
                      :
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control"
                          name="endMinute"
                          value={endMinute}
                          onChange={(e) => setEndMinute(e.target.value)}
                          min={0}
                          max={59}
                          ref={register({
                            min: {
                              value: 0,
                              message:
                                "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                            max: {
                              value: 59,
                              message:
                                "PLEASE_ENTER_VALUE_NOT_MORE_THAN_59",
                            },
                          })}
                        />
                        {errors.endMinute && (
                          <div className="invalid-feedback">
                            {t(errors.endMinute.message)}
                          </div>
                        )}
                      </div>
                      (GMT +8)
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("STATUS")}</b>
                    </label>
                    <div className="col-md-7">
                      <div className="switcher">
                        <input
                          type="checkbox"
                          name="status"
                          id="status"
                          onChange={(e) => setStatus(e.target.checked)}
                          value={true}
                          checked={status}
                          ref={register}
                        />
                        <label htmlFor="status"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>}
        </div>
      </div>
    </div>
  );
};

export default withRouter(PromptPaySettings);
