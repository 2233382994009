import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
    ApiKey,
    ApiUrl,
    TransactionType,
    PanelType,
    ExternalUrl,
    LanguageKey,
    BankingChannel,
    DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { AsyncPaginate } from "react-select-async-paginate";
import ApiEngine from "../../util/ApiEngine";
import ReactHtmlParser from "react-html-parser";

import PinInput from 'react-pin-input';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import moment from "moment";
/*import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";*/

/// <summary>
/// Author : -
/// </summary>

const Withdrawal = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _location = useLocation();
    const _dispatch = useDispatch();
    const {
        register,
        unregister,
        handleSubmit,
        errors,
        setValue,
        trigger,
    } = useForm();
    const [bankOption, setBankOption] = useState([]);
    const [auditBankOption, setAuditBankOption] = useState([]);
    const [bankAccountOption, setBankAccountOption] = useState([]);
    const [bankName, setBankName] = useState("");
    const [auditBankId, setAuditBankId] = useState("");
    const [auditBankAccountId, setAuditBankAccountId] = useState("");
    const [memberOption, setMemberOption] = useState([]);
    const [memberId, setMemberId] = useState("");
    const [memberBalance, setMemberBalance] = useState("");
    const [fullScreen, setFullScreen] = useState(false);
    const [selectedMember, setSelectedMember] = useState();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);

    const [showPinEntry, setShowPinEntry] = useState(false);

    const [startOffTime, setStartOffTime] = useState("");
    const [endOffTime, setEndOffTime] = useState("");
    const [withdrawalStatus, setWithdrawalStatus] = useState(true);
    const [enteredWithdrawalData, setEnteredWithdrawalData] = useState({});
    const [enteredWithdrawalE, setEnteredWithdrawalE] = useState({});

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        register({ name: "memberId" }, { required: "PLEASE_SELECT_MEMBER" });
        register({ name: "bankName" }, { required: "PLEASE_SELECT_BANK" });
        register({ name: "auditBankId" }, { required: "PLEASE_SELECT_BANK" });
        register(
            { name: "auditBankAccountId" },
            { required: "PLEASE_SELECT_BANK_ACCOUNT" }
        );

        /// For The Size Difference of Modal And Page
        if (props.id) {
            setFullScreen(true);
        }

        init();
        initFormField();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(auditBankId)) {
            var apiUrl = ApiUrl._API_GET_BANK_ACCOUNT_BY_BANK_ID;
            apiUrl += "?bankId=" + auditBankId + "&all=true";//"&withdrawal=" + true;
            fetch(apiUrl, {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        const bankAccountList = [];
                        responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
                            if (bankAccount.withdrawalStatus) {
                                bankAccountList.push({
                                    label: bankAccount.accountNumber,
                                    value: bankAccount.id,
                                    min: bankAccount.minimumWithdrawal,
                                    max: bankAccount.maximumWithdrawal,
                                });
                            }
                        });

                        setBankAccountOption(bankAccountList);
                        if (bankAccountList.length == 0) {
                            _dispatch(showMessage(false, t("NO_BANK_ACCOUNT_WITH_WITHDRAWAL_STATUS_OPEN")));
                        }
                    }
                });
        }
    }, [auditBankId]);

    /// <summary>
    /// Author : -
    /// Editted : -
    /// Added fetch to get IP
    /// </summary>
    async function init() {
        _dispatch(setBusy());
        await fetch(ApiUrl._API_GET_USER_BANK, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    const bankList = [];
                    responseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
                        bankList.push({ label: bank.bankName, value: bank.id });
                    });
                    setAuditBankOption(bankList);
                    setBankOption(bankList);
                }
            });

        _dispatch(setIdle());
    }

    /// <summary>
    /// Author : Wong
    /// </summary>
    async function getMemberList(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(
            `${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${
            (page - 1) * DEFAULT_PAGE_SIZE
            }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY]["data"];
            let formattedData = data.map((m) => {
                return { label: m.username, value: m.id, balance: m.primaryCredit };

            });
            { console.log(formattedData) }
            return {
                options: formattedData,
                hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
                additional: {
                    page: page + 1,
                },
            };
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const initFormField = () => {
        /*if (stringIsNullOrEmpty(props.id)) {
            setMemberId("");
            setValue("memberId", "");
        } else {
            setMemberId(props.id);
            setValue("memberId", props.id);
        }*/
        setSelectedMember("");
        setMemberId("");
        setValue("memberId", "");
        setMemberBalance("");
        setBankAccountOption([]);
        setAuditBankId("");
        setAuditBankAccountId("");
        setBankName("");
        setValue("auditBankId", "");
        setValue("auditBankAccountId", "");
        setValue("bankName", "");
    };

    /// <summary>
    /// Author : -
    /// Editted : -
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            transactionTypeId: TransactionType._WITHDRAWAL,
            memberId: memberId,
            amount: data.amount,
            channel: BankingChannel._CHANNEL_OFFLINE_BANKING,
            panel: PanelType._BACKOFFICE,
            bankName: bankName,
            bankAccountNumber: data.bankAccountNumber,
            bankAccountHolderName: data.bankAccountHolderName,
            auditBankAccountId: auditBankAccountId,
        };
        _dispatch(setSaveAdminLog("CREATE_TRANSACTION_WITHDRAWAL",_history.location.pathname, params));
        let formBody = createFormBody(params);
        _dispatch(setBusy());

        fetch(ApiUrl._API_CREATE_TRANSACTION, {
            method: ApiKey._API_POST,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    e.target.reset();
                    //initFormField();
                    window.location.reload();

                    if (props.onComplete != null) {
                        props.onComplete();
                    }
                }
                else {
                    setShowPinEntry(false);
                }
                _dispatch(setIdle());
                _dispatch(
                    showMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        t(responseJson[ApiKey._API_MESSAGE_KEY])
                    )
                );
            });
    };

    async function checkSystemSettings() {
        let settingsJson = await ApiEngine.get(ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=withdrawal");
        if (settingsJson[ApiKey._API_SUCCESS_KEY] && settingsJson[ApiKey._API_DATA_KEY] != null) {
            let data = settingsJson[ApiKey._API_DATA_KEY];
            let startTime;
            let endTime;
            let status = false;
            data.map((keyValuePair, index) => {
                if (keyValuePair['key'] === "WithdrawalStartTime") {
                    startTime = keyValuePair['value'];
                }
                if (keyValuePair['key'] === "WithdrawalEndTime") {
                    endTime = keyValuePair['value'];
                }
                if (keyValuePair['key'] === "WithdrawalStatus") {
                    status = keyValuePair['value'] === 'true';
                }
            });
            if (moment(startTime) >= moment(endTime)) {
                setStartOffTime(endTime);
                setEndOffTime(startTime);
            }
            else {
                setStartOffTime(startTime);
                setEndOffTime(endTime);
            }

            setWithdrawalStatus(status);

            return status;
        }
    }
    
    async function onPinSubmit(data, e) {
        // verify pin
        let params = { transactionPinNumber: data };
        let formBody = createFormBody(params);
        let responseJson = await ApiEngine.post(ApiUrl._API_VERIFY_USER_TRANSACTION_PIN_NUMBER, formBody);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            submitForm(enteredWithdrawalData, enteredWithdrawalE);
        }
        else {
            _dispatch(showMessage(false, t("INCORRECT") + " " + t("PIN_NUMBER")));
            setShowPinEntry(false);
        }
    }

    async function checkPinValue(data, e) {
        // check if pin has 4 digits (valid pin)
        // if true then submit pin
        if (data.length == 4) {
            onPinSubmit(data);
        }
        
    }

    async function onSubmit(data, e) {
        setEnteredWithdrawalData(data);
        setEnteredWithdrawalE(e);
        
        //setShowPinEntry(true);
        
        let tempStatus = await checkSystemSettings();
        if (tempStatus) {
            setShowPinEntry(true);
        }
        else {
            _dispatch(showMessage(false, t("WITHDRAWAL") + " " + t("CURRENTLY_OFFLINE")));
            // props.parentInit();
        }
    }

    return (
        <div>
            <h1 className="page-header">
                {t("WITHDRAWAL")}
                {props.id ? (
                    <button
                        style={{ float: "right" }}
                        className="btn btn-inverse"
                        onClick={() => {
                            props.toggleModal(false);
                        }}
                    >
                        <i className="fa fa-times"></i>
                    </button>
                ) : (
                        <NavigationButton history={_history} />
                    )}
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("USERNAME")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <AsyncPaginate
                                                    name="memberId"
                                                    placeholder={
                                                        selectedMember ? selectedMember.label : ""
                                                    }
                                                    isDisabled={!stringIsNullOrEmpty(props.id)}
                                                    debounceTimeout={250}
                                                    loadOptions={getMemberList}
                                                    additional={{
                                                        page: 1,
                                                    }}
                                                    value={selectedMember}
                                                    onChange={(e) => {
                                                        setValue("memberId", e.value);
                                                        setMemberId(e.value);
                                                        setMemberBalance(e.balance);
                                                        setSelectedMember(e);
                                                        trigger("memberId");
                                                    }}
                                                />
                                                {errors.memberId && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.memberId.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("BANK")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <Select
                                                    name="bankName"
                                                    options={bankOption}
                                                    placeholder={
                                                        bankOption.filter(
                                                            (option) => option.label == bankName
                                                        )[0] !== undefined
                                                            ? bankOption.filter(
                                                                (option) => option.label == bankName
                                                            )[0].label
                                                            : ""
                                                    }
                                                    value={bankOption.filter(
                                                        (option) => option.label == bankName
                                                    )}
                                                    onChange={(e) => {
                                                        setValue("bankName", e.value);
                                                        setBankName(e.label);
                                                        trigger("bankName");
                                                    }}
                                                />
                                                {errors.bankName && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.bankName.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("BANK_ACCOUNT_NUMBER")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder={t("PLEASE_ENTER_BANK_ACCOUNT_NUMBER")}
                                                    name="bankAccountNumber"
                                                    ref={register({
                                                        required: "PLEASE_ENTER_BANK_ACCOUNT_NUMBER",
                                                    })}
                                                />
                                                {errors.bankAccountNumber && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.bankAccountNumber.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("BANK_ACCOUNT_HOLDER_NAME")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t(
                                                        "PLEASE_ENTER_BANK_ACCOUNT_HOLDER_NAME"
                                                    )}
                                                    name="bankAccountHolderName"
                                                    ref={register({
                                                        required: "PLEASE_ENTER_BANK_ACCOUNT_HOLDER_NAME",
                                                    })}
                                                />
                                                {errors.bankAccountHolderName && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.bankAccountHolderName.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("AUDIT_BANK")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <Select
                                                    name="auditBankId"
                                                    options={auditBankOption}
                                                    placeholder={
                                                        auditBankOption.filter(
                                                            (option) => option.value == auditBankId
                                                        )[0] !== undefined
                                                            ? auditBankOption.filter(
                                                                (option) => option.value == auditBankId
                                                            )[0].label
                                                            : ""
                                                    }
                                                    value={auditBankOption.filter(
                                                        (option) => option.value == auditBankId
                                                    )}
                                                    onChange={(e) => {
                                                        setValue("auditBankId", e.value);
                                                        setAuditBankId(e.value);
                                                      
                                                        trigger("auditBankId");
                                                    }}
                                                />
                                                {errors.auditBankId && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.auditBankId.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("AUDIT_BANK_ACCOUNT")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <Select
                                                    name="auditBankAccountId"
                                                    options={bankAccountOption}
                                                    placeholder={
                                                        bankAccountOption.filter(
                                                            (option) => option.value == auditBankAccountId
                                                        )[0] !== undefined
                                                            ? bankAccountOption.filter(
                                                                (option) => option.value == auditBankAccountId
                                                            )[0].label
                                                            : ""
                                                    }
                                                    value={bankAccountOption.filter(
                                                        (option) => option.value == auditBankAccountId
                                                    )}
                                                    onChange={(e) => {
                                                        setValue("auditBankAccountId", e.value);
                                                        setAuditBankAccountId(e.value);
                                                        setMin(e.min);
                                                        setMax(e.max);
                                                        trigger("auditBankAccountId");
                                                    }}
                                                />
                                                {errors.auditBankAccountId && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.auditBankAccountId.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("AMOUNT")} <label style={{ color: "blue", paddingLeft: "5px" }}>
                                                    {memberBalance !== undefined
                                                        ? "(" + memberBalance +
                                                        ")"
                                                        : ""}
                                                </label>
                                                </b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder={t("PLEASE_ENTER_AMOUNT")}
                                                    name="amount"
                                                    defaultValue={0}
                                                    ref={register({
                                                        required: "PLEASE_ENTER_AMOUNT",
                                                        min: {
                                                            value: 0,
                                                            message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                        },
                                                        max: {
                                                            value:
                                                                memberBalance !== undefined
                                                                    ? memberBalance
                                                                    : 0,
                                                            message: ReactHtmlParser(t("PLEASE_ENTER_VALUE_NOT_MORE_THAN", { value: memberBalance }))
                                                        },
                                                    })}
                                                />
                                                {errors.amount && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.amount.message, {
                                                            value:
                                                                memberOption.filter(
                                                                    (option) => option.value == memberId
                                                                )[0] !== undefined
                                                                    ? memberOption.filter(
                                                                        (option) => option.value == memberId
                                                                    )[0].balance
                                                                    : 0,
                                                        })}
                                                      
                                                    </div>
                                                )}
                                                {
                                                    (min != 0 && max != 0) &&
                                                    <div className="col-md-12"><span style={{ color: "red" }}>* Min: {min}/ Max: {max}</span></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad" isOpen={showPinEntry} centered>
                <ModalBody>
                    <form onSubmit={handleSubmit(onPinSubmit)}>
                        <div className="display-wrapper">
                            <button type="button" style={{
                                alignSelf: "flex-end", backgroundColor: "transparent", border: "none", marginRight: "15px", fontSize: "2rem", color: "#002e6c", marginTop: "-15px", float: "right", fontWeight: "bold"
                            }} onClick={() => { setShowPinEntry(false); }} data-dismiss="modal" aria-hidden="true">&times;</button>
                            <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "20px" }}>{t("ENTER_YOUR_PIN")}</strong>
                            <PinInput
                                length={4}
                                initialValue=""
                                secret={true}
                                focus={true}
                                autoSelect={true}
                                type="numeric"
                                onChange={(value, index) => {
                                    checkPinValue(value, index);
                                }}
                                inputMode="numeric"
                                inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                regexCriteria={/^[ 0-9_@./#&+-]*$/}
                            />
                            {/*<button type="submit" style={{ position: "relative", marginTop: "30px", width: "250px" }} className="btn btn-block btn-brand-gradient btn-sticky-bottom">{t("SUBMIT_TRANSACTION")}</button>*/}
                            {/*<div className="invalid-feedback" style={{ fontSize: "12px", marginTop: "1rem" }}>{invalidPin && t("PIN_DOES_NOT_MATCH")}{!invalidPin && <span>&nbsp;</span>}</div>*/}

                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default Withdrawal;
