import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { createFormBody } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>
const LoyaltyPointDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, watch } = useForm({ mode: "onBlur" });
  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [loyaltyPoints, setLoyaltyPoints] = useState(0.00);
  const [valueInCurrency, setValueInCurrency] = useState("");


  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);


  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_ACCOUNT_PRODUCT_BY_PRODUCT_ID;
      apiUrl += "?productId=" + _location.state.productId;
      let responseJson = await ApiEngine.get(apiUrl);

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setProductId(responseJson[ApiKey._API_DATA_KEY]["productId"]);
        setProductName(responseJson[ApiKey._API_DATA_KEY]["productName"]);
        setProductCode(responseJson[ApiKey._API_DATA_KEY]["productCode"]);
        setLoyaltyPoints(responseJson[ApiKey._API_DATA_KEY]["loyaltyPoints"].toFixed(2));
        setValueInCurrency(responseJson[ApiKey._API_DATA_KEY]["valueInCurrency"].toFixed(13));
      }
    }
    else {
      _history.push("/loyalty/loyaltyPointDetails")
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      productId: productId,
      loyaltyPoints: data.loyaltyPoints,
      valueInCurrency: data.valueInCurrency,
    };
    
    let formBody = createFormBody(params);
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_LOYALTY_POINTS,
      formBody
    );
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
    _dispatch(setSaveAdminLog("EDIT_BO_ACCOUNT_PRODUCT_GAME_POINT", _history.location.pathname, params));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
  };

  return (
    <div>
      <h1 className="page-header">
        {t("ACCOUNT_PRODUCT_GAME_POINTS")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-2">
                    <h4>{t("PRODUCT_DETAIL")}</h4>
                  </div>
                </div>


                <div className="row">
                  <div className="col-lg-3">
                    <label>
                      <b>{t("PRODUCT_NAME")}</b>
                    </label>
                    <input type="text" className="form-control" value={productName} disabled />
                  </div>
                  <div className="col-lg-3">
                    <label>
                      <b>{t("PRODUCT_CODE")}</b>
                    </label>
                    <input type="text" className="form-control" value={productCode} disabled />
                  </div>
                  <div className="col-lg-3">
                    <label>
                      <b>{t("GAME_POINTS")}</b>
                    </label>

                    <br />
                    <input
                      type="number"
                      name="loyaltyPoints"
                      ref={register({
                        required: "PLEASE_ENTER_GAME_POINTS",
                        min: {
                          value: 0,
                          message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0"
                        }
                      })}
                      className="form-control m-b-5"
                      placeholder={t("GAME_POINTS")}
                      value={loyaltyPoints}
                      onChange={(e) => setLoyaltyPoints(e.target.value)}
                      pattern="[0-9]+.[0-9]"
                      step="0.01"
                    />
                    {errors.loyaltyPoints && (
                      <div className="invalid-feedback">
                        {t(errors.loyaltyPoints.message)}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3">
                    <label>
                      <b>{t("VALUE_IN_CURRENCY")}</b>
                    </label>

                    <br />
                    <input
                      type="text"
                      name="valueInCurrency"
                      ref={register({
                        required: "PLEASE_ENTER_VALUE_IN_CURRENCY",
                        min: {
                          value: 0,
                          message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0"
                        },
                        pattern : {
                          value: /[\d]+.[\d]/g,
                          message: "PLEASE_ENTER_THE_CORRECT_FORMAT"
                        }
                      })}
                      className="form-control m-b-5"
                      placeholder={t("VALUE_IN_CURRENCY")}
                      value={valueInCurrency}
                      onChange={(e) => setValueInCurrency(e.target.value)}
                    />
                    {errors.valueInCurrency && (
                      <div className="invalid-feedback">
                        {t(errors.valueInCurrency.message)}
                      </div>
                    )}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button
                        type="button"
                        onClick={() => handleSubmit(submitForm)()}
                        className="btn btn-primary"
                      >
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyPointDetail;
