/* eslint-disable no-throw-literal */
import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  LanguageKey,
  _TICKET_STATUS,
  DEFAULT_PAGE_SIZE,
  Role
} from "../../util/Constant";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  numberWithCurrencyFormat,
} from "../../util/Util";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import Select from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from "classnames";
import { AsyncPaginate } from "react-select-async-paginate";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const ManageTallyWinLossReport = (props) => {
  let _dispatch = useDispatch();
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [productList, setProductList] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );

  const [timeZone, setTimeZone] = useState([-4]);
  var _TIMEZONE_OPTION = [
    { label: "(GMT-11:00) Pago Pago", value: -11 },
    { label: "(GMT-10:00) Pacific/Honolulu", value: -10 },
    { label: "(GMT-08:00) America/Los_Angeles", value: -8 },
    { label: "(GMT-07:00) America/Denver", value: -7 },
    { label: "(GMT-06:00) America/Chicago", value: -6 },
    { label: "(GMT-05:00) America/New_York", value: -5 },
    { label: "(GMT-04:00) America/Guyana", value: -4 },
    { label: "(GMT-03:00) America/Santiago", value: -3 },
    { label: "(GMT-02:00) America/Sao_Paulo", value: -2 },
    { label: "(GMT-01:00) Atlantic/Azores", value: -1 },
    { label: "(GMT+00:00) Europe/London", value: 0 },
    { label: "(GMT+01:00) Europe/Berlin", value: 1 },
    { label: "(GMT+02:00) Europe/Athens", value: 2 },
    { label: "(GMT+03:00) Europe/Istanbul", value: 3 },
    { label: "(GMT+04:00) Europe/Samara", value: 4 },
    { label: "(GMT+05:00) Asia/Karachi", value: 5 },
    { label: "(GMT+06:00) Asia/Dhaka", value: 6 },
    { label: "(GMT+07:00) Asia/Bangkok", value: 7 },
    { label: "(GMT+08:00) Asia/Singapore", value: 8 },
    { label: "(GMT+09:00) Asia/Seoul", value: 9 },
    { label: "(GMT+10:00) Asia/Magadan", value: 10 },
    { label: "(GMT+11:00) Australia/Sydney", value: 11 },
    { label: "(GMT+12:00) Pacific/Majuro", value: 12 },
    { label: "(GMT+13:00) Pacific/Auckland", value: 13 },
    { label: "(GMT+14:00) Pacific/Apia", value: 14 },
  ];
  const [gameType, setGametype] = useState([0]);
  var _GAMETYPE_OPTION = [
    { label: "CreatedTime", value: 0 },
    { label: "UpdatedTime", value: 1 },
  ];
  const [tallyWinLoseApiUrl, setTallyWinLoseApiUrl] = useState("");
  const _userData = useSelector((state) => state.authState.userData);
  const isAllowToSearchMonth = _userData.userRoleId === Role._SUPER_COMPANY || _userData.userRoleId === Role._COMPANY || _userData.userRoleId === Role._SUPERVISOR || _userData.userRoleId === Role._RISK_SUPERVISOR;


  // This is added for the sub reports total "footer"
  const _NODE_THEAD = "THEAD";
  const _TEXT_RED = "text-red";
  /// <summary>
  /// Author : -
  /// </summary>
  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  let _tableColumns = [
    {
      Header: "USERNAME",
      accessor: "username",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <span style={{ color: "#0054b3", textDecoration: "underline", cursor: "pointer" }} onClick={() => window.open(WebUrl._URL_USERNAME_BET_DETAIL_REPORT + "?username=" + row.original.alias + "&startDate=" + startDate + "&endDate=" + endDate + "&zone=" + timeZone + "&productId=" + row.original.productId)}>
            {row.original.username}
          </span>
        );
      },
      Footer: () => {
        return <span><b>{t("TOTAL")}: </b></span>
      },
    },
    {
      Header: "Alias",
      accessor: "alias",
      disableSortBy: true,
      style: {
        textAlign: 'right'
      },
    },
    {
      Header: "Product",
      accessor: "product",
      disableSortBy: true,
      style: {
        textAlign: 'right'
      },
    },
    {
      Header: "Total Outstanding",
      accessor: "TotalOutstanding",
      disableSortBy: true,
      style: {
        textAlign: 'right'
      },
      Cell: ({ row }) => {
        let totalOutstanding = parseFloat(row.original.totalOutstanding);
        return (
          <span>
            {numberWithCurrencyFormat(totalOutstanding, 2, true)}
          </span>
        );
      },
      Footer: () => {
        let totalOutstanding = 0;
        for (let i = 0; i <= reportData.length; i++) {
          if (reportData[i] && reportData[i].totalOutstanding) {
            totalOutstanding += parseFloat(reportData[i].totalOutstanding);
          }
        }
        return (
          <span><div style={{ textAlign: "right" }} >
            <b>{numberWithCurrencyFormat(totalOutstanding)}</b>
          </div>
          </span>
        );
      },
    },
    {
      Header: "Total Sales",
      accessor: "totalSales",
      disableSortBy: true,
      style: {
        textAlign: 'right'
      },
      Cell: ({ row }) => {
        let totalSales = parseFloat(row.original.totalSales);
        return (
          <span>
            {numberWithCurrencyFormat(totalSales, 2, true)}
          </span>
        );
      },
      Footer: () => {
        let totalSales = 0;
        for (let i = 0; i <= reportData.length; i++) {
          if (reportData[i] && reportData[i].totalSales) {
            totalSales += parseFloat(reportData[i].totalSales);
          }
        }
        return (
          <span><div style={{ textAlign: "right" }} >
            <b>{numberWithCurrencyFormat(totalSales)}</b>
          </div>
          </span>
        );
      },
    },
    {
      Header: "Total Win",
      accessor: "totalWin",
      disableSortBy: true,
      style: {
        textAlign: 'right'
      },
      Cell: ({ row }) => {
        let totalWin = parseFloat(row.original.totalWin);
        return (
          <span className={classnames({ "text-red": totalWin != 0 })}>
            {numberWithCurrencyFormat(-totalWin, 2, true)}
          </span>);
      },
      Footer: () => {
        let totalWin = 0;
        for (let i = 0; i <= reportData.length; i++) {
          if (reportData[i] && reportData[i].totalWin) {
            totalWin -= parseFloat(reportData[i].totalWin);
          }
        }
        return (
          <span className={classnames({ "text-red": totalWin < 0 })}><div style={{ textAlign: "right" }}>
            <b>{numberWithCurrencyFormat(totalWin)}</b>
          </div>
          </span>
        );
      },
    },
    {
      Header: "Total Win Loss",
      accessor: "totalWinLose",
      disableSortBy: true,
      style: {
        textAlign: 'right'
      },
      Cell: ({ row }) => {
        let totalWinLose = parseFloat(row.original.totalWinLose);
        return (
          <span className={classnames({ "text-red": totalWinLose < 0 })}>
            {numberWithCurrencyFormat(totalWinLose, 2, true)}
          </span>);
      },
      Footer: () => {
        let totalWinLose = 0;
        for (let i = 0; i <= reportData.length; i++) {
          if (reportData[i] && reportData[i].totalWinLose) {
            totalWinLose += parseFloat(reportData[i].totalWinLose);
          }
        }
        return (
          <span className={classnames({ "text-red": totalWinLose < 0 })}><div style={{ textAlign: "right" }}>
            <b>{numberWithCurrencyFormat(totalWinLose)}</b>
          </div>
          </span>
        );
      },
    },
    {
      Header: "Total Cancel",
      accessor: "totalCancel",
      disableSortBy: true,
      style: {
        textAlign: 'right'
      },
      Cell: ({ row }) => {
        let totalCancel = parseFloat(row.original.totalCancel);
        return (
          <span>
            {numberWithCurrencyFormat(totalCancel, 2, true)}
          </span>
        );
      },
      Footer: () => {
        let totalCancel = 0;
        for (let i = 0; i <= reportData.length; i++) {
          if (reportData[i] && reportData[i].totalCancel) {
            totalCancel += parseFloat(reportData[i].totalCancel);
          }
        }
        return (
          <span><div style={{ textAlign: "right" }} >
            <b>{numberWithCurrencyFormat(totalCancel)}</b>
          </div>
          </span>
        );
      },
    },
    {
      Header: "Total Jpc",
      accessor: "totalJpc",
      disableSortBy: true,
      style: {
        textAlign: 'right'
      },
      Cell: ({ row }) => {
        let totalJpc = parseFloat(row.original.totalJpc);
        return (
          <span>
            {numberWithCurrencyFormat(totalJpc, 3, true)}
          </span>
        );
      },
      Footer: () => {
        let totalJpc = 0;
        for (let i = 0; i <= reportData.length; i++) {
          if (reportData[i] && reportData[i].totalJpc) {
            totalJpc += parseFloat(reportData[i].totalJpc);
          }
        }
        return (
          <span><div style={{ textAlign: "right" }} >
            <b>{numberWithCurrencyFormat(totalJpc)}</b>
          </div>
          </span>
        );
      },
    },
    {
      Header: "Total Jpw",
      accessor: "totalJpw",
      disableSortBy: true,
      style: {
        textAlign: 'right'
      },
      Cell: ({ row }) => {
        let totalJpw = parseFloat(row.original.totalJpw);
        return (
          <span>
            {numberWithCurrencyFormat(totalJpw, 3, true)}
          </span>
        );
      },
      Footer: () => {
        let totalJpw = 0;
        for (let i = 0; i <= reportData.length; i++) {
          if (reportData[i] && reportData[i].totalJpw) {
            totalJpw += parseFloat(reportData[i].totalJpw);
          }
        }
        return (
          <span><div style={{ textAlign: "right" }} >
            <b>{numberWithCurrencyFormat(totalJpw)}</b>
          </div>
          </span>
        );
      },
    },
    {
      Header: "Total Club Fees",
      accessor: "totalClubFee",
      disableSortBy: true,
      style: {
        textAlign: 'right'
      },
      Cell: ({ row }) => {
        let totalClubFee = parseFloat(row.original.totalClubFee);
        return (
          <span>
            {numberWithCurrencyFormat(totalClubFee, 3, true)}
          </span>
        );
      },
      Footer: () => {
        let totalClubFee = 0;
        for (let i = 0; i <= reportData.length; i++) {
          if (reportData[i] && reportData[i].totalClubFee) {
            totalClubFee += parseFloat(reportData[i].totalClubFee);
          }
        }
        return (
          <span><div style={{ textAlign: "right" }} >
            <b>{numberWithCurrencyFormat(totalClubFee)}</b>
          </div>
          </span>
        );
      },
    },
    {
      Header: "Total Tax",
      accessor: "totalTax",
      disableSortBy: true,
      style: {
        textAlign: 'right'
      },
      Cell: ({ row }) => {
        let totalTax = parseFloat(row.original.totalTax);
        return (
          <span>
            {numberWithCurrencyFormat(totalTax, 3, true)}
          </span>
        );
      },
      Footer: () => {
        let totalTax = 0;
        for (let i = 0; i <= reportData.length; i++) {
          if (reportData[i] && reportData[i].totalTax) {
            totalTax += parseFloat(reportData[i].totalTax);
          }
        }
        return (
          <span><div style={{ textAlign: "right" }} >
            <b>{numberWithCurrencyFormat(totalTax)}</b>
          </div>
          </span>
        );
      },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    await fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const accountProductOptionList = [
              //{ label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
            ];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
              accountProductOptionList.push({
                label: option.productName,
                value: option.id,
              });
            });

            setProductList(accountProductOptionList);
          }
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
      });
  }


  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function readReport() {
    try {

      var filterString = "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&zone=" +
        timeZone +
        "&gameType=" +
        gameType +
        "&productId=" +
        selectedProduct;
      if (stringIsNullOrEmpty(selectedProduct)) {
        throw "No Game Selected";
      }

      if (isAllowToSearchMonth && moment(endDate).diff(moment(startDate), 'days') > 32) {
        throw t("MAX_DAY_RANGE_WARNING", { day: 1, type: t("MONTH") });
      }

      if (!isAllowToSearchMonth && moment(endDate).diff(moment(startDate), 'days') > 1) {
        throw t("MAX_DAY_RANGE_WARNING", { day: 1, type: t("DAY") });
      }

      var apiGetRedisTallyWinLoseUrl = ApiUrl._API_GET_TALLY_WINLOSS_REPORT + filterString
      var apiGetTotalTallyWinLoseUrl = ApiUrl._API_GET_TOTAL_TALLY_WINLOSS_REPORT + filterString;

      setTallyWinLoseApiUrl(apiGetRedisTallyWinLoseUrl);
      var responseJson = await ApiEngine.get(apiGetTotalTallyWinLoseUrl);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setReportData(responseJson[ApiKey._API_DATA_KEY]);
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      checkNegativeCells(reportData);
    } catch (error) {
      _dispatch(showMessage(false, t(error)));
    }
  }

  async function resetReport() {
    try {
      var filterString = "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&zone=" +
        timeZone +
        "&gameType=" +
        gameType +
        "&productId=" +
        selectedProduct;

      if (stringIsNullOrEmpty(selectedProduct)) {
        throw "No Game Selected";
      }

      var apiGetResetTallyWinLoseUrl = ApiUrl._API_GET_RESET_TALLY_WINLOSS_REPORT + filterString
      var apiGetTotalTallyWinLoseUrl = ApiUrl._API_GET_TOTAL_TALLY_WINLOSS_REPORT + filterString;

      setTallyWinLoseApiUrl(apiGetResetTallyWinLoseUrl);

      var responseJson = await ApiEngine.get(apiGetTotalTallyWinLoseUrl);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setReportData(responseJson[ApiKey._API_DATA_KEY]);
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
      checkNegativeCells(reportData);
    } catch (error) {
      _dispatch(showMessage(false, t(error)));
    }
  }
  function checkNegativeCells(ref) {
    if (ref.current) {
      Array.from(ref.current.childNodes.values()).map((section) => {
        if (section.nodeName != _NODE_THEAD) {
          Array.from(section.childNodes.values()).map((tr) => {
            Array.from(tr.childNodes.values()).map((td) => {
              if (
                !isNaN(parseFloat(td.innerText)) &&
                parseFloat(td.innerText) < 0
              ) {
                td.classList.add(_TEXT_RED);
              }
            });
          });
        }
      });
    }
  }

  /// <summary>
  /// Author : Wong
  /// </summary>
  /*async function getMemberList(search, loadOptions, { page }) {
      var responseJson = await ApiEngine.get(
          `${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${
          (page - 1) * DEFAULT_PAGE_SIZE
          }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY]["data"];
          let formattedData = data.map((m) => {
              return { label: m.username, value: m.id };
          });

          return {
              options: formattedData,
              hasMore: formattedData.length == _PAGING_COUNT,
              additional: {
                  page: page + 1,da
              },
          };
      }
  }*/

  return (
    <div>
      <h1 className="page-header">
        {stringIsNullOrEmpty(props.id) && t("TALLY_WINLOSS_REPORT")}
      </h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("DATE")}</b>
                </label>
                <div
                  className="input-group"
                  style={{ width: "100%", flexFlow: "nowrap" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-calendar"></i>
                    </span>
                  </div>
                  <div style={{ width: "80%" }}>
                    <DateRangePicker
                      containerStyles={{ width: "100%" }}
                      startDate={startDate}
                      endDate={endDate}
                      onApply={handleEvent}
                      alwaysShowCalendars={true}
                      locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                      timePicker={true}
                      timePickerSeconds={true}
                      maxDate={moment().endOf("day")}
                      ranges={{
                        Today: [moment().startOf("day"), moment().endOf("day")],
                        Yesterday: [
                          moment().subtract(1, "days").startOf("day"),
                          moment().subtract(1, "days").endOf("day"),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "Last 30 Days": [
                          moment().subtract(29, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                        //"This Year": [
                        //    moment().startOf("year"),
                        //    moment().endOf("year"),
                        //],
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={startDate + " - " + endDate}
                      />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("GAME_NAME")}</b>
                </label>
                <Select
                  styles={_OPTION_STYLES}
                  value={productList.filter(
                    (product) => product.value == selectedProduct
                  )}
                  options={productList}
                  onChange={(e) => {
                    setSelectedProduct(e.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("TIME_ZONE")}</b>
                </label>
                <br />
                <Select
                  name="timeZone"
                  options={_TIMEZONE_OPTION}
                  placeholder={
                    _TIMEZONE_OPTION.filter(
                      (option) => option.value == timeZone
                    )[0] !== undefined
                      ? _TIMEZONE_OPTION.filter(
                        (option) => option.value == timeZone
                      )[0].label
                      : ""
                  }
                  value={_TIMEZONE_OPTION.filter(
                    (option) => option.value == timeZone
                  )}
                  onChange={(e) => {
                    setTimeZone(e.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>Filter By</b>
                </label>
                <br />
                <Select
                  name="gametype"
                  options={_GAMETYPE_OPTION}
                  placeholder={
                    _GAMETYPE_OPTION.filter(
                      (option) => option.value == gameType
                    )[0] !== undefined
                      ? _GAMETYPE_OPTION.filter(
                        (option) => option.value == gameType
                      )[0].label
                      : ""
                  }
                  value={_GAMETYPE_OPTION.filter(
                    (option) => option.value == gameType
                  )}
                  onChange={(e) => {
                    setGametype(e.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  readReport();
                }}
              >
                <i className="fas fa-search"></i> {t("SEARCH")}
              </button>
            </div>
            {/*<div className="col-lg-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  resetReport();
                }}
              >
                <i className="fa fa-refresh"></i> {t("RESET_CACHE")}
              </button>
            </div>*/}
          </div>
          <hr />

          <div className="row">
            <div className="col-lg-12">
              <ReactTable fetchUrl={tallyWinLoseApiUrl} columns={_tableColumns} renderFooter={true} />
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageTallyWinLossReport;
