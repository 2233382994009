import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  navigateTo,
  stringIsNullOrEmpty,
  numberWithCurrencyFormat,
} from "../../util/Util";
import {
  ApiKey,
  ApiUrl,
  AccessRight,
  WebUrl,
  LanguageKey,
  DEFAULT_PAGE_SIZE,
  Role,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { showMessage, setMemberSearch, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";

/// <summary>
/// Author : -
/// </summary>
const ViewMember = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();

  var _username = useSelector(
    (state) => state["authState"]["userData"]["username"]
  );
  var _searchedMember = useSelector((state => state.appState.memberSearch));
  const _dispatch = useDispatch();
  const [memberData, setMemberData] = useState([]);

  const _SELECTED_MEMBER_KEY = "selectedMemberKey";

  const [selectedMember, setSelectedMember] = useState("");
  const [selectedMemberPhone, setSelectedMemberPhone] = useState("");
  const [locationStateObj, setLocationStateObj] = useState();
  const [memberId, setMemberId] = useState("");
  const [nodePathMembers, setNodePathMembers] = useState([]);
  const [labelUsername, setLabelUsername] = useState("All");
  const [labelUserPhone, setLabelUserPhone] = useState("All");
  const [usernameKeyword, setUsernameKeyword] = useState("");
  const [phoneNumKeyword, setPhoneNumKeyword] = useState("");
  const [memberListUrl, setMemberListUrl] = useState(
    `${ApiUrl._API_GET_DOWNLINE_BY_REFERRER}?memberId=${memberId}`
  );
  const [tableData, setTableData] = useState([]);
  const [formattedDataList, setFormattedDataList] = useState([]);

  let _tableColumns = [
    {
      Header: "USERNAME",
      accessor: "username",
      minWidth: 100,
      Cell: ({ row }) => {
        return (
          <span
            className={classNames({
              "clickable-username":
                nodePathMembers.length > 0
                  ? nodePathMembers[nodePathMembers.length - 1].id !=
                  row.original.id
                  : true,
            })}
            onClick={() => setSelectedMember(row.original.id)}
          >
            {row.original.username}
          </span>
        );
      },
    },
    {
      Header: "FULLNAME",
      accessor: "userFullName",
      minWidth: 100,
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      minWidth: 100,
    },
    {
      Header: "EMAIL",
      accessor: "email",
      minWidth: 100,
    },
    {
      Header: "WALLET",
      accessor: "primaryCredit",
      Cell: ({ row }) => {
        return (
          <>
            {numberWithCurrencyFormat(
              parseFloat(row.original.primaryCredit),
              3
            )}
          </>
        );
      },
      minWidth: 100,
    },
    {
      Header: "PHONE",
      accessor: "phoneNumber",
      minWidth: 100,
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.status == 1 ? (
              <span className="badge badge-secondary badge-green">
                {t("ACTIVE")}
              </span>
            ) : row.original.status == 2 ? (
              <span className="badge badge-secondary">
                {t("CLOSED")}
              </span>
            ) : (
              <span className="badge badge-secondary badge-danger">
                {t("SUSPENDED")}
              </span>
            )}
          </span>
        );
      },
      minWidth: 100,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(
          AccessRight._EDIT_MEMBER_DETAIL_PERMISSION,
          true
        ) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_EDIT_MEMBER_DETAIL, row.original); }}><span className="text-warning">{t("EDIT")}</span></DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : Wong
  /// </summary>
  useEffect(() => {
    let nodePath = [];
    let memId = "";
    if (tableData.memberNodePath) {
      if (tableData.memberNodePath.length > 0) {
        nodePath = tableData.memberNodePath;
        memId = nodePath[nodePath.length - 1].id;
      }
    }

    setNodePathMembers(nodePath);
    setMemberId(memId);
    setLocationStateObj({
      parentMemberId: memId,
    });
    if (!stringIsNullOrEmpty(_searchedMember)) {
      sessionStorage.setItem(_SELECTED_MEMBER_KEY, _searchedMember);
      setSelectedMember(_searchedMember);
      let temp1 = tableData && tableData['data'] && tableData['data'].length > 0 ? tableData['data'].find(x => x.id == _searchedMember) : {};
      let tempLabelUsername = tableData && tableData['data'] && tableData['data'].length > 0 && typeof (temp1) !== "undefined" ? temp1.username : "All";
      setLabelUsername(tempLabelUsername);
    }
  }, [tableData]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    sessionStorage.setItem(_SELECTED_MEMBER_KEY, selectedMember);
    _dispatch(setMemberSearch(selectedMember));
    getDownlineByReferrer(selectedMember);
  }, [selectedMember]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    sessionStorage.setItem(_SELECTED_MEMBER_KEY, selectedMemberPhone);
    getDownlineByReferrer(selectedMemberPhone);
  }, [selectedMemberPhone]);

  /// <summary>
  /// Author : -
  /// </summary>
  async function getDownlineByReferrer(memberId = "") {
    setMemberListUrl(
      `${ApiUrl._API_GET_DOWNLINE_BY_REFERRER}?memberId=${memberId}`
    );
  }

  async function getDownlineBySelectedReferrer() {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_DOWNLINE_BY_REFERRER_USING_USERNAME + "?username=" + usernameKeyword);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setTableData(data["data"]);
    }
    _dispatch(setSaveAdminLog("VIEW_MEMBER", _history.location.pathname, { "username": usernameKeyword }));
  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function syncProductData(data) {
    var responseJson = await ApiEngine.post(
      `${ApiUrl._API_SYNC_MEMBER_TO_OWNER_PRODUCT_DATA}?id=${data["id"]}`
    );
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
  }

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      console.log(responseJson[ApiKey._API_DATA_KEY]);
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      setMemberData(data);
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function getMemberPhoneList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      console.log(responseJson[ApiKey._API_DATA_KEY]);
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.phoneNumber, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  return (
    <div>
      <h1 className="page-header">
        {t("MANAGE_ACCOUNT")}
      </h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <>
              <div className="hierarchy-line">
                <a
                  href="javascript:;"
                  onClick={() => {
                    setSelectedMember("");
                    sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                  }}
                >
                  {_username}
                </a>
                {nodePathMembers.map((m, index) => {
                  return (
                    <>
                      {index == 0 && ">"}
                      <a
                        href="javascript:;"
                        onClick={() => setSelectedMember(m.id)}
                      >
                        {m.username}
                      </a>
                      {index != nodePathMembers.length - 1 && ">"}
                    </>
                  );
                })}
              </div>
              <hr />
            </>
            <div className="row">
              <div className="col-lg-3" style={{ margin: "15px 0 0 15px" }}>
                <div className="form-group">
                  <label>
                    <b>{t("USERNAME")}</b>
                  </label>
                  {<AsyncPaginate
                    placeholder={labelUsername}
                    debounceTimeout={250}
                    loadOptions={getMemberList}
                    additional={{
                      page: 1,
                    }}
                    value={selectedMember}
                    onChange={(e) => {
                      if (!stringIsNullOrEmpty(e.value)) {
                        sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                      } else {
                        sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                      }
                      setLabelUsername(e.label);
                      setSelectedMember(e.value);
                    }}
                  />}
                  {/*<div>{t("KEY_KEYWORD_TO_CONTINUE")}</div>*/}
                </div>
              </div>
              <div className="col-lg-3" style={{ margin: "15px 0 0 15px" }}>
                <div className="form-group">
                  <label>
                    <b>{t("PHONE")}</b>
                  </label>
                  {<AsyncPaginate
                    placeholder={labelUserPhone}
                    debounceTimeout={250}
                    loadOptions={getMemberPhoneList}
                    additional={{
                      page: 1,
                    }}
                    value={selectedMemberPhone}
                    onChange={(e) => {
                      if (!stringIsNullOrEmpty(e.value)) {
                        sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                      } else {
                        sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                      }
                      setLabelUserPhone(e.label);
                      setSelectedMemberPhone(e.value);
                    }}
                  />}
                </div>
              </div>
              {<div className="col-lg-2">
                <button className="btn btn-primary" style={{ marginLeft: "5px", marginTop: "40px", width: "100px" }} onClick={() => getDownlineBySelectedReferrer()}>{t("SEARCH")}</button>
              </div>}
            </div>
            <PanelBody>
              {<ReactTable
                fetchUrl={memberListUrl}
                fetchedData={(data) => setTableData(data)}
                filterable
                columns={_tableColumns}
              />}
            </PanelBody>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default ViewMember;
