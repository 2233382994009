import React, { useState } from "react";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch } from "react-redux";
import { ApiKey, ApiUrl, LanguageKey, DEFAULT_PAGE_SIZE, BetStatus } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import { useTranslation } from "react-i18next";
import { AsyncPaginate } from "react-select-async-paginate";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable.js";
import { setSaveAdminLog } from "../../redux/AppAction.js";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const BonusDepositReport = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const _SELECTED_MEMBER_KEY = "selectedMemberKey";
  const [search, setSearch] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [label, setLabel] = useState("");
  const [calendarStartDate, setCalendarStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
  const [calendarEndDate, setCalendarEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
  const [firstDepositUrl, setFirstDepositUrl] = useState("");

  let _tableColumns = [
    {
      Header: "Username",
      accessor: "username",
      minWidth: 100,
      Footer: () => {
        return (
          <span>
            <b>{ t("TOTAL")}: </b>
          </span>
        );
      },
    },
    {
      Header: "BonusName",
      accessor: "bonusName",
      minWidth: 100,
      
    },
    {
      Header: "Channel",
      accessor: "channel",
      minWidth: 50,
    },
    {
      Header: "Deposit Amount",
      accessor: "depositAmount",
      minWidth: 100,
      Cell: ({ row }) => {
        return <div>{numberWithCurrencyFormat(row.original.depositAmount)}</div>
      },
      Footer: ({ page }) => {
        const totalTrans = page.reduce((sum, currentValue) => {
          if (currentValue.original.depositAmount) {
            return sum + (parseFloat(currentValue.original.depositAmount));
          } else {
            return sum;
          }

        }, 0);
        return <span><b>{numberWithCurrencyFormat(parseFloat(totalTrans))}</b></span>
      },
    },
    {
      Header: "Bonus Amount",
      accessor: "bonusAmount",
      minWidth: 100,
      Cell: ({ row }) => {
        return <div>{numberWithCurrencyFormat(row.original.bonusAmount)}</div>
      },
      Footer: ({ page }) => {
        const totalTrans = page.reduce((sum, currentValue) => {
          if (currentValue.original.bonusAmount) {
            return sum + (parseFloat(currentValue.original.bonusAmount));
          } else {
            return sum;
          }

        }, 0);
        return <span><b>{numberWithCurrencyFormat(parseFloat(totalTrans))}</b></span>
      },
    },
    {
      Header: "Claimed Time",
      accessor: "claimedTime",
      minWidth: 100,
      Cell: ({ row }) => {
        return <div>{moment(row.original.claimedTime).format("YYYY-MM-DD HH:mm:ss")}</div>
      },
    },
  ]

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setCalendarStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setCalendarEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  }


  /// <summary>
  /// Author : -
  /// </summary>
  const searchBtnHandler = async (selected) => {
    let apiUrl = ApiUrl._API_GET_BONUS_DEPOSIT_REPORT + "?startDate=" + calendarStartDate + "&endDate=" + calendarEndDate;

    if (!stringIsNullOrEmpty(selectedMember)) {
      apiUrl += "&memberId=" + selectedMember;
    }
    setFirstDepositUrl(apiUrl);
  };

  /// <summary>
  /// Author : 
  /// </summary>
  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  async function adminLog() {
    let params = {};

    if (!stringIsNullOrEmpty(calendarStartDate) && !stringIsNullOrEmpty(calendarEndDate) ) {
      params["calendarStartDate"] = calendarStartDate;
      params["calendarEndDate"] = calendarEndDate;
    }

    if (!stringIsNullOrEmpty(selectedMember)) {
      params["selectedMember"] = selectedMember;
    }

    _dispatch(setSaveAdminLog("VIEW_BONUS_DEPOSIT_REPORT", _history.location.pathname, params));

  }
  return (
    <div>
      <h1 className="page-header">
        {stringIsNullOrEmpty(props.id) && t("BONUS_DEPOSIT_REPORT")}
      </h1>
      <Panel
        style={{
          marginBottom: !stringIsNullOrEmpty(props.id) ? "0px" : "20px",
        }}
      >
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      width: "100%",
                      flexFlow: "nowrap",
                    }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        {/*<DateRangePicker*/}
                        {/*  containerStyles={{ width: "100%" }}*/}
                        {/*  singleDatePicker={true}*/}
                        {/*  onApply={handleEvent}*/}
                        {/*  alwaysShowCalendars={true}*/}
                        {/*  locale={{ format: "YYYY-MM-DD" }}*/}
                        {/*>*/}
                        {/*  <input*/}
                        {/*    type="text"*/}
                        {/*    className="form-control"*/}
                        {/*    value={selectedDate}*/}
                        {/*  />*/}
                        {/*</DateRangePicker>*/}
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          alwaysShowCalendars={true}
                          timePicker={false}
                          timePickerSeconds={false}
                          onApply={handleEvent}
                          ranges={{
                            'Today': [moment().startOf('day'), moment().endOf('day')],
                            'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                            'This Week': [moment().startOf('week'), moment().endOf('week')],
                          }}>
                          <input type="text" className="form-control" value={calendarStartDate + " - " + calendarEndDate} />
                        </DateRangePicker>
                          
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div className="input-group member-group" style={{ width: "100%" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <AsyncPaginate
                        placeholder={label}
                        debounceTimeout={250}
                        loadOptions={getMemberList}
                        additional={{
                          page: 1,
                        }}
                        value={selectedMember}
                        onChange={(e) => {
                          if (!stringIsNullOrEmpty(e.value)) {
                            sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                          } else {
                            sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                          }
                          setLabel(e.label);
                          setSelectedMember(e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setSearch(true);
                      searchBtnHandler();
                      adminLog();
                    }}
                  >
                    <i className="fas fa-search"></i> {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <ReactTable fetchUrl={firstDepositUrl} renderFooter={true} columns={_tableColumns} exportRequired />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default BonusDepositReport;