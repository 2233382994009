import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch } from "react-redux";
import { ApiKey, ApiUrl, LanguageKey, DEFAULT_PAGE_SIZE, BetStatus } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useTranslation } from "react-i18next";
import { AsyncPaginate } from "react-select-async-paginate";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable.js";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import {
    useTable,
  } from "react-table";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const ManageWalletRecon = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    let _history = useHistory();
    const [startDate, setStartDate] = useState(
        moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
    );
    const [endDate, setEndDate] = useState(
        moment().startOf("day").add(1, 'hours').format("YYYY-MM-DD HH:mm:ss")
    );
    const [search, setSearch] = useState(false);
    const [walletUrl, setWalletUrl] = useState("");
    const [tableData, setTableData] = useState([]);

    let _tableColumns = [
        {
            Header: "Player ID",
            accessor: "username",
            minWidth: 100,
        },
        {
            Header: "Date",
            accessor: "parsedTime",
            minWidth: 100,
        },
        {
            Header: "Remark",
            accessor: "remark",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                        <span>{row.original.remark}</span>
                        {!stringIsNullOrEmpty(row.original.betId) && <span><b>{"Ticket: "}</b>{row.original.betId}</span>}
                    </div>
                )
            }
        },
        {
            Header: "Before",
            accessor: "preMemberCredit",
            minWidth: 100,
        },
        {
            Header: "Amount",
            accessor: "amount",
            minWidth: 100,
        },
        {
            Header: "After",
            accessor: "postMemberCredit",
            minWidth: 100,
        },
        {
            Header: "Validate Before Balance",
            accessor: "validatePreMemberCredit",
            minWidth: 100,
        },
        {
            Header: "Variance",
            accessor: "variance",
            minWidth: 100,
        },
        {
            Header: "Recon",
            accessor: "recon",
            minWidth: 100,
        },
        {
            Header: "Wallet Status",
            accessor: "walletStatus",
            minWidth: 100,
        },
    ]

    const handleEvent = (event, picker) => {
        setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
        setEndDate(moment(picker.endDate).add(1, 'minutes').format("YYYY-MM-DD HH:mm:ss"));
    }; 

    const searchBtnHandler = async (selected) => {
        _dispatch(setBusy());
        let apiUrl = ApiUrl._API_GET_MEMBER_WALLET_STATEMENT_RECON + "?StartDate=" + startDate + "&EndDate=" + endDate;
        setWalletUrl(apiUrl);
        
        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setTableData(data["data"]);
        }
        _dispatch(setSaveAdminLog("VIEW_MEMBER_WALLET_STATEMENT_RECON", _history.location.pathname, { "startDate": startDate, "endDate": endDate }));
        _dispatch(setIdle());
    };

    function getExportFileBlob({ columns, data, fileType, fileName }) {
        var response = null;
        if (!stringIsNullOrEmpty(walletUrl)) {    
            response = ApiEngine.get(walletUrl);
    
            response.then(function (responseJson) {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var allData =responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY];
        
                    if (fileType === "csv") {
                        const csvString = Papa.unparse(allData);
                        var csvData = new Blob([csvString], { type: "text/csv" });
                        FileSaver.saveAs(csvData, `${fileName}.csv`);
                    } 
                    else if (fileType === "xlsx") 
                    {
                        const header = columns.map((c) => c.id);
                        const headerNames = columns.map((c) => t(c.exportValue));
            
                        const compatibleData = allData.map((row) => {
                        const obj = {};
                        header.forEach((col, index) => {
                            obj[headerNames[index]] = row[col];
                        });
                        return obj;
                        });
            
                        let wb = XLSX.utils.book_new();
                        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        headerNames,
                        });
                        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                        XLSX.writeFile(wb, `${fileName}.xlsx`);
                    }
                    return false;
                }
            });
        }
    }

    return (
        <div>
            <h1 className="page-header">
                {stringIsNullOrEmpty(props.id) && t("MEMBER_WALLET_STATEMENT_RECON")}
            </h1>
            <Panel
                style={{
                    marginBottom: !stringIsNullOrEmpty(props.id) ? "0px" : "20px",
                }}
            >
                <PanelBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div 
                                        className="form-group"
                                        style={{ display: "flex", width: "100%" }}
                                    >
                                        <div className="input-group" style={{ width: "100%" }}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <DateRangePicker
                                                    containerStyles={{ width: "100%" }}
                                                    singleDatePicker={true}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onApply={handleEvent}
                                                    alwaysShowCalendars={true}
                                                    locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                                                    timePicker={true}
                                                    timePickerSeconds={true}
                                                    // ranges={{
                                                    //     Today: [moment().startOf("day"), moment().endOf("day")],
                                                    //     Yesterday: [
                                                    //         moment().subtract(1, "days").startOf("day"),
                                                    //         moment().subtract(1, "days").endOf("day"),
                                                    //     ],
                                                    //     "Last 7 Days": [
                                                    //         moment().subtract(6, "days").startOf("day"),
                                                    //         moment().endOf("day"),
                                                    //     ],
                                                    //     "Last 30 Days": [
                                                    //         moment().subtract(29, "days").startOf("day"),
                                                    //         moment().endOf("day"),
                                                    //     ],
                                                    //     "This Month": [
                                                    //         moment().startOf("month"),
                                                    //         moment().endOf("month"),
                                                    //     ],
                                                    //     "Last Month": [
                                                    //         moment().subtract(1, "month").startOf("month"),
                                                    //         moment().subtract(1, "month").endOf("month"),
                                                    //     ],
                                                    //     "This Year": [
                                                    //         moment().startOf("year"),
                                                    //         moment().endOf("year"),
                                                    //     ],
                                                    // }}
                                                >
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={startDate + " - " + endDate}
                                                    />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <label>
                                        <b></b>
                                    </label>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setSearch(true);
                                            searchBtnHandler();
                                        }}
                                    >
                                        <i className="fas fa-search"></i> {t("SEARCH")}
                                    </button>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div> 
                    <div className="wallet-recon-wrapper">
                        <ReactTable className=" wallet-recon-export" tableStyle={{display: "none"}} fetchUrl={walletUrl} columns={_tableColumns} exportRequired={true}/>
                    </div> 
                    
                    <ReactTable className=" wallet-recon-table" data={tableData} columns={_tableColumns} />
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ManageWalletRecon;