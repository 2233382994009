import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {ApiKey, ApiUrl, LanguageKey, ContentTypeString } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import { fetch } from "whatwg-fetch";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ManageConfigurationLanguage from "./ManageConfigurationLanguage";

/// <summary>
/// Author : -
/// </summary>

const VideoTutorialDetail = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _DETAIL_TAB = "1";
    const _LANGUAGE_TAB = "2";
    const [activeTab, setActiveTab] = useState(_DETAIL_TAB);
    const _location = useLocation();
    const _dispatch = useDispatch();
    const { register, unregister, handleSubmit, errors, setValue } = useForm();
    const [videoId, setVideoId] = useState("");

    const [title, setTitle] = useState("");
    const [content, setContent] = useState();
    const [status, setStatus] = useState(true);

    /// <summary>
    /// Author : -
    /// </summary>
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        console.log(_location);
        console.log(_location.state);
        init();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {
        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_VIDEO_BY_ID;
            apiUrl += "?id=" + _location.state.id;
            fetch(apiUrl, {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log(responseJson[ApiKey._API_DATA_KEY]);
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setVideoId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                        setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
                        setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
                        setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
                    }
                });
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {
        console.log(data);
        let params = {
            title: data.title,
            status: data.status,
            content:data.content
        };

        if (!stringIsNullOrEmpty(videoId)) {
            params["id"] = videoId;
            _dispatch(setSaveAdminLog("EDIT_BO_VIDEO_TUTORIAL", _history.location.pathname, params));
          } else {
            _dispatch(setSaveAdminLog("CREATE_BO_VIDEO_TUTORIAL", _history.location.pathname, params));
        }

        let formBody = createMultiPartFormBody(params);
        _dispatch(setBusy());

        fetch(ApiUrl._API_CREATE_OR_UPDATE_VIDEO, {
            method: ApiKey._API_POST,
            headers: {
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                _dispatch(setIdle());
                _dispatch(
                    showMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        t(responseJson[ApiKey._API_MESSAGE_KEY])
                    )
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setVideoId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                }
            });
    };

    return (
        <div>
            <h1 className="page-header">
                {videoId ? t("EDIT_VIDEO") : t("ADD_VIDEO") /*localization*/}
                <NavigationButton history={_history} />
            </h1>
            {videoId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _LANGUAGE_TAB })}
              onClick={() => {
                toggle(_LANGUAGE_TAB);
              }}
            >
              {t("LANGUAGE")}
            </NavLink>
          </NavItem>
        </Nav>
            )}
            <TabContent activeTab={activeTab}>
                <TabPane tabId={_DETAIL_TAB}>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("Title" /*localization*/)}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px" }}
                                                    ref={register({ required: "PLEASE_ENTER_TEXT" })}
                                                    name="title"
                                                    defaultValue={title}
                                                />
                                                {errors.text && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.text.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("Content" /*localization*/)}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px" }}
                                                    ref={register({ required: "PLEASE_ENTER_TEXT" })}
                                                    name="content"
                                                    defaultValue={content}
                                                />
                                                {errors.text && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.text.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("STATUS")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <div className="switcher">
                                                    <input
                                                        type="checkbox"
                                                        name="status"
                                                        id="status"
                                                        onChange={(e) => setStatus(e.target.checked)}
                                                        value={true}
                                                        checked={status}
                                                        ref={register}
                                                    />
                                                    <label htmlFor="status"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
                    </div>

                </TabPane>

                <TabPane tabId={_LANGUAGE_TAB}>
                    {videoId && (
                        <div className="row">
                            <div className="col-lg-12">
                                <ManageConfigurationLanguage
                                    id={videoId}
                                    type={ContentTypeString._VIDEO_TUTORIAL}
                                />
                            </div>
                        </div>
                    )}
                </TabPane>
            </TabContent>
        </div>
    );
};

export default VideoTutorialDetail;
