import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  DisplayMode,
  LanguageKey,
  ContentTypeString,
  AllVipOption,
} from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageConfigurationLanguage from "./ManageConfigurationLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const AnnouncementDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors, setValue } = useForm();
  const [announcementId, setAnnouncementId] = useState("");
  const [announcementRefId, setAnnouncementRefId] = useState("");
  const [displayMode, setDisplayMode] = useState("");

  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [vip, setVip] = useState();
  const [vipOption, setVipOption] = useState([]);
  const [status, setStatus] = useState(true);
  const [rank, setRank] = useState(100);
  const [pinned, setPinned] = useState(false);
  const [isCompulsory, setIsCompulsory] = useState(false);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "content" }, { required: "PLEASE_ENTER_CONTENT" });
    register({ name: "vip" }, { required: "PLEASE_SELECT_VIP_LEVEL" });
    init();
  }, []);

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      let stateAnnouncementId = _location.state.id || _location.state.refId;
      var apiUrl = ApiUrl._API_GET_USER_ANNOUNCEMENT_BY_ID;
      apiUrl += "?id=" + stateAnnouncementId;
      fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (_location.state.id) {
              setAnnouncementId(responseJson[ApiKey._API_DATA_KEY]["id"]);
              setAnnouncementRefId("");
            } else if (_location.state.refId) {
              setAnnouncementId("");
              setAnnouncementRefId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            }
            setDisplayMode(responseJson[ApiKey._API_DATA_KEY]["displayModeId"]);
            setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
            setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
            setRank(responseJson[ApiKey._API_DATA_KEY]["rank"]);
            setPinned(responseJson[ApiKey._API_DATA_KEY]["pinOnTop"]);
            setIsCompulsory(responseJson[ApiKey._API_DATA_KEY]["isCompulsory"]);
            setVip(
              stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["vipId"])
                ? AllVipOption._VALUE
                : responseJson[ApiKey._API_DATA_KEY]["vipId"]
            );
            unregister("vip");
          }
        });
    }

    await fetch(ApiUrl._API_GET_USER_VIP, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const vipList = [
            { label: AllVipOption._LABEL, value: AllVipOption._VALUE },
          ];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (vipLevel) {
            vipList.push({ label: vipLevel.name, value: vipLevel.id });
          });

          setVipOption(vipList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      displayModeId: data.displayMode,
      title: data.title,
      rank: data.rank,
      content: content,
      status: data.status,
      rank: data.rank,
      pinOnTop: data.pinOnTop,
      isCompulsory: data.isCompulsory,
      vipId: stringIsNullOrEmpty(vip) ? "" : vip,
    };

    if (!stringIsNullOrEmpty(announcementRefId)) {
      params["refId"] = announcementRefId;
    }

    let formBody = createFormBody(params);

    if (!stringIsNullOrEmpty(announcementId)) {
      params["id"] = announcementId;
      _dispatch(setSaveAdminLog("EDIT_BO_ANNOUNCEMENT", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_ANNOUNCEMENT", _history.location.pathname, params));
    }

    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_ANNOUNCEMENT, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setAnnouncementId(responseJson[ApiKey._API_DATA_KEY]["id"]);
          setAnnouncementRefId("");

          _history.replace({
            pathname: _location.pathname,
            state: {
              id: responseJson[ApiKey._API_DATA_KEY]["id"],
            },
          });
        }
      });
  };

  return (
    <div>
      <h1 className="page-header">
        {announcementId ? t("EDIT_ANNOUNCEMENT") : t("ADD_ANNOUNCEMENT")}
        <NavigationButton history={_history} />
      </h1>
      {announcementId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _LANGUAGE_TAB })}
              onClick={() => {
                toggle(_LANGUAGE_TAB);
              }}
            >
              {t("LANGUAGE")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("DISPLAY_MODE")}</b>
                          </label>
                          <div className="col-md-7">
                            <select
                              className="form-control"
                              name="displayMode"
                              ref={register}
                              onChange={(e) => setDisplayMode(e.target.value)}
                              value={displayMode}
                            >
                              <option value={DisplayMode._MEMBER}>
                                {t("MEMBER")}
                              </option>
                            </select>
                            {errors.displayMode && (
                              <div className="invalid-feedback">
                                {t(errors.displayMode.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("TITLE")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_TITLE")}
                              name="title"
                              ref={register({ required: "PLEASE_ENTER_TITLE" })}
                              defaultValue={title}
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {t(errors.title.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row">
                          <label className="col-md-4 col-form-label">
                            <b>{t("VIP_LEVEL")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="vip"
                              options={vipOption}
                              placeholder={
                                vipOption.filter(
                                  (option) => option.value == vip
                                )[0] !== undefined
                                  ? vipOption.filter(
                                      (option) => option.value == vip
                                    )[0].label
                                  : ""
                              }
                              value={vipOption.filter(
                                (option) => option.value == vip
                              )}
                              onChange={(e) => {
                                unregister("vip");
                                setVip(e.value);
                              }}
                            />
                            {errors.vip && (
                              <div className="invalid-feedback">
                                {t(errors.vip.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CONTENT")}</b>
                          </label>
                          <div className="col-md-7">
                            <textarea
                              className="form-control"
                              rows="5"
                              style={{ marginTop: "10px" }}
                              ref={register}
                              name="content"
                              defaultValue={content}
                              onChange={(e) => {
                                setContent(e.target.value);
                                setValue("content", e.target.value);
                              }}
                            ></textarea>
                            {errors.content && (
                              <div className="invalid-feedback">
                                {t(errors.content.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={register}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("SEQUENCE_RANK")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="number"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_RANK")}
                              name="rank"
                              value={rank}
                              ref={register({
                                required: "PLEASE_ENTER_RANK",
                                min: {
                                  value: 0,
                                  message:
                                    "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                },
                                max: {
                                  value: 100,
                                  message:
                                    "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                },
                              })}
                              onChange={(e) => setRank(e.target.value)}
                            />
                            {errors.rank && (
                              <div className="invalid-feedback">
                                {t(errors.rank.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("PIN_ON_TOP")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="pinOnTop"
                                id="pinOnTop"
                                onChange={(e) => setPinned(e.target.checked)}
                                value={true}
                                checked={pinned}
                                ref={register}
                              />
                              <label htmlFor="pinOnTop"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("COMPULSORY_DISPLAY")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="isCompulsory"
                                id="isCompulsory"
                                onChange={(e) => setIsCompulsory(e.target.checked)}
                                value={true}
                                checked={isCompulsory}
                                ref={register}
                              />
                              <label htmlFor="isCompulsory"></label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={_LANGUAGE_TAB}>
          {announcementId && (
            <div className="row">
              <div className="col-lg-12">
                <ManageConfigurationLanguage
                  id={announcementId}
                  type={ContentTypeString._ANNOUNCEMENT}
                />
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default AnnouncementDetail;
