import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import Select from "react-select";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import moment from 'moment';
/// <summary>
/// Author : -
/// </summary>
const InstantPaySettingDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const [instantPaySettingId, setInstantPaySettingId] = useState("");
  const [instantPayBankSettingId, setInstantPayBankSettingId] = useState("");
  const [apiCode, setApiCode] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [serviceCharge, setServiceCharge] = useState("0");
  const [url, setUrl] = useState("");
  const [parameter_1, setParameter_1] = useState("");
  const [status, setStatus] = useState(true);
  const [isUm, setIsUm] = useState(false);
  const [autoWithdrawalStatus, setAutoWithdrawalStatus] = useState(true);
  const [isVIPOnly, setIsVIPOnly] = useState(true);
  const [paymentGatewayId, setPaymentGatewayId] = useState(0);
  const [minimumDeposit, setMinimumDeposit] = useState("");
  const [maximumDeposit, setMaximumDeposit] = useState("");
  const [minimumWithdrawal, setMinimumWithdrawal] = useState("");
  const [maximumWithdrawal, setMaximumWithdrawal] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankId, setBankId] = useState("");
  const [instantPayRank, setInstantPayRank] = useState("");
  const [paymentMethodTypeId, setPaymentMethodTypeId] = useState(0);
  const [instantBankSettingStatus, setInstantBankSettingStatus] = useState(true);
  const [isMaintenancePeriod, setIsMaintenancePeriod] = useState(false);
  const [maintenanceStartTime, setMaintenanceStartTime] = useState("");
  const [maintenanceEndTime, setMaintenanceEndTime] = useState("");


  const { register, handleSubmit, errors, unregister, setValue } = useForm();
  const {
    register: bankFormRegister,
    handleSubmit: bankFormHandleSubmit,
    errors: bankFormErrors,
    unregister: bankFormUnregister,
    setValue: bankFormSetValue,
  } = useForm();

  const [paymentGatewayOption, setPaymentGatewayOption] = useState([]);
  const [paymentMethodTypeOption, setPaymentMethodTypeOption] = useState([]);
  const [bankOption, setBankOption] = useState([]);
  const [instantPayBankSettingData, setInstantPayBankSettingData] = useState(
    []
  );
  const [bankSettingModal, setBankSettingModal] = useState(false);

  let _tableColumns = [
    {
      Header: "Bank Name",
      accessor: "bankName",
      disableSortBy: true,
    },
    {
      Header: "Minimum Deposit",
      accessor: "minimumDeposit",
      disableSortBy: true,
    },
    {
      Header: "Maximum Deposit",
      accessor: "maximumDeposit",
      disableSortBy: true,
    },
    {
      Header: "Minimum Withdrawal",
      accessor: "minimumWithdrawal",
      disableSortBy: true,
    },
    {
      Header: "Maximum Withdrawal",
      accessor: "maximumWithdrawal",
      disableSortBy: true,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => loadBankSetting(row.original)}>
                {t("EDIT")}
              </DropdownItem>
              <DropdownItem onClick={() => deleteBankSetting(row.original.id)}>
                <span className="text-warning">{t("DELETE")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const submitBankSettingForm = async (data, e) => {
    let params = {
      bankId: data.bankId,
      bankCode: data.bankCode,
      maximumDeposit: data.maximumDeposit,
      maximumWithdrawal: data.maximumWithdrawal,
      minimumDeposit: data.minimumDeposit,
      minimumWithdrawal: data.minimumWithdrawal,
      instantPayGatewayId: data.paymentGatewayId,
      instantPaySettingId: instantPaySettingId,
      status: data.bankSettingStatus,
    };

    if (!stringIsNullOrEmpty(instantPayBankSettingId)) {
      params["id"] = instantPayBankSettingId;
    }

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_INSTANT_PAY_BANK_SETTING, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          toggleModal();
          getInstantPayBankSetting();
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {

    _dispatch(setBusy());

    try {
      //let startTime = moment(document.getElementById("maintenanceStartTime").value, "hh:mm:ss");
      //let endTime = moment(document.getElementById("maintenanceEndTime").value, "hh:mm:ss");

      if (data.isMaintenancePeriod == true && moment(document.getElementById("maintenanceStartTime").value, "hh:mm:ss").isAfter(moment(document.getElementById("maintenanceEndTime").value, "hh:mm:ss"))) {
        throw t("START_TIME_IS_AFTER_END_TIME");
      }


    let params = {
      apiCode: data.apiCode,
      apiSecret: data.apiSecret,
      url: data.url,
      status: data.status,
      isUm: data.isUm,
      autoWithdrawalStatus: data.autoWithdrawalStatus,
      isVIPOnly: data.isVIPOnly,
      instantPayGatewayId: data.paymentGatewayId,
      parameter_1: data.parameter1,
      serviceCharge: data.serviceCharge,
      rank: data.instantPayRank,
      paymentMethodTypeId: paymentMethodTypeId,
      isMaintenancePeriod: data.isMaintenancePeriod,
      };

    if (params.isMaintenancePeriod == true) {
      params["maintenanceStartTime"] = document.getElementById("maintenanceStartTime").value;
      params["maintenanceEndTime"] = document.getElementById("maintenanceEndTime").value;
    }


    if (!stringIsNullOrEmpty(instantPaySettingId)) {
      params["id"] = instantPaySettingId;
    }

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_INSTANT_PAY_SETTING, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setInstantPaySettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
      });
    }
    catch (errorMessage) {
      _dispatch(showMessage(false, t(errorMessage)));
    }
    finally {
      _dispatch(setIdle());
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function deleteBankSetting(id) {
    fetch(
      ApiUrl._API_DELETE_INSTANT_PAYBANK_SETTING +
      "?instantPayBankSettingId=" +
      id,
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          getInstantPayBankSetting();
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    bankFormRegister({ name: "bankId" }, { required: "PLEASE_SELECT_BANK" });
    register(
      { name: "paymentGatewayId" },
      { required: "PLEASE_SELECT_PAYMENT_GATEWAY" }
    );
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_USER_INSTANT_PAY_SETTING_BY_ID;
      apiUrl += "?id=" + _location.state.id;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setInstantPaySettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setApiCode(responseJson[ApiKey._API_DATA_KEY]["apiCode"]);
            setApiSecret(responseJson[ApiKey._API_DATA_KEY]["apiSecret"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
            setIsUm(responseJson[ApiKey._API_DATA_KEY]["isUm"]);
            setAutoWithdrawalStatus(responseJson[ApiKey._API_DATA_KEY]["autoWithdrawalStatus"]);
            setIsVIPOnly(responseJson[ApiKey._API_DATA_KEY]["isVIPOnly"]);
            setUrl(responseJson[ApiKey._API_DATA_KEY]["url"]);
            setServiceCharge(responseJson[ApiKey._API_DATA_KEY]["serviceCharge"]);
            setPaymentGatewayId(responseJson[ApiKey._API_DATA_KEY]["instantPayGatewayId"]);
            setInstantPayRank(responseJson[ApiKey._API_DATA_KEY]["rank"]);
            setParameter_1(responseJson[ApiKey._API_DATA_KEY]["parameter_1"]);
            setPaymentMethodTypeId(responseJson[ApiKey._API_DATA_KEY]["paymentMethodTypeId"]);
            setValue(
              "paymentGatewayId",
              responseJson[ApiKey._API_DATA_KEY]["instantPayGatewayId"]
            );
            setValue(
              "serviceCharge",
              responseJson[ApiKey._API_DATA_KEY]["serviceCharge"]
            );
            setIsMaintenancePeriod(responseJson[ApiKey._API_DATA_KEY]["isMaintenancePeriod"]);
            setMaintenanceStartTime(responseJson[ApiKey._API_DATA_KEY]["maintenanceStartTime"]);
            setMaintenanceEndTime(responseJson[ApiKey._API_DATA_KEY]["maintenanceEndTime"]);
          }
        });

      await fetch(ApiUrl._API_GET_USER_BANK, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const bankList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
              bankList.push({ label: bank.bankName, value: bank.id });
            });

            setBankOption(bankList);
          }
        });

      await getInstantPayBankSetting();
    }

    await fetch(ApiUrl._API_GET_INSTANT_PAYMENT_GATEWAY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const paymentGatewayList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (gateway) {
            paymentGatewayList.push({ label: gateway.name, value: gateway.id });
          });

          setPaymentGatewayOption(paymentGatewayList);
        }
      });

    await fetch(ApiUrl._API_GET_PAYMENT_METHOD_TYPE, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const paymentMethodTypeList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (type) {
            paymentMethodTypeList.push({ label: type.name, value: type.id });
          });
          setPaymentMethodTypeOption(paymentMethodTypeList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function getInstantPayBankSetting() {
    await fetch(
      ApiUrl._API_GET_INSTANT_PAY_BANK_SETTING +
      "?instantPaySettingId=" +
      _location.state.id,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setInstantPayBankSettingData(responseJson[ApiKey._API_DATA_KEY]);
          }
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function loadBankSetting(data) {
    setMinimumDeposit(data["minimumDeposit"]);
    setMaximumDeposit(data["maximumDeposit"]);
    setMinimumWithdrawal(data["minimumWithdrawal"]);
    setMaximumWithdrawal(data["maximumWithdrawal"]);
    setBankCode(data["bankCode"]);
    setBankId(data["bankId"]);
    bankFormSetValue("bankId", data["bankId"]);
    setInstantPayBankSettingId(data["id"]);
    setInstantBankSettingStatus(data["status"]);
    toggleModal();
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function toggleModal() {
    if (bankSettingModal) {
      setMinimumDeposit("");
      setMaximumDeposit("");
      setMinimumWithdrawal("");
      setMaximumWithdrawal("");
      setBankId("");
      setBankCode("");
      bankFormSetValue("bankId", "");
      setInstantPayBankSettingId("");
      setInstantBankSettingStatus(true);
    }

    setBankSettingModal(!bankSettingModal);
  }

  return (
    <div>
      <h1 className="page-header">
        {!stringIsNullOrEmpty(instantPaySettingId)
          ? t("EDIT_INSTANT_PAY_SETTING")
          : t("ADD_INSTANT_PAY_SETTING")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("INSTANY_PAYMENT_GATEWAY")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="paymentGatewayId"
                              options={paymentGatewayOption}
                              placeholder={
                                paymentGatewayOption.filter(
                                  (option) => option.value == paymentGatewayId
                                )[0] !== undefined
                                  ? paymentGatewayOption.filter(
                                    (option) =>
                                      option.value == paymentGatewayId
                                  )[0].label
                                  : ""
                              }
                              value={paymentGatewayOption.filter(
                                (option) => option.value == paymentGatewayId
                              )}
                              onChange={(e) => {
                                setPaymentGatewayId(e.value);
                                setValue("paymentGatewayId", e.value);
                              }}
                            />
                            {errors.paymentGatewayId && (
                              <div className="invalid-feedback">
                                {t(errors.paymentGatewayId.message)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("API_CODE")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_API_CODE")}
                              name="apiCode"
                              defaultValue={apiCode}
                              ref={register({
                                required: "PLEASE_ENTER_API_CODE",
                              })}
                            />
                            {errors.apiCode && (
                              <div className="invalid-feedback">
                                {t(errors.apiCode.message)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("API_SECRET")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_API_SECRET")}
                              defaultValue={apiSecret}
                              name="apiSecret"
                              ref={register({
                                required: "PLEASE_ENTER_API_SECRET",
                              })}
                            />
                            {errors.apiSecret && (
                              <div className="invalid-feedback">
                                {t(errors.apiSecret.message)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("URL")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              name="url"
                              defaultValue={url}
                              placeholder={t("PLEASE_ENTER_URL")}
                              ref={register({ required: "PLEASE_ENTER_URL" })}
                            />
                            {errors.url && (
                              <div className="invalid-feedback">
                                {t(errors.url.message)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CUSTOM_PARAMETER_1")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              name="parameter1"
                              defaultValue={parameter_1}
                              placeholder={t("PLEASE_ENTER_TEXT")}
                              ref={register()}
                            />
                            {errors.parameter1 && (
                              <div className="invalid-feedback">
                                {t(errors.parameter1.message)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("SERVICE_CHARGE")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              name="serviceCharge"
                              defaultValue={serviceCharge}
                              placeholder={t("PLEASE_ENTER_AMOUNT")}
                              ref={register({
                                required: "PLEASE_ENTER_AMOUNT",
                              })}
                            />
                            {errors.serviceCharge && (
                              <div className="invalid-feedback">
                                {t(errors.serviceCharge.message)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("RANK")}</b>
                          </label>
                          <div className="col-md-7">
                            <input type="number"
                              name="instantPayRank"
                              ref={register({
                                required: "PLEASE_ENTER_RANK",
                                min: {
                                  value: 0,
                                  message: 'PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO'
                                },
                                max: {
                                  value: 999,
                                  message: 'PLEASE_ENTER_VALUE_NOT_MORE_THAN_999'
                                }
                              })}
                              className="form-control m-b-5"
                              placeholder={t("RANK")}
                              defaultValue={instantPayRank} />
                            {errors.instantPayRank && <div className="invalid-feedback">{t(errors.instantPayRank.message)}</div>}
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("PAYMENT_METHOD_TYPE")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="paymentMethodTypeId"
                              options={paymentMethodTypeOption}
                              placeholder={
                                paymentMethodTypeOption.filter(
                                  (option) => option.value == paymentMethodTypeId
                                )[0] !== undefined
                                  ? paymentMethodTypeOption.filter(
                                    (option) =>
                                      option.value == paymentMethodTypeId
                                  )[0].label
                                  : ""
                              }
                              value={paymentMethodTypeOption.filter(
                                (option) => option.value == paymentMethodTypeId
                              )}
                              onChange={(e) => {
                                setPaymentMethodTypeId(e.value);
                                setValue("paymentMethodTypeId", e.value);
                              }}
                            />
                            {errors.paymentMethodTypeId && (
                              <div className="invalid-feedback">
                                {t(errors.paymentMethodTypeId.message)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={register}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("MAINTENANCE_STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="isUm"
                                id="isUm"
                                onChange={(e) => setIsUm(e.target.checked)}
                                value={true}
                                checked={isUm}
                                ref={register}
                              />
                              <label htmlFor="isUm"></label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("AUTO_WITHDRAWAL_STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="autoWithdrawalStatus"
                                id="autoWithdrawalStatus"
                                onChange={(e) => setAutoWithdrawalStatus(e.target.checked)}
                                value={true}
                                checked={autoWithdrawalStatus}
                                ref={register}
                              />
                              <label htmlFor="autoWithdrawalStatus"></label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("ISVIPONLY")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="isVIPOnly"
                                id="isVIPOnly"
                                onChange={(e) => setIsVIPOnly(e.target.checked)}
                                value={true}
                                checked={isVIPOnly}
                                ref={register}
                              />
                              <label htmlFor="isVIPOnly"></label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("MAINTENANCE_PERIOD")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="isMaintenancePeriod"
                                id="isMaintenancePeriod"
                                onChange={(e) => setIsMaintenancePeriod(e.target.checked)}
                                checked={isMaintenancePeriod}
                                ref={register}
                              />
                              <label htmlFor="isMaintenancePeriod"></label>
                            </div>
                          </div>
                        </div>
                        {isMaintenancePeriod && <>
                          <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label">
                              <b>{t("START_TIME")}</b>
                            </label>
                            <div className="col-md-7">
                              <input type="time" id="maintenanceStartTime" name="maintenanceStartTime" onChange={(e) => setMaintenanceStartTime(e.target.value)} className="form-control" value={maintenanceStartTime} />
                              {errors.maintenanceStartTime && <div className="invalid-feedback">{t(errors.maintenanceStartTime.message)}</div>}
                            </div>
                          </div>
                          <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label">
                              <b>{t("END_TIME")}</b>
                            </label>
                            <div className="col-md-7">
                              <input type="time" id="maintenanceEndTime" name="maintenanceEndTime" onChange={(e) => setMaintenanceEndTime(e.target.value)} className="form-control" value={maintenanceEndTime} />
                              {errors.maintenanceEndTime && <div className="invalid-feedback">{t(errors.maintenanceEndTime.message)}</div>}
                            </div>
                          </div>
                        </>
                        }

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
      {!stringIsNullOrEmpty(instantPaySettingId) && (
        <div className="row">
          <div className="col-xl-12">
            <Panel>
              <PanelHeader>{t("INSTANT_BANK_SETTING")}</PanelHeader>
              <PanelBody>
                <div className="row">
                  <div className="col-xl-12">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ float: "right" }}
                      onClick={() => toggleModal()}
                    >
                      <i className="fa fa-plus-circle m-r-10"></i>
                      {t("ADD_INSTANT_BANK_SETTING")}
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <ReactTable
                      data={instantPayBankSettingData}
                      columns={_tableColumns}
                    />
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>
      )}
      <div className="row">
        <Modal isOpen={bankSettingModal} toggle={() => toggleModal()}>
          <form onSubmit={bankFormHandleSubmit(submitBankSettingForm)}>
            <ModalHeader toggle={() => toggleModal()}>
              {t("BANK_SETTING")}
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("BANK")}</b>
                    </label>
                    <div className="col-md-7">
                      <Select
                        name="bankId"
                        options={bankOption}
                        placeholder={
                          bankOption.filter(
                            (option) => option.value == bankId
                          )[0] !== undefined
                            ? bankOption.filter(
                              (option) => option.value == bankId
                            )[0].label
                            : ""
                        }
                        value={bankOption.filter(
                          (option) => option.value == bankId
                        )}
                        onChange={(e) => {
                          setBankId(e.value);
                          bankFormSetValue("bankId", e.value);
                        }}
                      />
                      {bankFormErrors.bankId && (
                        <div className="invalid-feedback">
                          {t(bankFormErrors.bankId.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("BANK_CODE")}</b>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        name="bankCode"
                        defaultValue={bankCode}
                        ref={bankFormRegister({
                          required: "PLEASE_ENTER_BANK_CODE",
                        })}
                      />
                      {bankFormErrors.bankCode && (
                        <div className="invalid-feedback">
                          {t(bankFormErrors.bankCode.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("MINIMUM_DEPOSIT")}</b>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="number"
                        className="form-control"
                        name="minimumDeposit"
                        defaultValue={minimumDeposit}
                        ref={bankFormRegister({
                          required: "PLEASE_ENTER_MINIMUM_DEPOSIT",
                          min: {
                            value: 0,
                            message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                          },
                        })}
                      />
                      {bankFormErrors.minimumDeposit && (
                        <div className="invalid-feedback">
                          {t(bankFormErrors.minimumDeposit.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("MAXIMUM_DEPOSIT")}</b>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="number"
                        className="form-control"
                        name="maximumDeposit"
                        defaultValue={maximumDeposit}
                        ref={bankFormRegister({
                          required: "PLEASE_ENTER_MAXIMUM_DEPOSIT",
                          min: {
                            value: 0,
                            message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                          },
                        })}
                      />
                      {bankFormErrors.maximumDeposit && (
                        <div className="invalid-feedback">
                          {t(bankFormErrors.maximumDeposit.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("MINIMUM_WITHDRAWAL")}</b>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="number"
                        className="form-control"
                        name="minimumWithdrawal"
                        defaultValue={minimumWithdrawal}
                        ref={bankFormRegister({
                          required: "PLEASE_ENTER_MINIMUM_WITHDRAWAL",
                          min: {
                            value: 0,
                            message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                          },
                        })}
                      />
                      {bankFormErrors.minimumWithdrawal && (
                        <div className="invalid-feedback">
                          {t(bankFormErrors.minimumWithdrawal.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("MAXIMUM_WITHDRAWAL")}</b>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="number"
                        className="form-control"
                        name="maximumWithdrawal"
                        defaultValue={maximumWithdrawal}
                        ref={bankFormRegister({
                          required: "PLEASE_ENTER_MAXIMUM_WITHDRAWAL",
                          min: {
                            value: 0,
                            message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                          },
                        })}
                      />
                      {bankFormErrors.maximumWithdrawal && (
                        <div className="invalid-feedback">
                          {t(bankFormErrors.maximumWithdrawal.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("STATUS")}</b>
                    </label>
                    <div className="col-md-7">
                      <div className="switcher">
                        <input
                          type="checkbox"
                          name="bankSettingStatus"
                          id="bankSettingStatus"
                          onChange={(e) => setInstantBankSettingStatus(e.target.checked)}
                          value={true}
                          checked={instantBankSettingStatus}
                          ref={bankFormRegister()}
                        />
                        <label htmlFor="bankSettingStatus"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-white"
                onClick={() => toggleModal()}
              >
                {t("CLOSE")}
              </button>
              <button type="submit" className="btn btn-primary">
                {t("SUBMIT")}
              </button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default InstantPaySettingDetail;
