import React, { useState } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiUrl, LanguageKey } from "../../util/Constant";
import { useForm, Controller } from "react-hook-form";
import DateTime from "react-datetime";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSaveAdminLog } from "../../redux/AppAction";

/// <summary>
/// Author : -
/// </summary>
const ManageActivityLog = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const [apiUrl, setApiUrl] = useState("");
  const _dispatch = useDispatch();
  let _history = useHistory();

  let _operationLogTableColumns = [
    {
      Header: t("ID"),
      accessor: "id",
      disableSortBy: true,
    },
    {
      Header: t("ACTION"),
      accessor: "actionKey",
      disableSortBy: true,
    },
    {
      Header: t("VALUE"),
      accessor: "value",
      disableSortBy: true,
      style: {
        wordBreak: "break-word",
      },
      minWidth: 300,
    },
    {
      Header: t("MODIFIED_DATE"),
      accessor: "createdTime",
      disableSortBy: true,
    },
    {
      Header: t("MODIFIED_BY_USER"),
      Cell: ({ row }) => {
        return !stringIsNullOrEmpty(row.original.createdByUser) ? (
          <div className="td-singleline">
            {row.original.userFullname}({row.original.createdByUser})
          </div>
        ) : (
          <div className="td-singleline">-</div>
        );
      },
    },
    {
      Header: t("MODIFIED_BY_MEMBER"),
      Cell: ({ row }) => {
        return !stringIsNullOrEmpty(row.original.createdByMember) ? (
          <div className="td-singleline">
            {row.original.memberFullname} ({row.original.createdByMember})
          </div>
        ) : (
          <div className="td-singleline">-</div>
        );
      },
    },
    {
      Header: t("IP_ADDRESS"),
      accessor: "ipAddress",
      disableSortBy: true,
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  async function submitForm(data) {
    setApiUrl(
      ApiUrl._API_GET_OPERATION_LOG +
        "?ActionKey=" +
        data.action +
        "&Value=" +
        data.value +
        "&CreatedBy=" +
        data.user +
        "&CreatedByMember=" +
        data.member +
        "&CreatedTime=" +
        (data.date ?? "") +
        "&v=" +
        Date.now()
    );

    _dispatch(setSaveAdminLog("VIEW_ACTIVITY_LOG", _history.location.pathname,data));
  }

  return (
    <div>
      <h1 className="page-header">{t("ACTIVITY_LOG")}</h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("ACTION")}</b>
                      </label>
                      <input
                        type="text"
                        ref={register}
                        name="action"
                        className="form-control m-b-5"
                        placeholder={t("FULL_OR_PART_ACTION")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("VALUE")}</b>
                      </label>
                      <input
                        type="text"
                        ref={register}
                        name="value"
                        className="form-control m-b-5"
                        placeholder={t("FULL_OR_PART_VALUE")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("USER")}</b>
                      </label>
                      <input
                        type="text"
                        ref={register}
                        name="user"
                        className="form-control m-b-5"
                        placeholder={t("FULL_OR_PART_USER")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("MEMBER")}</b>
                      </label>
                      <input
                        type="text"
                        ref={register}
                        name="member"
                        className="form-control m-b-5"
                        placeholder={t("FULL_OR_PART_MEMBER")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("DATE")}</b>
                      </label>
                      <Controller
                        control={control}
                        name="date"
                        render={({ onChange, value }) => {
                          return (
                            <DateTime
                              timeFormat={false}
                              dateFormat="YYYY-MM-DD"
                              value={value}
                              closeOnSelect={true}
                              inputProps={{ placeholder: t("SELECT_DATE") }}
                              onChange={(e) => {
                                if (e instanceof moment) {
                                  onChange(e.format("YYYY-MM-DD"));
                                } else {
                                  onChange("");
                                }
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <button className="btn btn-primary" type="submit">
                      {t("APPLY_FILTER")}
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </div>
        </div>
      </Panel>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <PanelBody>
              <ReactTable
                fetchUrl={apiUrl}
                columns={_operationLogTableColumns}
                globalFilterable={false}
              />
            </PanelBody>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default ManageActivityLog;
