import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { createFormBody, createMultiPartFormBody, navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  WebUrl,
  AccessRight,
  LanguageKey,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import moment from "moment";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";

/// <summary>
/// Author : -
/// </summary>

const ManageMasterProduct = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const [productData, setProductData] = useState([]);
  const [targetSyncProduct, setTargetSyncProduct] = useState();
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [showRangeModal, setShowRangeModal] = useState(false);
  const [showSettlementSyncSubModal, setShowSettlementSyncSubModal] = useState(
    false
  );
  const [settleDate, setSettleDate] = useState(moment().add(-1, 'day').toDate());

  let _tableColumns = [
    {
      Header: "PRODUCT_ID",
      accessor: "productId",
    },
    {
      Header: "PRODUCT_NAME",
      accessor: "productName",
    },
    {
      Header: "SHORT_CODE",
      accessor: "productCode",
    },
    {
      Header: "PRODUCT_IMAGE",
      accessor: "productImage",
      Cell: ({ row }) => (
        <div>
          {!stringIsNullOrEmpty(row.original.productImage) ? (
            <img
              style={{ width: "40px", height: "40px" }}
              src={row.original.productImage}
            />
          ) : (
            <span> - </span>
          )}
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("SUSPENDED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(
          AccessRight._MASTER_PRODUCT_CONTROL_PERMISSION,
          true
        ) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu container="body" flip={false} right>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_CREATE_OR_UPDATE_MASTER_PRODUCT,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_PRODUCT_GAME,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("MANAGE_GAME")}</span>
                </DropdownItem>
                {row.original.status == 1 && (
                  <DropdownItem onClick={() => syncProduct(row.original)}>
                    <span className="text-primary">{t("SYNC_PRODUCT")}</span>
                  </DropdownItem>
                )}
                {row.original.status == 1 && (
                  <DropdownItem
                    onClick={() => activateSyncSettlement(row.original)}
                  >
                    <span className="text-primary">
                      {t("ACTIVATE_SYNC_SETTLEMENT")}
                    </span>
                  </DropdownItem>
                )}
                {row.original.status == 1 && (
                  <DropdownItem
                    onClick={() => syncSettleGameData(row.original)}
                  >
                    <span className="text-primary">
                      {t("TRIGGER_SETTLE_SYNC_DATE")}
                    </span>
                  </DropdownItem>
                )}
                {row.original.status == 1 && (
                  <DropdownItem onClick={() => syncProductGame(row.original)}>
                    <span className="text-primary">
                      {t("SYNC_PRODUCT_GAME")}
                    </span>
                  </DropdownItem>
                )}
                {row.original.status == 1 && (
                  <DropdownItem
                    onClick={() => activateSyncGameData(row.original)}
                  >
                    <span className="text-primary">
                      {t("TRIGGER_SYNC_SCHEDULE")}
                    </span>
                  </DropdownItem>
                )}
                {row.original.status == 1 && (
                  <DropdownItem onClick={() => syncGameDataRange(row.original)}>
                    <span className="text-primary">
                      {t("TRIGGER_SYNC_RANGE")}
                    </span>
                  </DropdownItem>
                )}
                {row.original.status == 1 && row.original.productCode === "568Win" && !row.original.productName.includes("Sports") && (
                  <DropdownItem onClick={() => navigateTo(
                      _history,
                      WebUrl._URL_MANAGE_568WIN_BET_LIMIT,
                      row.original
                    )}>
                    <span className="text-primary">
                      {t("ADJUST_BET_LIMIT")}
                    </span>
                  </DropdownItem>
                )}
                {row.original.hasOwnLobby === true &&
                  row.original.status === true &&
                  row.original.productCode !== "XE88" &&
                  row.original.productCode !== "VPower" &&
                  row.original.productCode !== "MarioClub" &&
                  row.original.productCode !== "Ace333" && (
                  <DropdownItem onClick={() => updateGameList(row.original)}>
                    <span className="text-primary">{t("UPDATE_GAME_LIST")}</span>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    fetch(ApiUrl._API_GET_MASTER_PRODUCT + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setProductData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  function syncProduct(data) {
    _dispatch(setBusy());
    fetch(ApiUrl._API_SYNC_PRODUCT_DATA + "?productId=" + data["id"], {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
    adminLog("SYNC_PRODUCT", data);
  }

  function syncProductGame(data) {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_PERFORM_PRODUCT_GAME_CALCULATION + "?productId=" + data["id"],
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
      adminLog("SYNC_GAME_RECORD",data);
  }

  function activateSyncGameData(data) {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_ACTIVATE_SYNC +
        "?productId=" +
        data["id"] +
        "&interval=" +
        data["regularSyncInterval"],
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
      adminLog("ACTIVATE_SYNC_SCHEDULE",data);
  }

  function activateSyncSettlement(data) {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_ACTIVATE_SETTLEMENT_SYNC +
        "?productId=" +
        data["id"] +
        "&hh=" +
        data["settlementSyncTimeHH"] +
        "&mm=" +
        data["settlementSyncTimeMM"],
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
    adminLog("ACTIVATE_SYNC_SETTLEMENT", data);
  }

  const updateGameList = async (data) => {
    console.log(data)

    let params = {
      "id": data["id"],
      "productCode": data["productCode"],
      "productId": data["productId"]
    };

    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_GAME_LIST, createMultiPartFormBody(params))
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
      _dispatch(setIdle());
    }
  }

  function triggerRangeSync() {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_TRIGGER_RANGE_SYNC +
        "?productId=" +
        targetSyncProduct["id"] +
        "&startDate=" +
        moment(startDate).format("YYYY-MM-DD HH:mm:ss") +
        "&endDate=" +
        moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        setShowRangeModal(false);
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
      adminLog("RE_SYNC_GAME_RANGE",targetSyncProduct);
  }

  function triggerSettlementDate() {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_TRIGGER_SETTLEMENT_DATE +
        "?productId=" +
        targetSyncProduct["id"] +
        "&startDate=" +
        moment(settleDate).format("YYYY-MM-DD"),
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        setShowSettlementSyncSubModal(false);
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
    adminLog("RE_SYNC_GAME_SETTLEMENT", targetSyncProduct);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function syncGameDataRange(data) {
    setTargetSyncProduct(data);
    setShowRangeModal(true);
  }

  // <summary>
  /// Author : -
  /// </summary>
  function syncSettleGameData(data) {
    setTargetSyncProduct(data);
    setShowSettlementSyncSubModal(true);
  }

  async function adminLog(ationKey, data) {

    _dispatch(setSaveAdminLog(ationKey, _history.location.pathname, data));
  }

  return (
    <div>
      <h1 className="page-header">
        {t("MASTER_PRODUCTS")}
        {checkIfPermissionExist(
          AccessRight._MASTER_PRODUCT_CONTROL_PERMISSION,
          true
        ) && (
          <NavigationButton
            history={_history}
            url={WebUrl._URL_CREATE_OR_UPDATE_MASTER_PRODUCT}
            buttonText={t("ADD_NEW_PRODUCT")}
          />
        )}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable filterable data={productData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
      <Modal
        isOpen={showRangeModal}
        toggle={() => setShowRangeModal(false)}
        style={{ paddingBottom: "100px" }}
        className="modal-lg"
        keyboard={true}
      >
        <ModalHeader toggle={() => setShowRangeModal(false)}>
          {t("SELECT_RANGE_TO_SYNC")}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group mb-0">
                <label>
                  <b>{t("DATE")}</b>
                </label>
                <div
                  className="input-group"
                  style={{ width: "100%", flexFlow: "nowrap" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-calendar"></i>
                    </span>
                  </div>
                  <div style={{ width: "80%" }}>
                    <DateRangePicker
                      containerStyles={{ width: "100%" }}
                      startDate={startDate}
                      endDate={endDate}
                      onApply={handleEvent}
                      alwaysShowCalendars={true}
                      locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                      timePicker={true}
                      timePickerSeconds={true}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={startDate + " - " + endDate}
                      />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  triggerRangeSync();
                }}
              >
                <i className="fas fa-search"></i> {t("SUBMIT")}
              </button>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn btn-inverse-brand"
            onClick={() => setShowRangeModal(false)}
          >
            {t("CLOSE")}
          </button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showSettlementSyncSubModal}
        toggle={() => setShowSettlementSyncSubModal(false)}
        style={{ paddingBottom: "100px" }}
        className="modal-lg"
        keyboard={true}
      >
        <ModalHeader toggle={() => setShowSettlementSyncSubModal(false)}>
          {t("SELECT_DATE_TO_SYNC")}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-3">
              <label>
                <b>{t("DATE")}</b>
              </label>
              <div style={{ width: "80%" }}>
                <DatePicker
                  id="settleDatePicker"
                  className="form-control"
                  selected={settleDate}
                  onChange={(startdate) => setSettleDate(startdate)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  triggerSettlementDate();
                }}
              >
                <i className="fas fa-search"></i> {t("SUBMIT")}
              </button>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn btn-inverse-brand"
            onClick={() => setShowSettlementSyncSubModal(false)}
          >
            {t("CLOSE")}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ManageMasterProduct;
