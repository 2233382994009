import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Dropzone from "react-dropzone";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine.js";

/// <summary>
/// Author : -
/// </summary>
const EditCommunicationChannel = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const { register, handleSubmit, errors } = useForm();
  const _dispatch = useDispatch();

  const [channelName, setChannelName] = useState("");
  const [channelDescription, setChannelDescription] = useState("");
  const [remark, setRemark] = useState("");
  const [cTA, setCTA] = useState("");
  const [isLiveChat, setIsLiveChat] = useState("");
  const [status, setStatus] = useState("");
  const [rank, setRank] = useState();
  const [logoImage, setLogoImage] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [isNewImage, setIsNewImage] = useState(false);
  const [channelId, setChannelId] = useState("");
  const [languages, setLanguages] = useState([]);
  const [channelLanguages, setChannelLanguages] = useState([]);
  const [attachmentFile, setAttachmentFile] = useState();
  const [attachmentImage, setAttachmentImage] = useState();
  const [isNewAttachment, setIsNewAttachment] = useState(false);

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      channelName: data.channelName,
      channelDescription: data.channelDescription,
      remark: data.remark,
      cTA: data.cTA,
      isLiveChat: data.isLiveChat,
      status: data.status,
      rank: data.rank,
      isNewImage: isNewImage,
    };

    // For attachment
    if (!stringIsNullOrEmpty(attachmentFile) && isNewAttachment) {
      params["attachmentFile"] = attachmentFile;
    } else if (stringIsNullOrEmpty(attachmentImage)) {
      params["attachmentFile"] = "";
    }

    if (!stringIsNullOrEmpty(logoFile)) {
      params["imageFile"] = logoFile;
    }

    if (stringIsNullOrEmpty(logoImage)) {
      params["imageRemoved"] = true;
    }

    params["descLocalization"] = JSON.stringify(data.descriptionLocalization);

    if (!stringIsNullOrEmpty(channelId)) {
      params["id"] = channelId;
      _dispatch(setSaveAdminLog("EDIT_BO_COMMUNICATION_CHANNEL", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_COMMUNICATION_CHANNEL", _history.location.pathname, params));
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_COMMUNICATION_CHANNEL, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setIsNewImage(false);
          setIsNewAttachment(false);
          setChannelId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }

        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      if (_location.state) {
        var apiUrl = ApiUrl._API_GET_COMMUNICATION_CHANNEL_DETAIL;
        apiUrl += "?id=" + _location.state.id;

        let channelDetailResponse = await ApiEngine.get(apiUrl);

        if (channelDetailResponse[ApiKey._API_SUCCESS_KEY]) {
          let data = channelDetailResponse[ApiKey._API_DATA_KEY];
          setChannelId(data["id"]);
          setChannelName(data["channelName"]);
          setChannelDescription(data["channelDescription"]);
          setRemark(data["rmark"]);
          setCTA(data["cta"]);
          setIsLiveChat(data["isLiveChat"]);
          setStatus(data["status"]);
          setRank(data["rank"]);
          setChannelLanguages(data["channelLanguageModels"]);
          if (!stringIsNullOrEmpty(data["channelImage"])) {
            setLogoImage(data["channelImage"]);
          }
          if (!stringIsNullOrEmpty(data["attachment"])) {
            setAttachmentImage(data["attachment"]);
          }
        } else {
          throw t(channelDetailResponse[ApiKey._API_MESSAGE_KEY]);
        }
      }

      let languageJson = await ApiEngine.get(
        ApiUrl._API_GET_PREFERENCE_LANGUAGE
      );

      if (languageJson[ApiKey._API_SUCCESS_KEY]) {
        setLanguages(languageJson[ApiKey._API_DATA_KEY]);
      } else {
        throw t(languageJson[ApiKey._API_MESSAGE_KEY]);
      }
    } catch (err) {
      _dispatch(showMessage(false, err));
    }
  };

  /// <summary>
  /// Author : -
  /// Used to set logo
  /// </summary>
  const logoDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setLogoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoImage(reader.result);
        setIsNewImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const attachmentDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setAttachmentFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachmentImage(reader.result);
      };
      reader.readAsDataURL(file);
      setIsNewAttachment(true);
    }
  };

  return (
    <div>
      <h1 className="page-header">
        {t("CREATE_EDIT_COMMUNICATION")}
        <button
          type="button"
          className="btn btn-inverse"
          style={{ float: "right" }}
          onClick={() => {
            _history.goBack();
          }}
        >
          <i className="fas fa-long-arrow-alt-left"></i>
        </button>
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="text-truncate mb-3">
                      <div className="card-body">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CHANNEL_NAME")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              name="channelName"
                              defaultValue={channelName}
                              ref={register({
                                required: "PLEASE_ENTER_CHANNEL_NAME",
                              })}
                              placeholder={t("PLEASE_ENTER_CHANNEL_NAME")}
                            />
                            {errors.channelName && (
                              <div className="invalid-feedback">
                                {t(errors.channelName.message)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CHANNEL_DESCRIPTION")} (DEFAULT)</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              name="channelDescription"
                              defaultValue={channelDescription}
                              ref={register({
                                required: "PLEASE_ENTER_CHANNEL_DESCRIPTION",
                              })}
                              placeholder={t(
                                "PLEASE_ENTER_CHANNEL_DESCRIPTION"
                              )}
                            />
                            {errors.channelDescription && (
                              <div className="invalid-feedback">
                                {t(errors.channelDescription.message)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("REMARK")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              ref={register}
                              name="remark"
                              defaultValue={remark}
                              placeholder={t("PLEASE_ENTER_REMARK")}
                            />
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CTA")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              ref={register}
                              name="cTA"
                              defaultValue={cTA}
                              placeholder={t("PLEASE_ENTER_CTA")}
                            />
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("IS_LIVE_CHAT")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="isLiveChat"
                                id="isLiveChat"
                                onChange={(e) =>
                                  setIsLiveChat(e.target.checked)
                                }
                                value={true}
                                checked={isLiveChat}
                                ref={register}
                              />
                              <label htmlFor="isLiveChat"></label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={register}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("RANK")}</b>
                          </label>
                          <div className="col-md-7">
                               <input
                                        type="number"
                                        className="form-control"
                                        placeholder={t("PLEASE_ENTER_RANK")}
                                        name="rank"
                                        defaultValue={rank}
                                        ref={register({
                                            required: "PLEASE_ENTER_RANK",
                                            min: {
                                                value: 0,
                                                message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                            },
                                            max: {
                                                value: 100,
                                                message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                            },
                                        })}
                              />
                              {errors.rank && (
                                        <div className="invalid-feedback">
                                            {t(errors.rank.message)}
                                        </div>
                                    )}
                          </div>
                        </div>
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CHANNEL_LOGO")}</b>
                          </label>
                          <div className="col-md-7">
                            <Dropzone
                              accept={"image/*"}
                              onDrop={(acceptedFiles) =>
                                logoDrop(acceptedFiles)
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="dropzone"
                                    style={{
                                      minHeight: "200px",
                                      textAlign: "center",
                                    }}
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <h4 style={{ color: "grey" }}>
                                      {t("SELECT_OR_DRAG_AND_DROP_FILE")}
                                    </h4>
                                    {!stringIsNullOrEmpty(logoImage) && (
                                      <aside className="thumbsContainer">
                                        <div className="thumb">
                                          <div className="thumbInner">
                                            <img
                                              src={logoImage}
                                              className="dropzone-img"
                                            />
                                          </div>
                                        </div>
                                      </aside>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                            <br />
                            <br />
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setLogoImage("");
                                setLogoFile();
                              }}
                            >
                              {t("REMOVE_LOGO")}
                            </button>
                          </div>
                        </div>

                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("ATTACHMENT")}</b>
                          </label>
                          <div className="col-md-7">
                            <Dropzone
                              accept={"image/*"}
                              onDrop={(acceptedFiles) =>
                                attachmentDrop(acceptedFiles)
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="dropzone"
                                    style={{
                                      minHeight: "200px",
                                      textAlign: "center",
                                    }}
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <h4 style={{ color: "grey" }}>
                                      {t("SELECT_OR_DRAG_AND_DROP_FILE")}
                                    </h4>
                                    {!stringIsNullOrEmpty(attachmentImage) && (
                                      <aside className="thumbsContainer">
                                        <div className="thumb">
                                          <div className="thumbInner">
                                            <img
                                              src={attachmentImage}
                                              className="dropzone-img"
                                            />
                                          </div>
                                        </div>
                                      </aside>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                            <br />
                            <br />
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setAttachmentImage("");
                                setAttachmentFile();
                              }}
                            >
                              {t("REMOVE")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card-body">
                      {languages.map((lang) => {
                        return (
                          <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label">
                              <b>
                                {t("CHANNEL_DESCRIPTION")} ({lang.name})
                              </b>
                            </label>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className="form-control"
                                name={`descriptionLocalization[${lang.name}]`}
                                defaultValue={
                                  channelLanguages.find(
                                    (l) => l.languageId == lang.id
                                  )?.content
                                }
                                ref={register()}
                                placeholder={t(
                                  "PLEASE_ENTER_CHANNEL_DESCRIPTION"
                                )}
                              />
                              {errors.channelDescription && (
                                <div className="invalid-feedback">
                                  {t(errors.channelDescription.message)}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EditCommunicationChannel);
