import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch } from "react-redux";
import { ApiKey, ApiUrl, LanguageKey, DEFAULT_PAGE_SIZE, BetStatus } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import { useTranslation } from "react-i18next";
import { AsyncPaginate } from "react-select-async-paginate";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable.js";
import { setSaveAdminLog } from "../../redux/AppAction.js";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const FirstDepositReport = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const ALL_OPTION_LABEL = "All";
  const ALL_OPTION_VALUE = "all";
  const _SELECTED_MEMBER_KEY = "selectedMemberKey";
  const [search, setSearch] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [label, setLabel] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [firstDepositUrl, setFirstDepositUrl] = useState("");

  let _tableColumns = [
    {
      Header: "Username",
      accessor: "username",
      minWidth: 100,
    },
    {
      Header: "Member Created Time",
      accessor: "createdTime",
      minWidth: 100,
      Cell: ({ row }) => {
        return <div>{moment(row.original.createdTime).format("YYYY-MM-DD HH:mm:ss")}</div>
      },
    },
    {
      Header: "First Deposit Time",
      accessor: "firstDepositTime",
      minWidth: 100,
      Cell: ({ row }) => {
        return <div>{moment(row.original.firstDepositTime).format("YYYY-MM-DD HH:mm:ss")}</div>
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      minWidth: 100,
      Cell: ({ row }) => {
        return <div style={{ textAlign: "right" }}>{numberWithCurrencyFormat(row.original.amount)}</div>
      },
    }
  ]

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setSelectedDate(moment(picker.startDate).format("YYYY-MM-DD"));
  };



  /// <summary>
  /// Author : -
  /// </summary>
  const searchBtnHandler = async (selected) => {
    let apiUrl = ApiUrl._API_GET_FIRST_DEPOSIT_REPORT + "?SelectedDate=" + selectedDate + "&memberId=" + selectedMember;

    setFirstDepositUrl(apiUrl);
  };

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  async function adminLog() {
    let params = {};

    if (!stringIsNullOrEmpty(selectedDate)) {
      params["selectedDate"] = selectedDate;
    }

    if (!stringIsNullOrEmpty(selectedMember)) {
      params["selectedMember"] = selectedMember;
    }

    _dispatch(setSaveAdminLog("VIEW_FIRST_DEPOSIT_REPORT", _history.location.pathname, params));

  }
  return (
    <div>
      <h1 className="page-header">
        {stringIsNullOrEmpty(props.id) && t("FIRST_DEPOSIT_REPORT")}
      </h1>
      <Panel
        style={{
          marginBottom: !stringIsNullOrEmpty(props.id) ? "0px" : "20px",
        }}
      >
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      width: "100%",
                      flexFlow: "nowrap",
                    }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          singleDatePicker={true}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={selectedDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div className="input-group member-group" style={{ width: "100%" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <AsyncPaginate
                        placeholder={label}
                        debounceTimeout={250}
                        loadOptions={getMemberList}
                        additional={{
                          page: 1,
                        }}
                        value={selectedMember}
                        onChange={(e) => {
                          if (!stringIsNullOrEmpty(e.value)) {
                            sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                          } else {
                            sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                          }
                          setLabel(e.label);
                          setSelectedMember(e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setSearch(true);
                      searchBtnHandler();
                      adminLog();
                    }}
                  >
                    <i className="fas fa-search"></i> {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <ReactTable fetchUrl={firstDepositUrl} columns={_tableColumns} exportRequired />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default FirstDepositReport;