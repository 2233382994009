import React, { useState, useEffect } from "react";
import {
	ApiKey,
	ApiUrl,
	LanguageKey,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { useHistory } from "react-router-dom";
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";

/// <summary>
/// Author : -
/// </summary>
const ManageDailyCheckIn = (props) => {
	const _DETAIL_TAB = "1";
	const _LANGUAGE_TAB = "2";
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _dispatch = useDispatch();
	const [status, setStatus] = useState(false);
	const [activeTab, setActiveTab] = useState(_DETAIL_TAB);
	const [languageDetailId, setLanguageDetailId] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [noOfRows, setNoOfRows] = useState(1);
	const [isCreated, setIsCreated] = useState(0);
	const [languageOption, setLanguageOption] = useState([]);
	const [languageId, setLanguageId] = useState("");
	const [newContent, setNewContent] = useState("");
	const [dailyChechInLanguage, setDailyChechInLanguage] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [editContent, setEditContent] = useState("");
	const [editLanguageId, setEditLanguageId] = useState("");
	let _tableColumns = [
		{
			Header: "LANGUAGE",
			accessor: "languageName",
			disableSortBy: true,
		},
		{
			Header: "CONTENT",
			accessor: "content",
			disableSortBy: true,
		},
		{
			id: "action",
			Header: "",
			Cell: ({ row }) => (
				<div className="btn-group m-r-5 m-b-5">
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="default">
							<i className="fas fa-cog"></i>
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem
								onClick={() => {
									setEditModal(true);
									setEditLanguageId(row.original.languageId);
									setEditContent(row.original.content);
								}}

							>
								<span className="text-warning">{t("EDIT")}</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledButtonDropdown>
				</div>
			),
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" },
		},
	];



	const {
		register,
		handleSubmit,
		errors,
		watch,
		trigger,
		setValue,
		getValues,
		unregister,
	} = useForm({ mode: "onChange" });

	const [data, setData] = useState([]);
	const [content, setContent] = useState("");

	/// <summary>
	/// Author : -
	/// </summary>
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	useEffect(() => {
		init();
		getLanguage();
		getDailyCheckInLangauge();
	}, []);

	async function init() {
		let processedData = [];
		let responseJson = await ApiEngine.get(
			ApiUrl._API_GET_DAILY_CHECK_IN
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			if (responseJson[ApiKey._API_DATA_KEY].length > 0) {
				const result = responseJson[ApiKey._API_DATA_KEY];
				result.map((x) => {
					processedData.push({
						id: x.id,
						label: x.days,
						points: x.points,
						credit: x.credit,
						min_turnover: x.minTurnover,
						max_day: x.maxDay,
						isCredit: x.isCredit,
						isReset: x.isReset,
						status: x.status,
						content: x.content
					});
				});
			} else {
				var week = new Array(
					"Monday",
					"Tuesday",
					"Wednesday",
					"Thursday",
					"Friday",
					"Saturday",
					"Sunday"
				);


				for (let i = 0; i < 7; i++) {
					let option = {
						id: i,
						label: week[i],
						points: 0,
						credit: 0,
						min_turnover: 0,
						max_day: 0,
						isCredit: false,
						isReset: false,
						status: false,
						content: ""
					};
					processedData.push(option);
				}
			}
		}

		setData(processedData);
		setStatus(processedData[0]["status"]);
		setContent(processedData[0]["content"]);
	}

	const handlePointsOnChange = (id, points, name) => {
		if (name == "point") {
			setData(
				data.map((item) => (item.id === id ? { ...item, points: points } : item))
			);
		} else if (name == "credit") {
			setData(
				data.map((item) => (item.id === id ? { ...item, credit: points } : item))
			);
		} else if (name == "min_turnover") {
			setData(
				data.map((item) => (item.id === id ? { ...item, min_turnover: points } : item))
			);
		} else if (name == "max_day") {
			setData(
				data.map((item) => (item.id === id ? { ...item, max_day: points } : item))
			);
		}
	};

	const handleIsCreditChage = (id, isCredit) => {
		setData(
			data.map((item) => (item.id === id ? { ...item, isCredit: isCredit } : item))
		);
	};

	const handleResetChange = (id) => {
		var dataList = data;
		var data2 = dataList.map((item) => item.isReset ? { ...item, isReset: false } : item)
		var data3 = data2.map((item) => (item.id === id ? { ...item, isReset: true } : item))
		setData(data3);
	}

	async function handleOnSubmit(data) {
		let labelList = [];
		let pointsList = [];
		let isCreditList = [];
		let creditList = [];
		let minTurnoverList = [];
		let maxDayList = [];
		let isResetList = [];


		for (var i = 0; i < data.length; i++) {
			labelList.push(data[i]["label"]);
			pointsList.push(data[i]["points"]);
			isCreditList.push(data[i]["isCredit"]);
			creditList.push(data[i]["credit"]);
			minTurnoverList.push(data[i]["min_turnover"]);
			maxDayList.push(data[i]["max_day"]);
			isResetList.push(data[i]["isReset"]);
		}

		let params = {
			label: labelList,
			points: pointsList,
			isCredit: isCreditList,
			credit: creditList,
			minTurnover: minTurnoverList,
			maxDay: maxDayList,
			isReset: isResetList,
			status: status,
			content: content
		};

		let formBody = createFormBody(params);
		_dispatch(setBusy());

		fetch(ApiUrl._API_CREATE_OR_UPDATE_DAILY_CHECK_IN_DETAILS, {
			method: ApiKey._API_POST,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					init();
				}
			});
	}

	async function getLanguage() {
		await fetch(ApiUrl._API_GET_LANGUAGE, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					const languageList = [];
					responseJson[ApiKey._API_DATA_KEY].forEach(function (language) {
						languageList.push({ label: language.name, value: language.id });
					});
					setLanguageOption(languageList);
				}
			});
	}

	async function getDailyCheckInLangauge() {

		var apiUrl = ApiUrl._API_GET_DAILY_CHECK_IN_LANGUAGE;
		console.log("api", apiUrl);
		fetch(apiUrl, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setDailyChechInLanguage(responseJson[ApiKey._API_DATA_KEY]);
				}
			});
	}

	async function onSubmit(method) {
		let params = [];
		if (method == "create") {
			params = {
				languageId: languageId,
				content: newContent
			}
		} else {
			params = {
				languageId: editLanguageId,
				content: editContent
			}
		}
		let formBody = createFormBody(params);
		_dispatch(setBusy());

		fetch(ApiUrl._API_CREATE_OR_UPDATE_DAILY_CHECK_IN_LANGUAGE, {
			method: ApiKey._API_POST,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setIsOpen(false);
					setEditModal(false);
					getDailyCheckInLangauge();
				}
			});

	}

	return (
		<div className="row">
			<div className="col-lg-12">
				<h1 className="page-header">
					{t("MANAGE_DAILY_CHECK_IN")}{" "}
				</h1>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _DETAIL_TAB })}
							onClick={() => {
								toggle(_DETAIL_TAB);
							}}
						>
							{t("DETAIL")}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _LANGUAGE_TAB })}
							onClick={() => {
								toggle(_LANGUAGE_TAB);
							}}
						>
							{t("LANGUAGE")}
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId={_DETAIL_TAB}>
						<Panel>
							<PanelBody>
								<div className="check-in-div">
									<div className="col-lg-8">
										<div className="form-group row m-b-15">
											<label className="col-md-4 col-form-label">
												<b>{t("STATUS")}</b>
											</label>
											<div className="col-md-7">
												<div className="switcher">
													<input
														type="checkbox"
														name="status"
														id="status"
														onChange={(e) => setStatus(e.target.checked)}
														value={true}
														checked={status}
														ref={register}
													/>
													<label htmlFor="status"></label>
												</div>
											</div>
										</div>
									</div>
									<div className="form-group row m-b-15">
										<label className="col-md-2 col-form-label" style={{ paddingLeft: '25px' }}>
											<b>{t("CONTENT")}</b>
										</label>
										<div className="col-md-8" style={{ paddingLeft: '75px' }} >
											<CKEditor
												editor={ClassicEditor}
												data={content}
												name="content"
												onInit={(editor) => {
													editor.setData(content);
													editor.editing.view.change((writer) => {
														writer.setStyle(
															"height",
															"200px",
															editor.editing.view.document.getRoot()
														);
													});
												}}
												onChange={(event, editor) => {
													const data = editor.getData();
													setContent(data);
													if (stringIsNullOrEmpty(data)) {
														register(
															{ name: "content" },
															{ required: "PLEASE_ENTER_CONTENT" }
														);
													} else {
														unregister("content");
													}
												}}
											/>
											{errors.content && (
												<div className="invalid-feedback">
													{t(errors.content.message)}
												</div>
											)}
										</div>
									</div>
									<hr />
									<div className="row">
										{data.map((item, i) => {
											return (
												<div className="col-lg-3" key={i}>
													<div className="card m-b-5">
														<div
															className="card-header"
															style={{
																whiteSpace: "normal",
																wordBreak: "break-word",
																textAlign: "center",
																display: "flex",
																justifyContent: "space-between"
															}}
														>
															<div></div>
															<div><b>{item.label}</b></div>
															<div>
																<input
																	type="checkbox"
																	name={"checkMode_" + i}
																	id={"checkMode_" + i}
																	onChange={(e) => {
																		handleResetChange(item.id);
																	}}
																	checked={item.isReset}
																	ref={register}
																/>
															</div>
														</div>
														<div className="row">
															<div className="col-lg-12">
																<div className="row m-5">
																	<div
																		className="col-lg-6"
																		style={{ display: "flex", alignItems: "center" }}
																	>
																		<b>{t("IsCredit")}</b>
																	</div>
																	<div className="col-lg-6">
																		<div className="switcher">
																			<input
																				type="checkbox"
																				name="displayMode"
																				id={"displayMode-" + item.id}
																				onChange={(e) => {
																					handleIsCreditChage(item.id, e.target.checked);
																				}}
																				value={true}
																				checked={item.isCredit}
																				ref={register}
																			/>
																			<label htmlFor={"displayMode-" + item.id}></label>
																		</div>
																	</div>
																</div>
																{item.isCredit == false ?
																	(
																		<div className="row m-5">
																			<div
																				className="col-lg-6"
																				style={{ display: "flex", alignItems: "center" }}
																			>
																				<b>{t("POINTS")}</b>
																			</div>
																			<div className="col-lg-6">
																				<input
																					type="number"
																					className="form-control m-b-5"
																					ref={register({
																						min: {
																							value: 1,
																							message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ONE",
																						},
																						max: {
																							value: 1000,
																							message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_1000",
																						},
																					})}
																					step="0.01"
																					onChange={(e) => {
																						handlePointsOnChange(item.id, e.target.value, "point");
																					}}
																					defaultValue={item.points}
																					name={"day-" + item.id}
																					pattern="^\d+(?:\.\d{1,2})?$"
																				/>
																				{errors["day-" + item.id] && (
																					<div className="invalid-feedback">
																						{t(errors["day-" + item.id].message, {
																							value: 100,
																						})}
																					</div>
																				)}
																			</div>
																		</div>
																	)
																	:
																	<div>
																		<div className="row m-5">
																			<div
																				className="col-lg-6"
																				style={{ display: "flex", alignItems: "center" }}
																			>
																				<b>{t("CREDIT")}</b>
																			</div>
																			<div className="col-lg-6">
																				<input
																					type="number"
																					className="form-control m-b-5"
																					ref={register({
																						min: {
																							value: 1,
																							message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ONE",
																						},
																					})}
																					step="1"
																					onChange={(e) => {
																						handlePointsOnChange(item.id, e.target.value, "credit");
																					}}
																					defaultValue={item.credit}
																					name={"day-" + item.id}
																					pattern="^\d+(?:\.\d{1,2})?$"
																				/>
																			</div>
																		</div>
																		<div className="row m-5">
																			<div
																				className="col-lg-6"
																				style={{ display: "flex", alignItems: "center" }}
																			>
																				<b>{t("Min. Tunover")}</b>
																			</div>
																			<div className="col-lg-6">
																				<input
																					type="number"
																					className="form-control m-b-5"
																					ref={register({
																						min: {
																							value: 1,
																							message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ONE",
																						},
																					})}
																					step="1"
																					onChange={(e) => {
																						handlePointsOnChange(item.id, e.target.value, "min_turnover");
																					}}
																					defaultValue={item.min_turnover}
																					name={"day-" + item.id}
																					pattern="^\d+(?:\.\d{1,2})?$"
																				/>
																			</div>
																		</div>
																		<div className="row m-5">
																			<div
																				className="col-lg-6"
																				style={{ display: "flex", alignItems: "center" }}
																			>
																				<b>{t("Max days for min. turnover")}</b>
																			</div>
																			<div className="col-lg-6">
																				<input
																					type="number"
																					className="form-control m-b-5"
																					ref={register({
																						min: {
																							value: 1,
																							message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ONE",
																						},
																						max: {
																							value: 1000,
																							message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_1000",
																						},
																					})}
																					step="1"
																					onChange={(e) => {
																						handlePointsOnChange(item.id, e.target.value, "max_day");
																					}}
																					defaultValue={item.max_day}
																					name={"day-" + item.id}
																					pattern="^\d+(?:\.\d{1,2})?$"
																				/>
																			</div>
																		</div>
																	</div>
																}
															</div>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
								<br />
								<div className="row">
									<div className="col-lg-12 m-5 text-right">
										<button
											type="submit"
											className="btn btn-primary"
											onClick={() =>
												handleOnSubmit(data)
											}
										>
											{t("SUBMIT")}
										</button>
									</div>
								</div>
							</PanelBody>
						</Panel>
					</TabPane>
					<TabPane tabId={_LANGUAGE_TAB}>
						<div className="row">
							<div className="col-lg-12">
								<button
									type="button"
									onClick={() => {
										setLanguageDetailId("");
										setIsCreated(1);
										setIsOpen(true);
										setNoOfRows(1);
										setNewContent("");
										setLanguageId("");
									}}
									className="btn btn-purple"
								>
									{t("ADD_LANGUAGE")}
								</button>
								<ReactTable data={dailyChechInLanguage} columns={_tableColumns} minRows={1} />
								<Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={isOpen} centered>
									<ModalHeader>
										{t("ADD_LANGUAGE")}
									</ModalHeader>
									<ModalBody style={{ minHeight: "30vh" }}>
										<div className="row">
											<div className="col-lg-12">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("LANGUAGE")}</b>
													</label>
													<div className="col-md-7">
														<Select
															name="languageId"
															options={languageOption}
															placeholder={
																languageOption.filter(
																	(option) => option.value == languageId
																)[0] !== undefined
																	? languageOption.filter(
																		(option) =>
																			option.value == languageId
																	)[0].label
																	: ""
															}
															value={languageOption.filter(
																(option) => option.value == languageId
															)}
															onChange={(e) => {
																setLanguageId(e.value);
																setValue("languageId", e.value);
															}}
														/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("CONTENT")}</b>
													</label>
													<div className="col-md-8">
														<CKEditor
															editor={ClassicEditor}
															data={newContent}
															name="newContent"
															onInit={(editor) => {
																editor.setData(newContent);
																editor.editing.view.change((writer) => {
																	writer.setStyle(
																		"height",
																		"300px",
																		editor.editing.view.document.getRoot()
																	);
																});
															}}

															onChange={(event, editor) => {
																const data = editor.getData();
																setNewContent(data);
																if (stringIsNullOrEmpty(data)) {
																	register(
																		{ name: "newContent" },
																		{ required: "PLEASE_ENTER_CONTENT" }
																	);
																} else {
																	unregister("newContent");
																}
															}}
														/>
														{errors.newContent && (
															<div className="invalid-feedback">
																{t(errors.newContent.message)}
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</ModalBody>
									<ModalFooter>
										<button
											type="button"
											className="btn btn-white"
											onClick={() => setIsOpen(false)}
										>
											{t("CLOSE")}
										</button>
										<button type="submit" className="btn btn-primary" onClick={() => onSubmit("create")}>
											{t("SUBMIT")}
										</button>
									</ModalFooter>
								</Modal>
								<Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={editModal} centered>
									<ModalHeader>
										{t("EDIT_LANGUAGE")}
									</ModalHeader>
									<ModalBody style={{ minHeight: "30vh" }}>
										<div className="row">
											<div className="col-lg-12">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("LANGUAGE")}</b>
													</label>
													<div className="col-md-7">
														<Select
															name="editLanguageId"
															options={languageOption}
															placeholder={
																languageOption.filter(
																	(option) => option.value == editLanguageId
																)[0] !== undefined
																	? languageOption.filter(
																		(option) =>
																			option.value == editLanguageId
																	)[0].label
																	: ""
															}
															defaultValue={languageOption.filter(
																(option) => option.value == editLanguageId
															)}
															value={languageOption.filter(
																(option) => option.value == editLanguageId
															)}
															onChange={(e) => {
																setEditLanguageId(e.value);
																setValue("editLanguageId", e.value);
															}}
														/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("CONTENT")}</b>
													</label>
													<div className="col-md-8">
														<CKEditor
															editor={ClassicEditor}
															data={editContent}
															name="editContent"
															onInit={(editor) => {
																editor.setData(editContent);
																editor.editing.view.change((writer) => {
																	writer.setStyle(
																		"height",
																		"300px",
																		editor.editing.view.document.getRoot()
																	);
																});
															}}

															onChange={(event, editor) => {
																const data = editor.getData();
																setEditContent(data);
																if (stringIsNullOrEmpty(data)) {
																	register(
																		{ name: "editContent" },
																		{ required: "PLEASE_ENTER_CONTENT" }
																	);
																} else {
																	unregister("editContent");
																}
															}}
														/>
														{errors.editContent && (
															<div className="invalid-feedback">
																{t(errors.editContent.message)}
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</ModalBody>
									<ModalFooter>
										<button
											type="button"
											className="btn btn-white"
											onClick={() => setEditModal(false)}
										>
											{t("CLOSE")}
										</button>
										<button type="submit" className="btn btn-primary" onClick={() => onSubmit("edit")}>
											{t("SUBMIT")}
										</button>
									</ModalFooter>
								</Modal>
							</div>
						</div>
					</TabPane>
				</TabContent>
			</div>
		</div>
	);
};

export default ManageDailyCheckIn;
