import React, { useState, useEffect } from "react";
import Select from "react-select";
import CKEditor from "@ckeditor/ckeditor5-react";
import Dropzone from "react-dropzone";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  ApiKey,
  ApiUrl,
  ContentTypeString,
  LanguageKey,
} from "../../util/Constant";
import { useForm } from "react-hook-form";
import { stringIsNullOrEmpty, createFormBody, createMultiPartFormBody } from "../../util/Util";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const ConfigurationLanguageDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();

  const [desktopFile, setDesktopFile] = useState([]);
  const [mobileFile, setMobileFile] = useState([]);
  const [desktopImage, setDesktopImage] = useState();
  const [mobileImage, setMobileImage] = useState();
  const [isNewDesktopImage, setIsNewDesktopImage] = useState(false);
  const [isNewMobileImage, setIsNewMobileImage] = useState(false);

  const _CUSTOM_CONTENT_REGISTER = [
    ContentTypeString._CONTENT,
    ContentTypeString._EMAIL,
    ContentTypeString._PROMOTION,
    ContentTypeString._SCRIPT,
    ContentTypeString._SEO,
    ContentTypeString._VIP_CRITERIA
  ];
  const _EXCLUDE_TITLE_TYPE = [
    ContentTypeString._VIP_BENEFIT_CONTENT,
    ContentTypeString._VIP_CRITERIA,
  ];
  const _EXCLUDE_CONTENT_TYPE = [
    ContentTypeString._VIP_BENEFIT,
    ContentTypeString._PRODUCT_CATEGORY
  ];

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    setValue,
    clearErrors,
    trigger,
    getValues,
  } = useForm();
  const [languageId, setLanguageId] = useState("");

  const [languageDetailId, setLanguageDetailId] = useState();
  const [languageType, setLanguageType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [dataTypeId, setDataTypeId] = useState("");
  const [dataDisplayDevice, setDataDisplayDevice] = useState(0);
  const [languageOption, setLanguageOption] = useState([]);
  const [languageModalVisible, setLanguageModalVisible] = useState(false);

  /// <summary>
  /// Author : -
  /// </summary>
  const logoDrop = (acceptedFiles, isDesktop) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      if (isDesktop) {
        setDesktopFile(file);
      } else {
        setMobileFile(file);
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (isDesktop) {
          setIsNewDesktopImage(true);
          setDesktopImage(reader.result);
        } else {
          setIsNewMobileImage(true);
          setMobileImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const submit = async (data, e) => {
    let params = {
      type: languageType,
      languageId: data.languageId,
      dataId: dataTypeId,
      isNewDesktopFile: isNewDesktopImage,
      isNewMobileFile: isNewMobileImage,
    };

    if (!_EXCLUDE_CONTENT_TYPE.includes(languageType)) {
      params["content"] = data.content;
    }

    if (!_EXCLUDE_TITLE_TYPE.includes(languageType)) {
      params["title"] = data.title;
    }

    if (!stringIsNullOrEmpty(languageDetailId)) {
      params["id"] = languageDetailId;
    }

    if (languageType == ContentTypeString._PROMOTION) {
      params["description"] = data.description;
    }

    if (!stringIsNullOrEmpty(desktopFile)) {
      params["desktopFile"] = desktopFile;
    }

    if (stringIsNullOrEmpty(desktopImage)) {
      params["desktopImageRemoved"] = true;
    }

    if (!stringIsNullOrEmpty(mobileFile)) {
      params["mobileFile"] = mobileFile;
    }

    if (stringIsNullOrEmpty(mobileImage)) {
      params["mobileImageRemoved"] = true;
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_CONFIG_LANGUAGE, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          cleanup();
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function cleanup() {
    unregister("content");
    unregister("languageId");
    setLanguageDetailId("");
    setLanguageId("");
    setContent("");
    setTitle("");
    setDesktopImage("");
    setMobileImage("");
    setIsNewDesktopImage(true);
    setIsNewMobileImage(true);
    setDesktopFile([]);
    setMobileFile([]);
    setValue("title", "");
    setValue("content", "");
    setValue("languageId", "");
    props.onFinish();
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (languageModalVisible) {
      initForAssignment();
    } else {
      cleanup();
    }
  }, [languageModalVisible]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    setLanguageDetailId(props["languageDetailId"]);
    if (stringIsNullOrEmpty(props["languageDetailId"])) {
      setDesktopImage("");
      setMobileImage("");
    }
    setLanguageType(props["languageType"]);
    setDataTypeId(props["dataTypeId"]);
    setLanguageModalVisible(props["visible"]);
  }, [props["visible"]]);

  /// <summary>
  /// Author : -
  /// Editted: - (Change register content)
  /// </summary>
  async function initForAssignment() {
    register({ name: "languageId" }, { required: "PLEASE_SELECT_LANGUAGE" });

    if (_CUSTOM_CONTENT_REGISTER.includes(languageType)) {
      register({ name: "content" }, { required: "PLEASE_ENTER_CONTENT" });
    }

    await fetch(ApiUrl._API_GET_LANGUAGE, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const languageList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (language) {
            languageList.push({ label: language.name, value: language.id });
          });

          setLanguageOption(languageList);
        }
      });

    clearErrors();

    if (!stringIsNullOrEmpty(languageDetailId)) {
      await fetch(
        ApiUrl._API_GET_CONFIG_LANGUAGE_BY_ID +
        "?type=" +
        languageType +
        "&id=" +
        languageDetailId,
        {
          method: ApiKey._API_GET,
          headers: {
            "Content-Type": ApiKey._API_FORM_URLENCODED,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setDataDisplayDevice(responseJson[ApiKey._API_DATA_KEY]["dataDisplayDevice"]);
            setLanguageDetailId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setLanguageId(responseJson[ApiKey._API_DATA_KEY]["languageId"]);
            setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
            setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
            setDescription(responseJson[ApiKey._API_DATA_KEY]["description"]);
            if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["desktopImage"])) {
              setDesktopImage(responseJson[ApiKey._API_DATA_KEY]["desktopImage"]);
            }
            else {
              setDesktopImage("");
            }
            if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["mobileImage"])) {
              setMobileImage(responseJson[ApiKey._API_DATA_KEY]["mobileImage"]);
            }
            else {
              setMobileImage("");
            }
            setValue("description", responseJson[ApiKey._API_DATA_KEY]["description"]);
            setValue("content", responseJson[ApiKey._API_DATA_KEY]["content"]);

            setValue(
              "languageId",
              responseJson[ApiKey._API_DATA_KEY]["languageId"]
            );

            setValue("title", responseJson[ApiKey._API_DATA_KEY]["title"]);
          }
        });
    } else {
      setDataDisplayDevice(props.dataDisplayDevice);
      setLanguageDetailId("");
      setLanguageId("");
      setContent("");
      setTitle("");
      setValue("title", "");
      setValue("content", "");
      setValue("languageId", "");
    }
  }

  return (
    <div>
      <div className="row">
        <Modal
          isOpen={languageModalVisible}
          toggle={() => cleanup()}
          size="lg"
          centered
        >
          <form onSubmit={handleSubmit(submit)}>
            <ModalHeader toggle={() => cleanup()}>
              {!stringIsNullOrEmpty(languageDetailId)
                ? t("EDIT_LANGUAGE")
                : t("ADD_LANGUAGE")}
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("LANGUAGE")}</b>
                    </label>
                    <div className="col-md-7">
                      <Select
                        name="languageId"
                        options={languageOption}
                        placeholder={
                          languageOption.filter(
                            (option) => option.value == languageId
                          )[0] !== undefined
                            ? languageOption.filter(
                              (option) => option.value == languageId
                            )[0].label
                            : ""
                        }
                        value={languageOption.filter(
                          (option) => option.value == languageId
                        )}
                        onChange={(e) => {
                          setValue("languageId", e.value);
                          setLanguageId(e.value);
                          clearErrors("languageId");
                        }}
                      />
                      {errors.languageId && (
                        <div className="invalid-feedback">
                          {t(errors.languageId.message)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!_EXCLUDE_TITLE_TYPE.includes(languageType) && (
                  <div className="col-lg-12">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("TITLE")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_TITLE")}
                          name="title"
                          ref={register({ required: "PLEASE_ENTER_TITLE" })}
                          defaultValue={title}
                        />
                        {errors.title && (
                          <div className="invalid-feedback">
                            {t(errors.title.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!_EXCLUDE_CONTENT_TYPE.includes(languageType) && (
                  <div className="col-lg-12">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("CONTENT")}</b>
                      </label>
                      <div className="col-md-7">
                        {languageType == ContentTypeString._BANNER ||
                          /*                          languageType == ContentTypeString._PROMOTION ||*/
                          languageType == ContentTypeString._ANNOUNCEMENT ||
                          languageType == ContentTypeString._VIDEO_TUTORIAL ||
                          languageType == ContentTypeString._CONTENT ||
                          languageType ==
                          ContentTypeString._VIP_BENEFIT_CONTENT ? (
                          <textarea
                            className="form-control"
                            rows="5"
                            name="content"
                            ref={register({ required: "PLEASE_ENTER_CONTENT" })}
                            style={{ marginTop: "10px" }}
                            defaultValue={content}
                          ></textarea>
                        ) : languageType == ContentTypeString._BONUS ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("PLEASE_ENTER_CONTENT")}
                            name="content"
                            defaultValue={content}
                            ref={register({ required: "PLEASE_ENTER_CONTENT" })}
                          />
                        ) : (
                          <CKEditor
                            editor={ClassicEditor}
                            data={content}
                            name="content"
                            onInit={(editor) => {
                              editor.setData(content);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setContent(data);
                              setValue("content", data);
                              trigger("content");
                            }}
                          />
                        )}
                        {errors.content && (
                          <div className="invalid-feedback">
                            {t(errors.content.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {languageType == ContentTypeString._PROMOTION &&
                  <div className="col-lg-12">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>
                          {t("DESCRIPTION")}
                        </b>
                      </label>
                      <div className="col-md-7">
                        <textarea
                          className="form-control"
                          rows="5"
                          name="description"
                          ref={register({ required: "PLEASE_ENTER_DESCRIPTION" })}
                          style={{ marginTop: "10px" }}
                          defaultValue={description}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                }
                <div className="col-lg-12">
                  {(languageType == ContentTypeString._PROMOTION || languageType == ContentTypeString._BANNER) &&
                    <>{(languageType == ContentTypeString._PROMOTION || (dataDisplayDevice === 0 || dataDisplayDevice === 1 || dataDisplayDevice === 4 || dataDisplayDevice === 6 || dataDisplayDevice === 10)) && <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>
                          {t(/*"DESKTOP_IMAGE"*/"CLASSIC_BANNER")}
                          <p style={{ color: "blue" }}>
                            ({languageType == ContentTypeString._PROMOTION ? t("RECOMMENDED_HEIGHT_WIDTH_RATIO_IS", { value: "308px * 100px" }) : t("RECOMMENDED_HEIGHT_WIDTH_RATIO_IS_1_5")})
                          </p>
                        </b>
                      </label>
                      <div className="col-md-7">
                        <Dropzone
                          accept={"image/*"}
                          onDrop={(acceptedFiles) =>
                            logoDrop(acceptedFiles, true)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div
                                className="dropzone"
                                style={{
                                  minHeight: "200px",
                                  textAlign: "center",
                                }}
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <h4 style={{ color: "grey" }}>
                                  {t(
                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                  )}
                                </h4>
                                {!stringIsNullOrEmpty(desktopImage) && (
                                  <aside className="thumbsContainer">
                                    <div className="thumb">
                                      <div className="thumbInner">
                                        <img
                                          src={desktopImage}
                                          className="dropzone-img"
                                        />
                                      </div>
                                    </div>
                                  </aside>
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        <br />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            setDesktopImage("");
                            setDesktopFile();
                          }}
                        >
                          {t("REMOVE_IMAGE")}
                        </button>
                      </div>
                    </div>}</>}
                </div>
                <div className="col-lg-12">
                  {(languageType == ContentTypeString._PROMOTION || languageType == ContentTypeString._BANNER) &&
                    <>{(languageType == ContentTypeString._PROMOTION || (dataDisplayDevice === 2 || dataDisplayDevice === 3 || dataDisplayDevice === 5 || dataDisplayDevice === 9)) && <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>
                          {t(/*"MOBILE_IMAGE"*/"MODERN_BANNER")}
                          <p style={{ color: "blue" }}>
                            ({languageType == ContentTypeString._PROMOTION ? t("RECOMMENDED_HEIGHT_WIDTH_RATIO_IS", { value: "308px * 100px" }) : t("RECOMMENDED_HEIGHT_WIDTH_RATIO_IS_1_5")})
                          </p>
                        </b>
                      </label>
                      <div className="col-md-7">
                        <Dropzone
                          accept={"image/*"}
                          onDrop={(acceptedFiles) =>
                            logoDrop(acceptedFiles, false)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div
                                className="dropzone"
                                style={{
                                  minHeight: "200px",
                                  textAlign: "center",
                                }}
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <h4 style={{ color: "grey" }}>
                                  {t(
                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                  )}
                                </h4>
                                {!stringIsNullOrEmpty(mobileImage) && (
                                  <aside className="thumbsContainer">
                                    <div className="thumb">
                                      <div className="thumbInner">
                                        <img
                                          src={mobileImage}
                                          className="dropzone-img"
                                        />
                                      </div>
                                    </div>
                                  </aside>
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        <br />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            setMobileImage("");
                            setMobileFile();
                          }}
                        >
                          {t("REMOVE_IMAGE")}
                        </button>
                      </div>
                    </div>}</>}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-white"
                onClick={() => cleanup()}
              >
                {t("CLOSE")}
              </button>
              <button type="submit" className="btn btn-primary">
                {t("SUBMIT")}
              </button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default ConfigurationLanguageDetail;
