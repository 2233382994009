import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Panel, PanelBody } from '../../components/panel/panel';
import Select from "react-select";
import ApiEngine from '../../util/ApiEngine';
import { ApiKey, ApiUrl, LanguageKey } from '../../util/Constant';
import { createFormBody } from '../../util/Util';
import { setSaveAdminLog, showMessage } from '../../redux/AppAction';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

const RetrieveMemberBetData = (props) => {
  const _dispatch = useDispatch();
  let _history = useHistory();
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const ALL_OPTION_VALUE = "all";
  const ALL_OPTION_LABEL = "All";
  const [masterProductList, setMasterProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(ALL_OPTION_VALUE);
  const [usernameString, setUsernameString] = useState("");

  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA + "?isFarmData=true", {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        const accountProductOptionList = [
                            
                        ];
                        //responseJson[ApiKey._API_DATA_KEY].filter(x => x.productName === "918Kiss" || x.productName === "Pussy888").map((option) => {
                        //    accountProductOptionList.push({
                        //        label: option.productName,
                        //        value: option.productId,
                        //        data: option,
                        //    });
                        //});
                        responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
                            accountProductOptionList.push({
                                label: option.productName,
                                value: option.productId,
                            });
                        });
                        setMasterProductList(accountProductOptionList);
                    }
                } else {
                    _dispatch(
                        showMessage(
                            responseJson[ApiKey._API_SUCCESS_KEY],
                            t(responseJson[ApiKey._API_MESSAGE_KEY])
                        )
                    );
                }
            });
    }


  const retrieveBetData = async () => {
    let params = {
      "productId": selectedProduct,
      "usernameList": usernameString
    };
      let responseJson = await ApiEngine.post(ApiUrl._API_RETRIEVE_MEMBER_GAME_DATA, createFormBody(params));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    _dispatch(setSaveAdminLog("EDIT_RETRIEVE_MEMBER_BET_DATA", _history.location.pathname, params));
  }

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <div className="col-lg-8">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("PRODUCT")}</b>
                  </label>
                  <div className="col-4">
                    <div
                      className="form-group"
                      style={{ display: "flex", width: "100%" }}
                    >
                      <div
                        className="input-group"
                        style={{ width: "100%", flexFlow: "nowrap" }}
                      >
                        <div style={{ width: "70%" }}>
                          <Select
                            styles={_OPTION_STYLES}
                            value={masterProductList.filter(
                              (product) => product.value == selectedProduct
                            )}
                            options={masterProductList}
                            onChange={(e) => {
                              setSelectedProduct(e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("MEMBER_USERNAME")}</b>
                  </label>
                  <div className="col-4">
                    <textarea
                      className="form-control"
                      rows="5"
                      style={{ marginTop: "10px" }}
                      onChange={(e) => setUsernameString(e.target.value)}
                    >
                    </textarea>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <button type="button" className="btn btn-primary" onClick={() => retrieveBetData()}>
                      {t("SUBMIT")}
                    </button>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  )
}

export default RetrieveMemberBetData;