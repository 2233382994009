import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { createFormBody, createMultiPartFormBody, navigateTo, numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  WebUrl,
  AccessRight,
  LanguageKey,
  DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import moment from "moment";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import { AsyncPaginate } from "react-select-async-paginate";

/// <summary>
/// Author : -
/// </summary>

const ManageLoyaltyRedemption = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const [redemptionData, setRedemptionData] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedItemName, setSelectedItemName] = useState("");
  const [labelUsername, setLabelUsername] = useState("All");
  const [labelItemName, setLabelItemName] = useState("All");
  const [searchTrigger, setSearchTrigger] = useState("");
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const _SELECTED_MEMBER_KEY = "selectedMemberKey";
  const _SELECTED_ITEM_KEY = "selectedItemKey";

  let _tableColumns = [
    {
      Header: "ITEM_NAME",
      accessor: "name",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>{row.original.name}</div>
      ),
      Footer: () => {
        return <span><b>{t("TOTAL")}: </b></span>
      },
    },
    {
      Header: "USERNAME",
      accessor: "username",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>{row.original.username}</div>
      ),
    },
    {
      Header: "CREDIT",
      accessor: "value",
      Cell: ({ row }) => {
        if (row.original.value !== 0) {
          return <div style={{ textAlign: "right" }}>{numberWithCurrencyFormat(row.original.value)}</div>
        } else {
          return <div style={{ textAlign: "center" }}>-</div>
        }
      },
      Footer: ({ page }) => {
        const totalValue = page.reduce((sum, currentValue) => {
          if (currentValue.original.value) {
            return sum + currentValue.original.value;
          }
          else {
            return sum;
          }
        }, 0);
        return (
          <span>
            <div style={{ textAlign: "right" }} >
              <b>{numberWithCurrencyFormat(totalValue)}</b>
            </div>
          </span>
        );
      },
      disableFilters: true
    },
    {
      Header: "POINTS_SPENDED",
      accessor: "pointsNeededOnRedemption",
      Cell: ({ row }) => (
        <div style={{ textAlign: "right" }}>{numberWithCurrencyFormat(row.original.pointsNeededOnRedemption)}</div>
      ),
      Footer: ({ page }) => {
        const totalPoints = page.reduce((sum, currentValue) => {
          if (currentValue.original.pointsNeededOnRedemption) {
            return sum + currentValue.original.pointsNeededOnRedemption;
          }
          else {
            return sum;
          }
        }, 0);
        return (
          <span>
            <div style={{ textAlign: "right" }} >
              <b>{numberWithCurrencyFormat(totalPoints)}</b>
            </div>
          </span>
        );
      },
      disableFilters: true
    },
    {
      Header: "REDEEM_TIME",
      accessor: "redeemTime",
      Cell: ({ row }) => {
        return <div style={{ textAlign: "center" }}>{moment(row.original.redeemTime).format("YYYY-MM-DD HH:mm:ss")}</div>
      },
      disableFilters: true
    },
    {
      Header: "RECEIVED",
      accessor: "received",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>{row.original.received == 1 ? <i className="icon-green-tick fa fa-check"></i> : ""}</div>
      ),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        (checkIfPermissionExist(
          AccessRight._LOYALTY_REDEMPTION_PERMISSION,
          true
        ) && row.original.received == 0) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu container="body" flip={false} right>
                <DropdownItem
                  onClick={() => { updateReceived(row.original.id); adminLog(row.original); }}
                >
                  <span className="text-warning">{t("MARK_RECEIVED")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  const updateReceived = async (id) => {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_LOYALTY_REDEMPTION + "?id=" + id);
    
    _dispatch(setIdle());
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
    init();
  }

  const init = async () => {
    let apiUrl = ApiUrl._API_GET_LOYALTY_REDEMPTION_RECORD + "?startDate=" + startDate + "&endDate=" + endDate;

    if (selectedMember !== "") {
      apiUrl += "&memberId=" + selectedMember;
    }

    if (selectedItemName !== "") {
      apiUrl += "&itemId=" + selectedItemName;
    }

    let responseJson = await ApiEngine.get(apiUrl);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setRedemptionData(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  const searchBtnHandler = async () => {
    let apiUrl =
      ApiUrl._API_GET_LOYALTY_REDEMPTION_RECORD +
      "?startDate=" +
      startDate +
      "&endDate=" +
      endDate

    if (selectedMember !== "") {
      apiUrl += "&memberId=" + selectedMember;
    }

    if (selectedItemName !== "") {
      apiUrl += "&itemId=" + selectedItemName;
    }

    setSearchTrigger(apiUrl);
    adminLog();
  };

  async function adminLog(object) {
    
    let paramss = {};
    let actionKey = "";
    if (!stringIsNullOrEmpty(object))
    {
      paramss = object;
      actionKey = "EDIT_BO_GAME_POINTS_REDEMPTION";
    }
    else
    {
      paramss = {
        "startDate": startDate,
        "endDate": endDate
      };

      if (selectedMember !== "") {
        paramss["selectedMember"] = selectedMember;
      }

      if (selectedItemName !== "") {
        paramss["itemId"] = selectedItemName;
      }
      actionKey = "VIEW_GAME_POINTS_REDEMPTION"
    }
     

    let pathname = _history.location.pathname;

    _dispatch(setSaveAdminLog(actionKey, pathname, paramss))
  }

  useEffect(async () => {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(searchTrigger);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setRedemptionData(responseJson[ApiKey._API_DATA_KEY]);
    }

    _dispatch(setIdle());
  }, [searchTrigger])

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  async function getItemList(search, loadOptions, { page }) {
    let formattedData = [];
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_LOYALTY_ITEM_NAME_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      formattedData = data.map((m) => {
        return { label: m.itemName, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  return (
    <div>
      <h1 className="page-header">
        {t("GAME_POINTS_REDEMPTION")}
      </h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-4" style={{ display: "flex", alignItems: "end" }}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  width: "100%",
                  flexFlow: "nowrap",
                }}
              >
                <div
                  className="input-group"
                  style={{ width: "100%", flexFlow: "nowrap" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-calendar"></i>
                    </span>
                  </div>
                  <div style={{ width: "80%" }}>
                    <DateRangePicker
                      containerStyles={{ width: "100%" }}
                      startDate={startDate}
                      endDate={endDate}
                      onApply={handleEvent}
                      alwaysShowCalendars={true}
                      locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                      timePicker={true}
                      timePickerSeconds={true}
                      ranges={{
                        Today: [
                          moment().startOf("day"),
                          moment().endOf("day"),
                        ],
                        Yesterday: [
                          moment().subtract(1, "days").startOf("day"),
                          moment().subtract(1, "days").endOf("day"),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "Last 30 Days": [
                          moment().subtract(29, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                        "This Year": [
                          moment().startOf("year"),
                          moment().endOf("year"),
                        ],
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={startDate + " - " + endDate}
                      />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label>
                  <b>{t("USERNAME")}</b>
                </label>
                {<AsyncPaginate
                  placeholder={labelUsername}
                  debounceTimeout={250}
                  loadOptions={getMemberList}
                  additional={{
                    page: 1,
                  }}
                  value={selectedMember}
                  onChange={(e) => {
                    if (!stringIsNullOrEmpty(e.value)) {
                      sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                    } else {
                      sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                    }
                    setLabelUsername(e.label);
                    setSelectedMember(e.value);
                  }}
                />}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label>
                  <b>{t("ITEM_NAME")}</b>
                </label>
                {<AsyncPaginate
                  placeholder={labelItemName}
                  debounceTimeout={250}
                  loadOptions={getItemList}
                  additional={{
                    page: 1,
                  }}
                  value={selectedItemName}
                  onChange={(e) => {
                    if (!stringIsNullOrEmpty(e.value)) {
                      sessionStorage.setItem(_SELECTED_ITEM_KEY, e.value);
                    } else {
                      sessionStorage.removeItem(_SELECTED_ITEM_KEY);
                    }
                    setLabelItemName(e.label);
                    setSelectedItemName(e.value);
                  }}
                />}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  //setSearch(true);
                  searchBtnHandler();
                }}
              >
                <i className="fas fa-search"></i> {t("SEARCH")}
              </button>
            </div>
          </div>
          <hr />
          <ReactTable filterable data={redemptionData} renderFooter={true} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageLoyaltyRedemption;
