import React, { useState, useEffect } from 'react';
import ActiveMemberReportTable from '../../components/constantComponent/reactTable/ActiveMemberReportTable';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { ApiKey, ApiUrl, LanguageKey } from '../../util/Constant';
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';
import { numberWithCurrencyFormat, numberWithCommas } from "../../util/Util";
import { useSelector } from "react-redux";

const ActiveMemberReport = props => {
  const authState = useSelector(state => state.authState);
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [calendarStartDate, setCalendarStartDate] = useState(moment().startOf('month').startOf('day').format("YYYY-MM-DD HH:mm:ss"));
  const [calendarEndDate, setCalendarEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
  const [activeMemberApiUrl, setActiveMemberApiUrl] = useState("");
  const [tempPostData, setTempPostData] = useState({});
  const [monthList, setMonthList] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (e) => {
    setCalendarStartDate(moment(e.target.value).startOf('month').format("YYYY-MM-DD HH:mm:ss"));

    if (moment(e.target.value).year() === moment().year() && moment(e.target.value).month() === moment().month()) {
      setCalendarEndDate(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setCalendarEndDate(moment(e.target.value).endOf('month').format("YYYY-MM-DD HH:mm:ss"));
    }
  }

  const init = () => {
    generateMonth();
    getActiveMember();
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const getActiveMember = async () => {
    localStorage.setItem('lastUpdated', moment.utc());
    let fetchUrl = ApiUrl._API_GET_ACTIVE_MEMBER_REPORT2 + "?StartDate=" + calendarStartDate + "&EndDate=" + calendarEndDate;
    setActiveMemberApiUrl(fetchUrl);
  }

  let _tableColumns = [
    {
      Header: t("DATE"),
      accessor: "date",
      colspan: 1,
      rowspan: 2,
      Cell: ({ row }) => {
        return <>{moment(row.original.date).format('YYYY-MM-DD')}</>
      },
      headerStyle: {
        textAlign: 'left',
        minWidth: '100px'
      },
      cellStyle: {
        textAlign: 'left'
      },
      footerStyle: {
        textAlign: 'left',
        fontWeight: 'bold'
      },
      Footer: () => {
        return <div>{t("TOTAL")}: </div>
      },
    },
    {
      Header: t("MEMBER"),
      colspan: 2,
      rowspan: 1,
      headerStyle: {
        textAlign: 'center'
      },
      subHeaders: [
        {
          Header: t("ACTIVE"),
          Cell: ({ row }) => {
            return <>{row.original.totalActiveMember}</>
          },
          headerStyle: {
            textAlign: 'right'
          },
          cellStyle: {
            textAlign: 'right'
          },
          footerStyle: {
            textAlign: 'right',
            fontWeight: 'bold'
          },
          minWidth: 100,
          Footer: ({ page }) => {
            const totalActiveMembers = page.reduce((sum, currentValue) => {
              if (currentValue.original.totalActiveMember) {
                return sum + currentValue.original.totalActiveMember;
              }
              else {
                return sum;
              }
            }, 0);
            return <div>{totalActiveMembers}</div>
          }
        },
        {
          Header: t("NEW"),
          Cell: ({ row }) => {
            return <>{row.original.totalNewMember}</>
          },
          headerStyle: {
            textAlign: 'right'
          },
          cellStyle: {
            textAlign: 'right'
          },
          footerStyle: {
            textAlign: 'right',
            fontWeight: 'bold'
          },
          minWidth: 100,
          Footer: ({ page }) => {
            const totalNewMembers = page.reduce((sum, currentValue) => {
              if (currentValue.original.totalNewMember) {
                return sum + currentValue.original.totalNewMember;
              }
              else {
                return sum;
              }
            }, 0);
            return <div>{totalNewMembers}</div>
          }
        },
      ]
    },
    {
      Header: t("NEW_DEPOSIT"),
      colspan: 3,
      rowspan: 1,
      headerStyle: {
        textAlign: 'center'
      },
      subHeaders: [
        {
          Header: t("TOTAL"),
          Cell: ({ row }) => {
            return <>{row.original.totalNewDeposit}</>
          },
          headerStyle: {
            textAlign: 'right'
          },
          cellStyle: {
            textAlign: 'right'
          },
          footerStyle: {
            textAlign: 'right',
            fontWeight: 'bold'
          },
          Footer: ({ page }) => {
            const totalNewDeposits = page.reduce((sum, currentValue) => {
              if (currentValue.original.totalNewDeposit) {
                return sum + currentValue.original.totalNewDeposit;
              }
              else {
                return sum;
              }
            }, 0);
            return <div>{totalNewDeposits}</div>
          }
        },
        {
          Header: "%",
          Cell: ({ row }) => {
            if (row.original.totalNewDeposit === 0 || row.original.totalNewMember === 0) {
              return <>-</>
            } else {
              return <>{(row.original.totalNewDeposit / row.original.totalNewMember * 100).toFixed(2) + " %"}</>
            }
          },
          headerStyle: {
            textAlign: 'right',
            maxWidth: '100px'
          },
          cellStyle: {
            textAlign: 'right',
            maxWidth: '50px'
          },
          footerStyle: {
            textAlign: 'right',
            fontWeight: 'bold'
          },
        },
        {
          Header: t("TOTAL_AMOUNT"),
          Cell: ({ row }) => {
            return (
              <div className="display-flex flex-column align-items-flex-end">
                <div>{numberWithCurrencyFormat(parseFloat(row.original.totalNewDepositAmount), 2, true)}</div>
              </div>
            )
          },
          headerStyle: {
            textAlign: 'right'
          },
          cellStyle: {
            textAlign: 'right'
          },
          footerStyle: {
            textAlign: 'right',
            fontWeight: 'bold'
          },
          Footer: ({ page }) => {
            const totalNewDepositAmounts = page.reduce((sum, currentValue) => {
              if (currentValue.original.totalNewDepositAmount) {
                return sum + (parseFloat(currentValue.original.totalNewDepositAmount));
              }
              else {
                return sum;
              }
            }, 0);
            return (
              <div className="display-flex flex-column align-items-flex-end">
                <div>{numberWithCurrencyFormat(parseFloat(totalNewDepositAmounts), 2, true)}</div>
              </div>
            )
          }
        },]
    },
    {
      Header: t("NEW_WITHDRAWAL"),
      colspan: 3,
      rowspan: 1,
      headerStyle: {
        textAlign: 'center'
      },
      subHeaders: [
        {
          Header: t("TOTAL"),
          Cell: ({ row }) => {
            return <>{row.original.totalNewWithdrawal}</>
          },
          headerStyle: {
            textAlign: 'right'
          },
          cellStyle: {
            textAlign: 'right'
          },
          footerStyle: {
            textAlign: 'right',
            fontWeight: 'bold'
          },
          Footer: ({ page }) => {
            const totalNewWithdrawals = page.reduce((sum, currentValue) => {
              if (currentValue.original.totalNewWithdrawal) {
                return sum + currentValue.original.totalNewWithdrawal;
              }
              else {
                return sum;
              }
            }, 0);
            return <div>{totalNewWithdrawals}</div>
          }
        },
        {
          Header: "%",
          Cell: ({ row }) => {
            if (row.original.totalNewWithdrawal === 0 || row.original.totalNewMember === 0) {
              return <>-</>
            } else {
              return <>{(row.original.totalNewWithdrawal / row.original.totalNewMember * 100).toFixed(2) + " %"}</>
            }
          },
          headerStyle: {
            textAlign: 'right',
            maxWidth: '100px'
          },
          cellStyle: {
            textAlign: 'right',
            maxWidth: '50px'
          },
          footerStyle: {
            textAlign: 'right',
            fontWeight: 'bold'
          },
        },
        {
          Header: t("TOTAL_AMOUNT"),
          accessor: "totalNewWithdrawalAmount",
          Cell: ({ row }) => {
            return (
              <div className="display-flex flex-column align-items-flex-end">
                <div>{numberWithCurrencyFormat(parseFloat(row.original.totalNewWithdrawalAmount), 2, true)}</div>
              </div>
            )
          },
          headerStyle: {
            textAlign: 'right',
          },
          cellStyle: {
            textAlign: 'right'
          },
          footerStyle: {
            textAlign: 'right',
            fontWeight: 'bold'
          },
          Footer: ({ page }) => {
            const totalNewWithdrawalAmounts = page.reduce((sum, currentValue) => {
              if (currentValue.original.totalNewWithdrawalAmount) {
                return sum + (parseFloat(currentValue.original.totalNewWithdrawalAmount));
              }
              else {
                return sum;
              }
            }, 0);
            return (
              <div className="display-flex flex-column align-items-flex-end">
                <div>{numberWithCurrencyFormat(parseFloat(totalNewWithdrawalAmounts), 2, true)}</div>
              </div>
            )
          }
        },
      ]
    },
  ]

  const generateMonth = () => {
    var emptyList = []
    const currentDate = moment();
    const startDate = currentDate.date() === 1 ? currentDate.clone().subtract(1, 'months') : currentDate.clone();
    for (var i = 0; i < 12; i++) {
      var year = startDate.format('YYYY');
      var month = startDate.format('MMMM');
      emptyList.push({ "year": year, "month": month });
      startDate.subtract(1, 'months');
    }
    setMonthList(emptyList);
  }

  return (
    <div>
      <h1 className="page-header">{t("ACTIVE_MEMBER_REPORT")}</h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <PanelBody>
              <div className="row">
                <div className="col-lg-10">
                  <div className="form-group" style={{ display: "flex" }}>
                    <div className="input-group" style={{ width: "100%", flexFlow: "nowrap" }}>
                      <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-calendar"></i></span></div>
                      <div className="input-group-append" style={{ width: "100%" }}>
                        <select id="month" name="month" onChange={(e) => handleEvent(e)}>
                          {[...monthList].map(({ year, month }) => {
                            return (
                              <option value={month + ' ' + year}>{month + ' ' + year}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 text-align-right">
                  <button type="button" onClick={() => { setIsSearch(!isSearch); getActiveMember(); }} className="btn btn-primary">{t("SEARCH")}</button>
                </div>
              </div>
              <hr />
              <div className="text-align-right">
                {localStorage.getItem('lastUpdated') != null && (t("LAST_UPDATED") + " : " + moment(localStorage.getItem('lastUpdated')).format("YYYY-MM-DD HH:mm:ss") + " GMT +" + authState.userData.timeZone)}
              </div>
              <ActiveMemberReportTable fetchUrl={activeMemberApiUrl} columns={_tableColumns} renderFooter={true} isSearch={isSearch} />
            </PanelBody>
          </div>
        </div>
      </Panel>
    </div>
  )
}

export default ActiveMemberReport;