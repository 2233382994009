import React from 'react';
import { stringIsNullOrEmpty } from "../../../util/Util";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSaveAdminLog } from '../../../redux/AppAction';

/// <summary>
/// Author : -
/// Used to create the navigation button
/// It will form different button based on the existence of url
/// </summary>
const NavigationButton = (props) => {
  const _dispatch = useDispatch();
  let _history = useHistory();

    if (!stringIsNullOrEmpty(props.url)) {
        return (
            <button type="button"
                className={props.class ? props.class : "btn btn-primary"}
            style={props.style ? props.style : { float: "right" }}
            onClick={() => { props.history.push(props.url, props.state); _dispatch(setSaveAdminLog("TO_BO_" + props.buttonText, _history.location.pathname, props)) }}
            >
                <i className={props.icon ? props.icon : "fa fa-plus-circle m-r-10"}></i>{props.buttonText}
            </button>
        );
    }
    else {
        return (
            <button type="button"
                className={props.class ? props.class : "btn btn-inverse"}
                style={props.style ? props.style : { float: "right" }}
                onClick={() => { props.history.goBack() }}
            >
                <i className={props.icon ? props.icon : "fas fa-long-arrow-alt-left"}></i>{props.buttonText}
            </button>
        );
    }
}

export default NavigationButton