import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
  ContentTypeString,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageConfigurationLanguage from "../configuration/ManageConfigurationLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";
import Dropzone from "react-dropzone";
import ApiEngine from "../../util/ApiEngine.js";

/// <summary>
/// Author : -
/// </summary>
const ProductCategoryDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(true);
  const [categoryId, setCategoryId] = useState("");
  const [isNewIcon, setIsNewIcon] = useState(false);
  const [iconFile, setIconFile] = useState([]);
  const [iconImage, setIconImage] = useState();

  const { register, handleSubmit, errors } = useForm();

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// Used to set banner icon
  /// </summary>
  const iconDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setIconFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
          setIconImage(reader.result);
          setIsNewIcon(true);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      title: data.title,
      status: data.status,
      isNewIconImage: isNewIcon,
    };

    if (!stringIsNullOrEmpty(iconFile)) {
      params["iconImage"] = iconFile;
    }

    if (stringIsNullOrEmpty(iconImage)) {
      params["iconImageRemoved"] = true;
    }

    if (!stringIsNullOrEmpty(categoryId)) {
      params["id"] = categoryId;
      _dispatch(setSaveAdminLog("EDIT_BO_CATEGORY", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_CATEGORY", _history.location.pathname, params));
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_OR_UPDATE_PRODUCT_CATEGORY,
      formBody
    );

    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setIsNewIcon(false);
      setCategoryId(responseJson[ApiKey._API_DATA_KEY]["id"]);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_PRODUCT_CATEGORY_BY_ID;
      apiUrl += "?id=" + _location.state.id;
      fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setCategoryId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
            if (
              !stringIsNullOrEmpty(
                responseJson[ApiKey._API_DATA_KEY]["iconImage"]
              )
            ) {
                setIconImage(responseJson[ApiKey._API_DATA_KEY]["iconImage"]);
              }
          }
        });
    }
  }, []);

  return (
    <div>
      <h1 className="page-header">
        {!stringIsNullOrEmpty(categoryId)
          ? t("EDIT_PRODUCT_CATEGORY")
          : t("ADD_PRODUCT_CATEGORY")}
        <NavigationButton history={_history} />
      </h1>
      {categoryId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _LANGUAGE_TAB })}
              onClick={() => {
                toggle(_LANGUAGE_TAB);
              }}
            >
              {t("LANGUAGE")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CATEGORY_NAME")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_CATEGORY_NAME")}
                              name="title"
                              ref={register({
                                required: "PLEASE_ENTER_CATEGORY_NAME",
                              })}
                              defaultValue={title}
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {t(errors.title.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7 col-form-label">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={register}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("ICON_IMAGE")}</b>
                          </label>
                          <div className="col-md-7 col-form-label">
                            <Dropzone
                            accept={"image/*"}
                            onDrop={(acceptedFiles) => iconDrop(acceptedFiles)}
                            >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  className="dropzone"
                                  style={{
                                    minHeight: "200px",
                                    textAlign: "center",
                                  }}
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <h4 style={{ color: "grey" }}>
                                    {t(
                                      "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                    )}
                                  </h4>
                                  {!stringIsNullOrEmpty(iconImage) && (
                                    <aside className="thumbsContainer">
                                      <div className="thumb">
                                        <div className="thumbInner">
                                          <img
                                            src={iconImage}
                                            className="dropzone-img"
                                          />
                                        </div>
                                      </div>
                                    </aside>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                          <br />
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              setIconImage("");
                              setIconFile();
                            }}
                          >
                            {t("REMOVE_IMAGE")}
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={_LANGUAGE_TAB}>
          {categoryId && (
            <div className="row">
              <div className="col-lg-12">
                <ManageConfigurationLanguage
                  id={categoryId}
                  type={ContentTypeString._PRODUCT_CATEGORY}
                />
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default ProductCategoryDetail;
