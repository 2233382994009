import React, { useEffect, useState } from 'react';
import { Route, withRouter, useHistory } from 'react-router-dom';
import { routes as initRoutes, loginRoute } from './../../config/page-route.jsx';
import { PageSettings } from './../../config/page-settings.js';
import { useSelector, useDispatch } from "react-redux";
import { ApiKey, ApiUrl, AccessRight, Status, LanguageKey } from "../../util/Constant";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next';
import { addLanguage } from "../../redux/LanguageAction.js";
import { fetch } from 'whatwg-fetch';
import { stringIsNullOrEmpty, createFormBody } from '../../util/Util.js';
import { showMessage, setMemberSearch } from "../../redux/AppAction";

/// <summary>
/// Author : -
/// </summary>
const Content = props => {
    const _history = useHistory();
    const _dispatch = useDispatch();
    const authState = useSelector(state => state.authState);
    const [routes, setRoutes] = useState(initRoutes);
    const { t, i18n } = useTranslation(LanguageKey._PRIMARY);

    /// <summary>
    /// Author : -
    /// the fetch at here is used to support even login page also can translate
    /// </summary>
    useEffect(() => {
        (async function () {
            if (!_history.location.pathname.match(/login/ig)) {
                await fetch(ApiUrl._API_GET_PREFERENCE_LANGUAGE, {
                    method: ApiKey._API_GET,
                    headers: {
                        "Accept": ApiKey._API_APPLICATION_JSON,
                        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
                    }
                }).then((response) => response.json()).then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        responseJson[ApiKey._API_DATA_KEY].map((language, index) => {
                            try {
                                i18n.addResources(language.code, LanguageKey._PRIMARY, require("../../translations/" + language.code + "/common.json"));
                            }
                            catch (ex) {
                                return;
                            }
                        });
    
                        ///below is used to add the data to reducer to be use at language selection to avoid fetch second time at there
                        _dispatch(addLanguage(responseJson[ApiKey._API_DATA_KEY]));
                    }
                });
            }
            
        })();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    const setTitle = (path, routeArray) => {
        var pageTitle;
        for (var i = 0; i < routeArray.length; i++) {
            if (routeArray[i].path === path) {

                pageTitle = "RW Admin | " + t(routeArray[i].title);
            }
        }
        document.title = (pageTitle) ? pageTitle : 'M93';
    }

    /// <summary>
    /// Author : -
    /// used to detect the first time language resource is added
    /// </summary>
    i18next.store.on('added', function (lng, ns) {
        setTitle(_history.location.pathname, routes);
    })

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        setTitle(_history.location.pathname, routes);
    }, [i18n.language]);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        _history.listen(() => {
            setTitle(_history.location.pathname, routes);
        })

        if (_history.location.pathname != "/login") {
          let params = {
            actionKey:"BO_ADMIN_ACTION",
            pathname: _history.location.pathname,
          };

          if (props.location.state != undefined) {
            params["body"] = JSON.stringify(props.location.state);
            params["id"] = props.location.state.id;
          }

          let formBody = createFormBody(params);

          fetch(ApiUrl._API_CREATE_ADMIN_OPERATION_LOG, {
            method: ApiKey._API_POST,
            headers: {
              "Content-Type": ApiKey._API_FORM_URLENCODED,
              "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              }
            });
        }
    }, [_history.location.pathname])

    /// <summary>
    /// Author : -
    /// </summary>
    const PrivateRoute = (index, routeData) => {
        return (
            <Route
                key={index}
                exact={routeData.authRequired && !authState.isLoggedIn ? loginRoute.exact : routeData.exact}
                path={routeData.authRequired && !authState.isLoggedIn ? loginRoute.path : routeData.path}
                component={routeData.authRequired && !authState.isLoggedIn ? loginRoute.component : routeData.component}
            />
        );
    }

    /// <summary>
    /// Author : -
    /// Editted: -
    /// </summary>
    const filterMenuByPermission = () => {
        var filteredRoutes = routes.filter(route => {
            return ((route.loginType == undefined ||
                route.loginType.filter(i => i == authState.userData.loginType).length != 0) ||
                (authState.isLoggedIn == false && route.path == _history.location.pathname)
            );
        });

        if (authState.userData.accessString !== undefined) {
            var userPermissionData = authState.userData.accessString.split(";");
            var permissionArrKey = [];

            for (var i = 0; i < userPermissionData.length; i++) {
                var keyValuePermission = userPermissionData[i].split(":");
                if (keyValuePermission.length == 2) {
                    if (keyValuePermission[1][AccessRight._READ_BIT_POSITION] == Status._ENABLED.toString()) {
                        permissionArrKey.push(keyValuePermission[0] + AccessRight._READ_PERMISSION_STRING);
                    }
                    if (keyValuePermission[1][AccessRight._WRITE_BIT_POSITION] == Status._ENABLED.toString()) {
                        permissionArrKey.push(keyValuePermission[0] + AccessRight._WRITE_PERMISSION_STRING);
                    }
                }
            }

            let finalRoutes = filteredRoutes.filter(item => {
                let permission = item.permission;
                //let match = '';
                //if (permission !== undefined){
                //    let temp = permission.substring(0, permission.indexOf(AccessRight._READ_PERMISSION_STRING));
                //    let temp1 = temp.split(' ');
                //    temp1.map((word) => {
                //        match += word.charAt(0);
                //    });
                //}
                //if (!stringIsNullOrEmpty(match)) {
                //    match += AccessRight._READ_PERMISSION_STRING;
                //    return permissionArrKey.filter(item => item == match).length != 0;
                //}
                if (permission !== undefined) {
                    return permissionArrKey.filter(item => item == permission).length != 0;
                }
                else {
                    return true;
                }
            });

            return finalRoutes;
        }
        else {
            return filteredRoutes;
        }
    }

    let finalRoutes = filterMenuByPermission();
    return (
        <PageSettings.Consumer>
            {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
                <div className={'content ' + (authState.isLoggedIn && 'logged-in ') + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass}>
                    {finalRoutes.map((route, index) => (
                        PrivateRoute(index, route)
                    ))}
                </div>
            )
            }
        </PageSettings.Consumer>
    );
}

export default withRouter(Content);
