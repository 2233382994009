import React, { useState, useEffect } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, AccessRight, WebUrl, LanguageKey } from "../../util/Constant";
import { createFormBody } from "../../util/Util";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import ApiEngine from "../../util/ApiEngine";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>
const Product = props => {
  const _ACTIVE_ALL = 1;
  const _SUSPEND_ALL = 2;
  let _history = useHistory();
  const { register, handleSubmit, errors, watch, trigger, setValue, getValues } = useForm({ mode: "onChange" });
  const [products, setProducts] = useState([]);
  const _dispatch = useDispatch();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [action, setAction] = useState('');
  const [isNotCSRole, setIsNotCSRole] = useState(props.role == "CS" ? false : true);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, [])

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_UPLINE_PRODUCT_RATES + "?memberId=" + props.id);
    let uplineProductRates = [];
    let processedProducts = [];
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      uplineProductRates = responseJson[ApiKey._API_DATA_KEY];
    }

    let productResponseJson = await ApiEngine.get(ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT + "?memberId=" + props.id + "&ignoreAccountProductStatus=true");
    if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
      productResponseJson[ApiKey._API_DATA_KEY].map((product) => {
        let uplineProduct = uplineProductRates.filter(i => i.accountProductId == product.accountProductId)[0];
        processedProducts.push({
          id: product.id,
          productId: product.accountProductModel.productId,
          accountProductId: product.accountProductId,
          productName: product.productName,
          assigned: product.assigned,
          bonus: product.bonus,
          commission: product.commission,
          clubFee: product.clubFee,
          maxBonus: (uplineProduct != null) ? uplineProduct.bonus : 0,
          maxCommission: (uplineProduct != null) ? uplineProduct.commission : 0,
          maxClubFee: (uplineProduct != null) ? uplineProduct.clubFee : 0,
          useGroupRate: product.accountProductModel.product.useGroupRate,
          status: product.status
        });
      })
    }

    setProducts(processedProducts);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (products.length > 0) {
      products.map((product) => {
        setValue("bonus-" + product.id, product.bonus);
        setValue("commission-" + product.id, product.commission);
        setValue("clubFee-" + product.id, product.clubFee);
        setValue("wallet-" + product.id, product.id);

      })
    }
  }, [products])

  /// <summary>
  /// Author : -
  /// </summary>
  const updateProductActiveness = async (status, accountProductId) => {
    let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_MEMBER_PRODUCT_ACTIVENESS + "?memberId=" + props.id + "&accountProductId=" + accountProductId + "&enable=" + status + "&assignment=true");
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    else {
      _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    let params = {
      "memberId": props.id,
      "accountProductId": accountProductId,
      "enable": status == true ? "Active Product" : "Suspend Product",
      "assignment":"true"
    };

    adminLog("EDIT_PRODUCT_ACTIVENESS", params);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const actionProcessing = async (confirm) => {
    setShowConfirmation(false);

    if (!confirm) {
      return;
    }

    let apiUrl = ApiUrl._API_UPDATE_ALL_MEMBER_PRODUCT_ACTIVENESS + "?memberId=" + props.id;
    apiUrl += "&enable=" + (action == _ACTIVE_ALL);

    let responseJson = await ApiEngine.post(apiUrl);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }

    let actiontext = "";
    let params = {
      "memberId": props.id
    };
    switch (action) {
      case _ACTIVE_ALL:
        actiontext = "ACTIVE_ALL";
        break;
      case _SUSPEND_ALL:
        actiontext = "SUSPEND_ALL";
        break;
    }
    params["action"] = actiontext;
    adminLog("EDIT_ActionProcessing", params);

    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const onSubmit = async (product) => {
    var result;
    if (product.productName === "D2 Poker") {
      result = await trigger(['bonus-' + product.id, 'commission-' + product.id, 'clubFee-' + product.id]);
    }
    else {
      result = await trigger(['bonus-' + product.id, 'commission-' + product.id]);
    }
    if (!result) {
      return;
    }

    let params = {
      id: product.id, //memberProductId
      productId: product.productId, //masterProductId
      accountProductId: product.accountProductId
    }

    params['bonus-' + product.productId] = getValues("bonus-" + product.id);
    params['commission-' + product.productId] = getValues("commission-" + product.id);
    if (product.productName === "D2 Poker") { params['clubFee-' + product.productId] = getValues("clubFee-" + product.id); }
    adminLog("EDIT_MEMBER_PRODUCT_RATE",params);
    let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_MEMBER_PRODUCT_RATE, createFormBody(params));

    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
  }

  async function adminLog(actionKey,data) {

    _dispatch(setSaveAdminLog(actionKey, _history.location.pathname, data));
  }

  return (
    <div>
      {
        showConfirmation && <SweetAlert
          warning
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="danger"
          title={t("ARE_YOU_SURE")}
          onConfirm={() => { actionProcessing(true) }}
          onCancel={() => { actionProcessing(false) }}>
        </SweetAlert>
      }
      {isNotCSRole &&
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <button type="button" style={{ margin: "5px" }} onClick={() => { setShowConfirmation(true); setAction(_ACTIVE_ALL); }} className="btn btn-success">{t("ACTIVE_ALL")}</button>
                <button type="button" style={{ margin: "5px" }} onClick={() => { setShowConfirmation(true); setAction(_SUSPEND_ALL); }} className="btn btn-danger">{t("SUSPEND_ALL")}</button>
              </div>
            </div>
          </div>
        </div>
      }
      <hr />
      <form>
        <div className="row">
          {products.map((product, index) => {
            return <div className="col-lg-3" key={index}>
              <div className="card m-b-5">
                <div className="card-header" style={{ whiteSpace: "normal", wordBreak: "break-word", textAlign: "center" }}>
                  <b>{product.productName}{product.useGroupRate && " *"}</b>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row m-5">
                      <div className="col-lg-6" style={{ display: "flex", alignItems: "center" }}><b>{t("BONUS")} (%)</b></div>

                      <div className="col-lg-6">
                        <input type="number"
                          className="form-control m-b-5"
                          disabled={isNotCSRole ? false : true}
                          ref={register({
                            required: "PLEASE_ENTER_BONUS",
                            min: {
                              value: 0,
                              message: 'PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO'
                            },
                            max: {
                              value: 100,
                              message: 'PLEASE_ENTER_VALUE_NOT_MORE_THAN_100'
                            },
                            max: {
                              value: product.maxBonus,
                              message: 'PLEASE_ENTER_VALUE_NOT_MORE_THAN'
                            }
                          })}
                          onChange={(e) => {
                            setValue("bonus-" + product.id, e.target.value)
                          }}
                          name={"bonus-" + product.id} />
                        {errors["bonus-" + product.id] && <div className="invalid-feedback">{t(errors["bonus-" + product.id].message, { value: product.maxBonus })}</div>}
                      </div>
                    </div>
                    <div className="row m-5">
                      <div className="col-lg-6" style={{ display: "flex", alignItems: "center" }}><b>{t("COMMISSION")} (%)</b></div>
                      <div className="col-lg-6">
                        <input type="number"
                          className="form-control m-b-5"
                          disabled={isNotCSRole ? false : true}
                          ref={register({
                            required: "PLEASE_ENTER_COMMISSION",
                            min: {
                              value: 0,
                              message: 'PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO'
                            },
                            max: {
                              value: 100,
                              message: 'PLEASE_ENTER_VALUE_NOT_MORE_THAN_100'
                            },
                            max: {
                              value: product.maxCommission,
                              message: 'PLEASE_ENTER_VALUE_NOT_MORE_THAN'
                            }
                          })}
                          onChange={(e) => {
                            setValue("commission-" + product.id, e.target.value)
                          }}
                          name={"commission-" + product.id} />
                        {errors["commission-" + product.id] && <div className="invalid-feedback">{t(errors["commission-" + product.id].message, { value: product.maxCommission })}</div>}
                      </div>
                    </div>
                    {product.productName === "D2 Poker" && <div className="row m-5">
                      <div className="col-lg-6" style={{ display: "flex", alignItems: "center" }}><b>{t("CLUB_FEE")} (%)</b></div>
                      <div className="col-lg-6">
                        <input type="number"
                          className="form-control m-b-5"
                          ref={register({
                            required: "PLEASE_ENTER_CLUBFEE",
                            min: {
                              value: 0,
                              message: 'PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO'
                            },
                            max: {
                              value: 100,
                              message: 'PLEASE_ENTER_VALUE_NOT_MORE_THAN_100'
                            },
                            max: {
                              value: product.maxClubFee,
                              message: 'PLEASE_ENTER_VALUE_NOT_MORE_THAN'
                            }
                          })}
                          onChange={(e) => {
                            setValue("clubFee-" + product.id, e.target.value)
                          }}
                          name={"clubFee-" + product.id} />
                        {errors["clubFee-" + product.id] && <div className="invalid-feedback">{t(errors["clubFee-" + product.id].message, { value: product.maxClubFee })}</div>}
                      </div>
                    </div>}
                  </div>
                </div>
                {isNotCSRole && <div className="row">
                  <div className="col-lg-12 m-5">
                    <button type="button" className="btn btn-warning m-5" onClick={() => {
                      onSubmit(product);
                    }}>{t("UPDATE")}</button>
                    {
                      !product.status ?
                        <button type="button" className="btn btn-primary m-5" onClick={() => { updateProductActiveness(true, product.accountProductId) }}>{t("ACTIVATE_PRODUCT")}</button>
                        : <button type="button" className="btn btn-danger m-5" onClick={() => { updateProductActiveness(false, product.accountProductId) }}>{t("SUSPEND_PRODUCT")}</button>
                    }
                  </div>
                </div>
                }
              </div>
            </div>
          })}
        </div>
      </form>
    </div>
  )
}

export default Product;