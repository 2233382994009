import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { createFormBody, navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  WebUrl,
  AccessRight,
  LanguageKey,
  AllVipOption,
} from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { useDispatch } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import { showMessage } from "../../redux/AppAction";

/// <summary>
/// Author : -
/// </summary>

const ManageGamePointContent = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [gamePointContentData, setGamePointContentData] = useState([]);
  let _history = useHistory();

  let _tableColumns = [
  
    {
      Header: "PLATFORM",
      accessor: "platform",
      disableSortBy: true,
    },
    {
      Header: "CONTENT",
      accessor: "content",
      disableSortBy: true,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_MANAGE_GAME_POINT_CONTENT_DETAIL,
                    row.original
                  );
                }}
              >
                <span className="text-warning">{t("EDIT")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];
  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getGamePointContent();
  }, []);

  async function getGamePointContent() {
    fetch(ApiUrl._API_GET_GAME_POINT_CONTENT, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setGamePointContentData(responseJson[ApiKey._API_DATA_KEY]);


        }
      });

  }

  return (
    <div>
      <h1 className="page-header">
        {t("MANAGE_GAME_POINT_CONTENT")}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable data={gamePointContentData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageGamePointContent;
