import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  DisplayMode,
  LanguageKey,
  ContentTypeString,
  AllVipOption,
} from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageGamePointLanguage from "./ManageGamePointContentLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine.js";
import ManageGamePointContent from "./ManageGamePointContent.js";


/// <summary>
/// Author : -
/// </summary>

const ManageGamePointContentDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors, setValue } = useForm();
  const [gamePointContentId, setGamePointContentId] = useState("");
  const [announcementRefId, setAnnouncementRefId] = useState("");
  const [platformMode, setPlatformMode] = useState("");

  const [content, setContent] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "content" }, { required: "PLEASE_ENTER_CONTENT" });
    register({ name: "vip" }, { required: "PLEASE_SELECT_VIP_LEVEL" });
  }, []);

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getGamePointContent();
  }, []);
  async function getGamePointContent() {
    let stateAnnouncementId = (_location.state.id || _location.state.refId) - 1;
  
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_GAME_POINT_CONTENT_BY_ID + "?id="+_location.state.id
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      var data = responseJson[ApiKey._API_DATA_KEY];
      
      setGamePointContentId(data["id"]);
      setPlatformMode(data["platform"]);
      setContent(data["content"]);
    }
  }

  async function submit() {
    let params = {
      displayModeId: platformMode,
      content: content,
      id : _location.state.id
    };

      let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_UPDATE_GAME_POINT_CONTENT, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

      });
    getGamePointContent();
  }

  return (
    <div>
      <h1 className="page-header">
        {t("EDIT_GAME_POINT_CONTENT")}
        <NavigationButton history={_history} />
      </h1>
      {gamePointContentId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _LANGUAGE_TAB })}
              onClick={() => {
                toggle(_LANGUAGE_TAB);
              }}
            >
              {t("LANGUAGE")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submit)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("PLATFORM")}</b>
                          </label>
                          <div className="col-md-7">
                            <label className="col-md-4 col-form-label">
                              {platformMode}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CONTENT")}</b>
                          </label>
                          <div className="col-md-7">
                            <textarea
                              className="form-control"
                              rows="10"
                              style={{ marginTop: "10px" }}
                              ref={register}
                              name="content"
                              defaultValue={content}
                              onChange={(e) => {
                                setContent(e.target.value);
                                setValue("content", e.target.value);
                              }}
                            ></textarea>
                            {errors.content && (
                              <div className="invalid-feedback">
                                {t(errors.content.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>


                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button
                            type="button"
                            onClick={() => {
                              submit();
                            }}
                            className="btn btn-purple"
                          >
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={_LANGUAGE_TAB}>
          {gamePointContentId && (
            <div className="row">
              <div className="col-lg-12">
                <ManageGamePointLanguage
                  id={gamePointContentId}
                  type={ContentTypeString._GAME_POINT_CONTENT}

                />
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default ManageGamePointContentDetail;
