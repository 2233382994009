import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { createFormBody, navigateTo, stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  ContentTypeString,
  LanguageKey,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import ConfigurationLanguageDetail from "../configuration/ConfigurationLanguageDetail";
import Dropzone from "react-dropzone";
import { showMessage, setBusy, setIdle, setSaveAdminLog } from "../../redux/AppAction";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>
const LoyaltyItemDetailLanguage = (props) => {
  let _history = useHistory();
  const { handleSubmit, errors, register, content, setValue, clearErrors } = useForm();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [data, setData] = useState([]);
  const _dispatch = useDispatch();
  const [
    languageDetailModalVisibility,
    setLanguageDetailModalVisibility,
  ] = useState(false);

  const _EXCLUDE_TITLE_TYPE = [
    ContentTypeString._VIP_BENEFIT_CONTENT,
    ContentTypeString._VIP_CRITERIA,
  ];
  const _EXCLUDE_CONTENT_TYPE = [
    ContentTypeString._VIP_BENEFIT,
    ContentTypeString._PRODUCT_CATEGORY,
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [languageDetailId, setLanguageDetailId] = useState("");
  const [tableColumns, setTableColumns] = useState([]);
  const [text, setText] = useState("");
  const [languageOption, setLanguageOption] = useState([]);
  const [languageId, setLanguageId] = useState("");
  const [loyaltyItemData, setLoyaltyItemData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editDataId, setEditDataId] = useState("");
  const [editLanguageId, setEditLanguageId] = useState("");
  const [loyaltyLanguageId, setLoyaltyLanguageId] = useState("");
  const [editName, setEditName] = useState("");
  const [mobileContent, setMobileContent] = useState("");
  const [desktopContent, setDesktopContent] = useState("");
  const [editMobileContent, setEditMobileContent] = useState("");
  const [editDesktopContent, setEditDesktopContent] = useState("");
  
  const [image, setImage] = useState();
  const [imageFile, setImageFile] = useState([]);
  const [imageDesktop, setImageDesktop] = useState();
  const [imageDesktopFile, setImageDesktopFile] = useState([]);
  const [editImage, setEditImage] = useState();
  const [editImageFile, setEditImageFile] = useState([]);
  const [editDesktopImage, setEditDesktopImage] = useState();
  const [editDesktopImageFile, setEditDesktopImageFile] = useState([]);

  const [name, setName] = useState("");
  let _tableColumns = [
    {
      Header: "LANGUAGE",
      accessor: "languageName",
      disableSortBy: true,
    },
    {
      Header: "NAME",
      accessor: "name",
      disableSortBy: true,
    },
    {
      Header: "IMAGE",
      accessor: "image",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div strle={{ textAlign: "center" }}>
          {!stringIsNullOrEmpty(row.original.image) ? (
            <img
              style={{ width: "150px", height: "200px" }}
              src={row.original.image}
            />
          ) : (
            <span> - </span>
          )}
        </div>
      ),
    },
    {
      Header: "DESKTOP_IMAGE",
      accessor: "desktopImage",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div strle={{ textAlign: "center" }}>
          {!stringIsNullOrEmpty(row.original.desktopImage) ? (
            <img
              style={{ width: "150px", height: "200px" }}
              src={row.original.desktopImage}
            />
          ) : (
            <span> - </span>
          )}
        </div>
      ),
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  setEditModal(true);
                  setLoyaltyLanguageId(row.original.id);
                  setEditDataId(row.original.dataId);
                  setEditLanguageId(row.original.languageId);
                  setEditName(row.original.name);
                  setEditImage(row.original.image);
                  setEditDesktopImage(row.original.desktopImage);
                  setEditMobileContent(row.original.mobileContent);
                  setEditDesktopContent(row.original.desktopContent);
                }}
              >
                <span className="text-warning">{t("EDIT")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];
  const onSubmit = async (data) => {

    let params = {
      dataId: props.id,
      languageId: languageId,
      name: name,
      mobileContent: mobileContent,
      desktopContent: desktopContent
    };

    if (!stringIsNullOrEmpty(imageFile)) {
      params["image"] = imageFile;
    }

    if (!stringIsNullOrEmpty(imageDesktopFile)) {
      params["imageDesktop"] = imageDesktopFile;
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_LOYALTY_ITEM_LANGUAGE,
      formBody
    );

    _dispatch(setSaveAdminLog("CREATE_" + props.type, _history.location.pathname,params));
    _dispatch(setIdle());
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
    setIsOpen(false);
    getLoyaltyItemLanguage();
  };
  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getConfigLanguage();
    getLanguage();
    getLoyaltyItemLanguage();
    /// Used to Exclude the column of table
    if (_EXCLUDE_CONTENT_TYPE.includes(props.type)) {
      _tableColumns.splice(1, 1);
    } else if (_EXCLUDE_TITLE_TYPE.includes(props.type)) {
      _tableColumns.splice(2, 1);
    }
    setTableColumns(_tableColumns);
  }, []);

  async function getLanguage() {
    await fetch(ApiUrl._API_GET_LANGUAGE, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const languageList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (language) {
            languageList.push({ label: language.name, value: language.id });
          });
          setLanguageOption(languageList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const getConfigLanguage = async () => {
    await fetch(
      ApiUrl._API_GET_CONFIG_LANGUAGE_BY_TYPE_ID +
      "?type=" +
      props.type +
      "&id=" +
      props.id,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  };

  async function getLoyaltyItemLanguage() {

    var apiUrl = ApiUrl._API_GET_LOYALTY_ITEM_LANGUAGE;
    apiUrl += "?id=" + props.id
    fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setLoyaltyItemData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });

  }

  const editSubmit = async (data) => {

    let params = {
      loyaltyItemLanguageId: loyaltyLanguageId,
      dataId: editDataId,
      languageId: editLanguageId,
      name: editName,
      editMobileContent: editMobileContent,
      editDesktopContent: editDesktopContent
    };

    if (!stringIsNullOrEmpty(editImageFile)) {
      params["image"] = editImageFile;
    }

    if (!stringIsNullOrEmpty(editDesktopImageFile)) {
      params["imageDesktop"] = editDesktopImageFile;
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_LOYALTY_ITEM_LANGUAGE_BY_ID,
      formBody
    );
    _dispatch(setSaveAdminLog("EDIT_" + props.type, _history.location.pathname, params));
    _dispatch(setIdle());
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );

    setEditModal(false);
    getLoyaltyItemLanguage();
  };

  const imageDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const imageDesktopDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setImageDesktopFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageDesktop(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const editImageDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setEditImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const editImageDesktopDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setEditDesktopImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditDesktopImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div className="row">
        <ConfigurationLanguageDetail
          visible={languageDetailModalVisibility}
          onFinish={() => {
            setLanguageDetailModalVisibility(false);
            getConfigLanguage();
          }}
          dataTypeId={props.id}
          languageDetailId={languageDetailId}
          languageType={props.type}
        />

      </div>
      <Panel>
        <PanelBody>
          <button
            type="button"
            onClick={() => {
              setLanguageDetailId("");
              setIsOpen(true);
            }}
            className="btn btn-purple"
          >
            {t("ADD_LANGUAGE")}
          </button>
          <ReactTable data={loyaltyItemData} columns={_tableColumns} minRows={1} />
        </PanelBody>
      </Panel>
      <Modal size="xl" fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={isOpen} centered>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            {t("ADD_LANGUAGE")}
          </ModalHeader>
          <ModalBody style={{ minHeight: "30vh" }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("LANGUAGE")}</b>
                  </label>
                  <div className="col-md-7">
                    <Select
                      name="languageId"
                      options={languageOption}
                      placeholder={
                        languageOption.filter(
                          (option) => option.value == languageId
                        )[0] !== undefined
                          ? languageOption.filter(
                            (option) =>
                              option.value == languageId
                          )[0].label
                          : ""
                      }
                      value={languageOption.filter(
                        (option) => option.value == languageId
                      )}
                      onChange={(e) => {
                        setLanguageId(e.value);
                        setValue("languageId", e.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("NAME")}</b>
                  </label>
                  <div className="col-md-7">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("PLEASE_ENTER_NAME")}
                      name="name"
                      ref={register({
                        required: "PLEASE_ENTER_NAME",
                      })}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />

                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("MOBILE_CONTENT")}</b>
                  </label>
                  <div className="col-md-7">
                    <textarea
                      className="form-control"
                      rows="10"
                      name="mobileContent"
                      ref={register({ required: "PLEASE_ENTER_MOBILE_CONTENT" })}
                      style={{ marginTop: "10px" }}
                      defaultValue={mobileContent}
                      onChange={(e) => setMobileContent(e.target.value)}
                    ></textarea>
                    {errors.mobileContent && (
                      <div className="invalid-feedback">
                        {t(errors.mobileContent.message)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("DESKTOP_CONTENT")}</b>
                  </label>
                  <div className="col-md-7">
                    <textarea
                      className="form-control"
                      rows="10"
                      name="desktopContent"
                      ref={register({ required: "PLEASE_ENTER_DESKTOP_CONTENT" })}
                      style={{ marginTop: "10px" }}
                      defaultValue={desktopContent}
                      onChange={(e) => setDesktopContent(e.target.value)}
                    ></textarea>
                    {errors.desktopContent && (
                      <div className="invalid-feedback">
                        {t(errors.desktopContent.message)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("IMAGE")}</b>
                  </label>
                  <div className="col-md-7">
                    <Dropzone
                      accept={"image/*"}
                      onDrop={(acceptedFiles) => imageDrop(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            className="dropzone"
                            style={{
                              minHeight: "200px",
                              textAlign: "center",
                            }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <h4 style={{ color: "grey" }}>
                              {t(
                                "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                              )}
                            </h4>
                            {!stringIsNullOrEmpty(image) && (
                              <aside className="thumbsContainer">
                                <div className="thumb">
                                  <div className="thumbInner">
                                    <img
                                      src={image}
                                      className="dropzone-img"
                                    />
                                  </div>
                                </div>
                              </aside>
                            )}
                          </div>
                        </section>
                      )}
                      </Dropzone>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("DESKTOP_IMAGE")}</b>
                  </label>
                  <div className="col-md-7">
                    <Dropzone
                      accept={"image/*"}
                      onDrop={(acceptedFiles) => imageDesktopDrop(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            className="dropzone"
                            style={{
                              minHeight: "200px",
                              textAlign: "center",
                            }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <h4 style={{ color: "grey" }}>
                              {t(
                                "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                              )}
                            </h4>
                            {!stringIsNullOrEmpty(imageDesktop) && (
                              <aside className="thumbsContainer">
                                <div className="thumb">
                                  <div className="thumbInner">
                                    <img
                                      src={imageDesktop}
                                      className="dropzone-img"
                                    />
                                  </div>
                                </div>
                              </aside>
                            )}
                          </div>
                        </section>
                      )}
                      </Dropzone>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-white"
              onClick={() => setIsOpen(false)}
            >
              {t("CLOSE")}
            </button>
            <button type="submit" className="btn btn-primary">
              {t("SUBMIT")}
            </button>
          </ModalFooter>
        </form>
      </Modal>
      <Modal size="xl" fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={editModal} centered>

        <form onSubmit={handleSubmit(editSubmit)}>
          <ModalHeader>
            {t("EDIT_LANGUAGE")}
          </ModalHeader>
          <ModalBody style={{ minHeight: "30vh" }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("LANGUAGE")}</b>
                  </label>
                  <div className="col-md-7">
                    <Select
                      name="editLanguageId"
                      options={languageOption}
                      placeholder={
                        languageOption.filter(
                          (option) => option.value == editLanguageId
                        )[0] !== undefined
                          ? languageOption.filter(
                            (option) =>
                              option.value == editLanguageId
                          )[0].label
                          : ""
                      }
                      defaultValue={languageOption.filter(
                        (option) => option.value == editLanguageId
                      )}
                      value={languageOption.filter(
                        (option) => option.value == editLanguageId
                      )}
                      onChange={(e) => {
                        setEditLanguageId(e.value);
                        setValue("editLanguageId", e.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("NAME")}</b>
                  </label>
                  <div className="col-md-7">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={editName}
                      name="editName"
                      ref={register({
                        required: "PLEASE_ENTER_NAME",
                      })}
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                    />

                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("MOBILE_CONTENT")}</b>
                  </label>
                  <div className="col-md-7">
                    <textarea
                      className="form-control"
                      rows="10"
                      name="editMobileContent"
                      ref={register({ required: "PLEASE_ENTER_MOBILE_CONTENT" })}
                      style={{ marginTop: "10px" }}
                      defaultValue={editMobileContent}
                      onChange={(e) => setEditMobileContent(e.target.value)}
                    ></textarea>
                    {errors.editMobileContent && (
                      <div className="invalid-feedback">
                        {t(errors.editMobileContent.message)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("DESKTOP_CONTENT")}</b>
                  </label>
                  <div className="col-md-7">
                    <textarea
                      className="form-control"
                      rows="10"
                      name="editDesktopContent"
                      ref={register({ required: "PLEASE_ENTER_DESKTOP_CONTENT" })}
                      style={{ marginTop: "10px" }}
                      defaultValue={editDesktopContent}
                      onChange={(e) => setEditDesktopContent(e.target.value)}
                    ></textarea>
                    {errors.editDesktopContent && (
                      <div className="invalid-feedback">
                        {t(errors.editDesktopContent.message)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("IMAGE")}</b>
                  </label>
                  <div className="col-md-7">
                    <Dropzone
                      accept={"image/*"}
                      onDrop={(acceptedFiles) => editImageDrop(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            className="dropzone"
                            style={{
                              minHeight: "200px",
                              textAlign: "center",
                            }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <h4 style={{ color: "grey" }}>
                              {t(
                                "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                              )}
                            </h4>
                            {!stringIsNullOrEmpty(editImage) && (
                              <aside className="thumbsContainer">
                                <div className="thumb">
                                  <div className="thumbInner">
                                    <img
                                      src={editImage}
                                      className="dropzone-img"
                                    />
                                  </div>
                                </div>
                              </aside>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("DESKTOP_IMAGE")}</b>
                  </label>
                  <div className="col-md-7">
                    <Dropzone
                      accept={"image/*"}
                      onDrop={(acceptedFiles) => editImageDesktopDrop(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            className="dropzone"
                            style={{
                              minHeight: "200px",
                              textAlign: "center",
                            }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <h4 style={{ color: "grey" }}>
                              {t(
                                "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                              )}
                            </h4>
                            {!stringIsNullOrEmpty(editDesktopImage) && (
                              <aside className="thumbsContainer">
                                <div className="thumb">
                                  <div className="thumbInner">
                                    <img
                                      src={editDesktopImage}
                                      className="dropzone-img"
                                    />
                                  </div>
                                </div>
                              </aside>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-white"
              onClick={() => setEditModal(false)}
            >
              {t("CLOSE")}
            </button>
            <button type="submit" className="btn btn-primary">
              {t("SUBMIT")}
            </button>
          </ModalFooter>
        </form>
      </Modal>

    </div>

  );
};

export default LoyaltyItemDetailLanguage;
