import React, { useState } from "react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
  _TRANSACTION_STATUS,
  _WALLET_GAME_TRANSACTION_TYPE,
  Transaction,
} from "../../util/Constant";
import {
  createFormBody,
  stringIsNullOrEmpty,
  numberWithCurrencyFormat,
} from "../../util/Util";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import SweetAlert from "react-bootstrap-sweetalert";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine.js";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const CommissionTracking = (props) => {
  const { register, handleSubmit, errors, setValue, trigger } = useForm();
  const _dispatch = useDispatch();
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [memberId, setMemberId] = useState("");
  const [nonSeamlessBalance, setNonSeamlessBalance] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const ALL_OPTION_LABEL = "All";
  const ALL_OPTION_VALUE = "all";
  const [selectedProduct, setSelectedProduct] = useState(ALL_OPTION_VALUE);
  const [productList, setProductList] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [totalCommission, setTotalCommission] = useState(0);
  const [totalSales, setTotalSales] = useState(0);

  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  let _tableColumns = [
    {
      Header: "SETTLEMENT_ID",
      accessor: "settlementId",
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              readSubReport(row.original.settlementId);
            }}
          >
            {row.original.settlementId}
          </button>
        );
      },
      disableSortBy: true,
      Footer: "",
    },
    {
      Header: "DATE",
      accessor: "settlementTime",
      disableSortBy: true,
      Footer: "",
    },
    {
      Header: "DOWNLINE",
      accessor: "salesOwner",
      disableSortBy: true,
      Footer: "",
    },
    {
      Header: "GAME_NAME",
      accessor: "productName",
      disableSortBy: true,
      Footer: "",
    },
    {
      Header: "PLAYERID",
      accessor: "salesPlayer",
      disableSortBy: true,
      Footer: "",
    },
    {
      Header: "SALES",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(
          parseFloat(row.original.salesAmount),
          3,
          true
        );
      },
      disableSortBy: true,
      Footer: "",
    },
    {
      Header: "COMMISSION_ID",
      accessor: "commissionId",
      disableSortBy: true,
      Footer: "",
    },
    {
      Header: "COMMISSION_TYPE",
      accessor: "commissionType",
      disableSortBy: true,
      Footer: "",
    },
    {
      Header: "WALLET_BEFORE_BALANCE",
      accessor: "walletBalanceBefore",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(
          parseFloat(row.original.walletBalanceBefore),
          3,
          true
        );
      },
      disableSortBy: true,
    },
    {
      Header: "COMMISSION",
      accessor: "commissionAmount",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(
          parseFloat(row.original.commissionAmount),
          3,
          true
        );
      },
      disableSortBy: true,
      Footer: () => {
        return numberWithCurrencyFormat(parseFloat(totalCommission), 3, true);
      },
    },
    {
      Header: "WALLET_AFTER_BALANCE",
      accessor: "walletBalanceAfter",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(
          parseFloat(row.original.walletBalanceAfter),
          3,
          true
        );
      },
      disableSortBy: true,
    },
  ];

  async function readSubReport(targetTransferId) {
    try {
      var apiUrl =
        ApiUrl._API_COMMISSION_TRACKING +
        "?dateFrom=" +
        startDate +
        "&dateTo=" +
        endDate +
        "&searchDownline=" +
        true;

      if (!stringIsNullOrEmpty(targetTransferId)) {
        apiUrl += "&targetSettlementId=" + targetTransferId;
        apiUrl += "&isSubReport=" + true;
      }

      var responseJson = await ApiEngine.get(apiUrl);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setReportData(responseJson[ApiKey._API_DATA_KEY]);
        calcualteDetails(responseJson[ApiKey._API_DATA_KEY]);
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (error) {
      _dispatch(showMessage(false, t(error)));
    }
  }

  function calcualteDetails(records) {
    let commissionAmount = 0;
    let sales = 0;

    for (let i = 0; i < records.length; i++) {
      commissionAmount += records[i].commissionAmount;
      sales += records[i].salesAmount;
    }
    setTotalCommission(commissionAmount);
    setTotalSales(sales);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (props.id) {
      setMemberId(props.id);
      getNonSeamLessProductBalance();
      getProductTransactionRecord();
      register({ name: "fromWallet" }, { required: "PLEASE_SELECT_WALLET" });
      register({ name: "toWallet" }, { required: "PLEASE_SELECT_WALLET" });
    }
  }, [props.id]);

  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    await fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const accountProductOptionList = [
              { label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
            ];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
              accountProductOptionList.push({
                label: option.productName,
                value: option.id,
              });
            });

            setProductList(accountProductOptionList);
          }
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const getNonSeamLessProductBalance = async () => {
    await fetch(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE + "?memberId=" + props.id,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let nonSeamlessBalance = [];
          responseJson[ApiKey._API_DATA_KEY].map((product, index) => {
            nonSeamlessBalance.push({
              label: product.productName,
              value: product.productId,
              balance: product.balance,
            });
          });
          setNonSeamlessBalance(nonSeamlessBalance);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const getProductTransactionRecord = async () => {
    try {
      var apiUrl =
        ApiUrl._API_COMMISSION_TRACKING +
        "?dateFrom=" +
        startDate +
        "&dateTo=" +
        endDate +
        "&searchDownline=" +
        true;

      apiUrl += "&memberId=" + props.id;

      if (selectedProduct != ALL_OPTION_VALUE) {
        apiUrl += "&masterProductId=" + selectedProduct;
      }

      var responseJson = await ApiEngine.get(apiUrl);

      console.log(responseJson[ApiKey._API_DATA_KEY]);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setReportData(responseJson[ApiKey._API_DATA_KEY]);
        calcualteDetails(responseJson[ApiKey._API_DATA_KEY]);
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (error) {
      _dispatch(showMessage(false, t(error)));
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const transferAllToMain = async () => {
    let params = {
      memberId: memberId,
    };

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    await fetch(ApiUrl._API_TRANSFER_ALL_TO_MAIN, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          getNonSeamLessProductBalance();
        }
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        getProductTransactionRecord();
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      fromWallet: data.fromWallet.value,
      toWallet: data.toWallet.value,
      amount: data.amount,
      memberId: memberId,
    };

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    await fetch(ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          getNonSeamLessProductBalance();
        }

        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        getProductTransactionRecord();
      });
  };

  async function adminLog() {

    let params = {
      "startDate": startDate,
      "endDate": endDate,
      "searchDownline": "true",
      "memberId": props.id
    };
    if (selectedProduct != ALL_OPTION_VALUE) {
      params["masterProductId"] = selectedProduct;
    }

    _dispatch(setSaveAdminLog("VIEW_COMMISSION_TRACKING", _history.location.pathname, params));
  }

  return (
    <div>
      <form>
        {showConfirmation && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText={t("CONFIRM")}
            cancelBtnText={t("CANCEL")}
            confirmBtnBsStyle="danger"
            title={t("ARE_YOU_SURE")}
            onConfirm={() => {
              setShowConfirmation(false);
              transferAllToMain();
            }}
            onCancel={() => {
              setShowConfirmation(false);
            }}
          >
            {t("UNABLE_TO_REVERT")}
          </SweetAlert>
        )}
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label>
                    <b>{t("DATE")}</b>
                  </label>
                  <div
                    className="input-group"
                    style={{ width: "100%", flexFlow: "nowrap" }}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-calendar"></i>
                      </span>
                    </div>
                    <div style={{ width: "80%" }}>
                      <DateRangePicker
                        containerStyles={{ width: "100%" }}
                        startDate={startDate}
                        endDate={endDate}
                        onApply={handleEvent}
                        alwaysShowCalendars={true}
                        locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                        timePicker={true}
                        timePickerSeconds={true}
                        ranges={{
                          Today: [
                            moment().startOf("day"),
                            moment().endOf("day"),
                          ],
                          Yesterday: [
                            moment().subtract(1, "days").startOf("day"),
                            moment().subtract(1, "days").endOf("day"),
                          ],
                          "Last 7 Days": [
                            moment().subtract(6, "days").startOf("day"),
                            moment().endOf("day"),
                          ],
                          "Last 30 Days": [
                            moment().subtract(29, "days").startOf("day"),
                            moment().endOf("day"),
                          ],
                          "This Month": [
                            moment().startOf("month"),
                            moment().endOf("month"),
                          ],
                          "Last Month": [
                            moment().subtract(1, "month").startOf("month"),
                            moment().subtract(1, "month").endOf("month"),
                          ],
                          "This Year": [
                            moment().startOf("year"),
                            moment().endOf("year"),
                          ],
                        }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={startDate + " - " + endDate}
                        />
                      </DateRangePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label>
                    <b>{t("GAME_NAME")}</b>
                  </label>
                  <Select
                    styles={_OPTION_STYLES}
                    value={productList.filter(
                      (product) => product.value == selectedProduct
                    )}
                    options={productList}
                    onChange={(e) => {
                      setSelectedProduct(e.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2">
                <button
                  type="button"
                  style={{ marginLeft: "5px" }}
                  className="btn btn-primary"
                  onClick={() => { getProductTransactionRecord(); adminLog(); }}
                >
                  {t("SEARCH")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-12">
            <ReactTable
              data={reportData}
              columns={_tableColumns}
              renderFooter={true}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CommissionTracking;
