import React, { useState, useEffect, useCallback } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const ManageDailySummaryReport = (props) => {
  const _dispatch = useDispatch();
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [dailySummaryDatas, setDailySummaryDatas] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getDailySummaryDatas();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const getFilteredRecords = useCallback(({ page }) => {
    setFilteredDatas(page);
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const getDailySummaryDatas = async () => {
    _dispatch(setBusy());
    await fetch(
      ApiUrl._API_GET_DAILY_SUMMARY_REPORT +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setDailySummaryDatas(responseJson[ApiKey._API_DATA_KEY]);
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
        _dispatch(setIdle());
      });
  };

  let _tableColumns = [
    {
      Header: t("DATE"),
      accessor: "date",
      Footer: () => {
        return (
          <span>
            <b>{t("TOTAL")}</b>
          </span>
        );
      },
      minWidth: 100,
    },
    {
      Header: t("REGISTER"),
      accessor: "register",
      Footer: () => {
        let totalRegister = 0;
        for (let i = 0; i <= dailySummaryDatas.length; i++) {
          if (dailySummaryDatas[i] && dailySummaryDatas[i].register) {
            totalRegister += parseFloat(dailySummaryDatas[i].register);
          }
        }
        return <span>{totalRegister}</span>;
      },
    },
    {
      Header: t("FIRST_DEPOSIT"),
      accessor: "firstDeposit",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.firstDepositCount} /{" "}
            {row.original.firstDepositApprovalCount} /{" "}
            {row.original.firstDepositTotalAmount}
          </span>
        );
      },
      Footer: () => {
        let firstDepositCountTotal = 0;
        let firstDepositApprovalCountTotal = 0;
        let firstDepositTotalAmountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i]) {
            firstDepositCountTotal += parseFloat(
              filteredDatas[i].original.firstDepositCount
            );
            firstDepositApprovalCountTotal += parseFloat(
              filteredDatas[i].original.firstDepositApprovalCount
            );
            firstDepositTotalAmountTotal += parseFloat(
              filteredDatas[i].original.firstDepositTotalAmount
            );
          }
        }
        return (
          <span>
            {firstDepositCountTotal.toFixed()} /{" "}
            {firstDepositApprovalCountTotal.toFixed()} /{" "}
            {firstDepositTotalAmountTotal.toFixed(2)}
          </span>
        );
      },
      minWidth: 200,
    },
    {
      Header: t("DEPOSIT"),
      accessor: "deposit",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.depositCount} / {row.original.depositApprovalCount} /{" "}
            {row.original.depositTotalAmount}
          </span>
        );
      },
      Footer: () => {
        let depositCountTotal = 0;
        let depositApprovalCountTotal = 0;
        let depositTotalAmountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i]) {
            depositCountTotal += parseFloat(
              filteredDatas[i].original.depositCount
            );
            depositApprovalCountTotal += parseFloat(
              filteredDatas[i].original.depositApprovalCount
            );
            depositTotalAmountTotal += parseFloat(
              filteredDatas[i].original.depositTotalAmount
            );
          }
        }
        return (
          <span>
            {depositCountTotal.toFixed()} /{" "}
            {depositApprovalCountTotal.toFixed()} /{" "}
            {depositTotalAmountTotal.toFixed(2)}
          </span>
        );
      },
      minWidth: 200,
    },
    {
      Header: t("WITHDRAWAL"),
      accessor: "withdrawal",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.withdrawalCount} /{" "}
            {row.original.withdrawalApprovalCount} /{" "}
            {row.original.withdrawalTotalAmount}
          </span>
        );
      },
      Footer: () => {
        let withdrawalCountTotal = 0;
        let withdrawalApprovalCountTotal = 0;
        let withdrawalTotalAmountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i]) {
            withdrawalCountTotal += parseFloat(
              filteredDatas[i].original.withdrawalCount
            );
            withdrawalApprovalCountTotal += parseFloat(
              filteredDatas[i].original.withdrawalApprovalCount
            );
            withdrawalTotalAmountTotal += parseFloat(
              filteredDatas[i].original.withdrawalTotalAmount
            );
          }
        }
        return (
          <span>
            {withdrawalCountTotal.toFixed()} /{" "}
            {withdrawalApprovalCountTotal.toFixed()} /{" "}
            {withdrawalTotalAmountTotal.toFixed(2)}
          </span>
        );
      },
      minWidth: 200,
    },
    {
      Header: t("BONUS"),
      accessor: "bonus",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.bonusCount} /{" "}
            {row.original.bonusApprovalCount} /{" "}
            {row.original.bonusTotalAmount}
          </span>
        );
      },
      Footer: () => {
        let bonusCountTotal = 0;
        let bonusApprovalCountTotal = 0;
        let bonusTotalAmountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i]) {
            bonusCountTotal += parseFloat(
              filteredDatas[i].original.bonusCount
            );
            bonusApprovalCountTotal += parseFloat(
              filteredDatas[i].original.bonusApprovalCount
            );
            bonusTotalAmountTotal += parseFloat(
              filteredDatas[i].original.bonusTotalAmount
            );
          }
        }
        return (
          <span>
            {bonusCountTotal.toFixed()} /{" "}
            {bonusApprovalCountTotal.toFixed()} /{" "}
            {bonusTotalAmountTotal.toFixed(2)}
          </span>
        );
      },
      minWidth: 200,
    },
    {
      Header: t("ADJUSTMENT"),
      accessor: "adjustment",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.adjustmentCount} /{" "}
            {row.original.adjustmentApprovalCount} /{" "}
            {row.original.adjustmentTotalAmount}
          </span>
        );
      },
      Footer: () => {
        let adjustmentCountTotal = 0;
        let adjustmentTotalAmountTotal = 0;
        let adjustmentApprovalCountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i]) {
            adjustmentCountTotal += parseFloat(
              filteredDatas[i].original.adjustmentCount
            );
            adjustmentApprovalCountTotal += parseFloat(
              filteredDatas[i].original.adjustmentApprovalCount
            );
            adjustmentTotalAmountTotal += parseFloat(
              filteredDatas[i].original.adjustmentTotalAmount
            );
          }
        }
        return (
          <span>
            {adjustmentCountTotal.toFixed()} /{" "}
            {adjustmentApprovalCountTotal.toFixed()} /{" "}
            {adjustmentTotalAmountTotal.toFixed(2)}
          </span>
        );
      },
      minWidth: 200,
    },
    {
      Header: t("Transfer"),
      accessor: "transfer",
        Cell: ({ row }) => {
            return (
                <span>
                    {row.original.transferCount} /{" "}
                    {row.original.transferApprovalCount} /{" "}
                    {row.original.transferTotalAmount}
                </span>
            );
        },
        Footer: () => {
            let transferCountTotal = 0;
            let transferTotalAmountTotal = 0;
            let transferApprovalCountTotal = 0;
            for (let i = 0; i <= filteredDatas.length; i++) {
                if (filteredDatas[i]) {
                    transferCountTotal += parseFloat(
                        filteredDatas[i].original.transferCount
                    );
                    transferApprovalCountTotal += parseFloat(
                        filteredDatas[i].original.transferApprovalCount
                    );
                    transferTotalAmountTotal += parseFloat(
                        filteredDatas[i].original.transferTotalAmount
                    );
                }
            }
            return (
                <span>
                    {transferCountTotal.toFixed()} /{" "}
                    {transferApprovalCountTotal.toFixed()} /{" "}
                    {transferTotalAmountTotal.toFixed(2)}
                </span>
            );
        },
        minWidth: 200,
    },
    {
      Header: t("REBATE"),
      accessor: "rebate",
      Footer: () => {
        let rebateTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i]) {
            rebateTotal += parseFloat(filteredDatas[i].original.rebate);
          }
        }
        return <span>{rebateTotal.toFixed(2)}</span>;
      },
    },
    {
      Header: t("TURNOVER"),
      accessor: "turnover",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.firstTurnover} / {row.original.secondTurnover}
          </span>
        );
      },
      Footer: () => {
        let firstTurnoverTotal = 0;
        let secondTurnoverTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i]) {
            firstTurnoverTotal += parseFloat(
              filteredDatas[i].original.firstTurnover
            );
            secondTurnoverTotal += parseFloat(
              filteredDatas[i].original.secondTurnover
            );
          }
        }
        return (
          <span>
            {firstTurnoverTotal.toFixed(2)} / {secondTurnoverTotal.toFixed(2)}
          </span>
        );
      },
      minWidth: 200,
    },
    {
      Header: t("PROFIT_LOSS"),
      accessor: "profitLoss",
      Cell: ({ row }) => {
        if (row.original.profitLoss < 0) {
          return <p style={{ color: "red" }}>{row.original.profitLoss}</p>;
        } else {
          return <p>{row.original.profitLoss}</p>;
        }
      },
      Footer: () => {
        let profitLossTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i]) {
            profitLossTotal += parseFloat(filteredDatas[i].original.profitLoss);
          }
        }
        return <span>{profitLossTotal.toFixed(2)}</span>;
      },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
  };

  async function adminLog() {
    let params = {
      "startDate": startDate,
      "endDate": endDate
    };

    _dispatch(setSaveAdminLog("VIEW_BO_DAILY_SUMMARY_REPORT", _history.location.pathname, params));
  }

  return (
    <div>
      <h1 className="page-header">{t("DAILY_SUMMARY_REPORT")}</h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div className="input-group" style={{ width: "100%" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          startDate={startDate}
                          endDate={endDate}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD" }}
                          ranges={{
                            Today: [moment(), moment()],
                            Yesterday: [
                              moment().subtract(1, "days"),
                              moment().subtract(1, "days"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days"),
                              moment(),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days"),
                              moment(),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={startDate + " - " + endDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <button
                    type="button"
                    onClick={() => {
                      getDailySummaryDatas();
                      adminLog();
                    }}
                    className="btn btn-primary"
                  >
                    {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
              <ReactTable
                data={dailySummaryDatas}
                columns={_tableColumns}
                renderFooter={true}
                getFilteredRecords={getFilteredRecords}
              />
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageDailySummaryReport;
