import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo, numberWithCommas, stringIsNullOrEmpty } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  WebUrl,
  AccessRight,
  LanguageKey,
  AllVipOption
} from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ManageBonusDeposit = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const [bonusDepositData, setBonusDepositData] = useState([]);
  const [vipOption, setVipOption] = useState([]);

  let _tableColumns = [
    {
      Header: "Id",
      accessor: "id",
      disableSortBy: true,
    },
    {
      Header: "Bonus Name",
      accessor: "bonusName",
    },
    {
      Header: "Bonus",
      accessor: "bonus",
      //Cell: ({ row }) => {
      //  if (stringIsNullOrEmpty(row.original.vipId)) {
      //    return <>{AllVipOption._LABEL}</>;
      //  } else {
      //    return <>{row.original.vipModel.name}</>;
      //  }
      //},
    },
    {
      Header: "Minimum Deposit",
      accessor: "minimumDeposit",

    },
    {
      Header: "Rollover",
      accessor: "rollover",
    },
    {
      Header: "Max Bonus",
      accessor: "maxBonus",

    },
    {
      Header: "Max Limit Per Member",
      accessor: "maxLimitPerMember",

    },
    {
      Header: "Day Limit",
      accessor: "dayLimit",

    },
    {
      Header: "VIP",
      accessor: "vipId",
      Cell: ({ row }) => {
        if (stringIsNullOrEmpty(row.original.vipId)) {
          return <>{AllVipOption._LABEL}</>;
        } else if (vipOption && vipOption.length > 0) {
          return <>{vipOption.filter(x => x.value == row.original.vipId)[0].label}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      Header: "Rank",
      accessor: "rank",

    },
    //{
    //  Header: "Eligible For Free Spin",
    //  accessor: "freeSpinStatus",
    //  Cell: ({ row }) => (
    //    <div className="btn-group m-r-5 m-b-5">
    //      {row.original.freeSpinStatus == 1 ? (
    //        <span className="badge badge-secondary badge-green">
    //          {t("ACTIVE")}
    //        </span>
    //      ) : (
    //        <span className="badge badge-secondary badge-danger">
    //          {t("DISABLED")}
    //        </span>
    //      )}
    //    </div>
    //  ),
    //  disableSortBy: true,
    //  style: { overflow: "visible" },
    //},
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("SUSPENDED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
      (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_CREATE_OR_UPDATE_BONUS_DEPOSIT,
                    row.original
                  );
                }}
              >
                <span className="text-warning">{t("EDIT")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await fetch(ApiUrl._API_GET_ALL_BONUS_DEPOSIT, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          console.log(responseJson[ApiKey._API_DATA_KEY]);
          setBonusDepositData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });

    await fetch(ApiUrl._API_GET_USER_VIP, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const vipList = [
            { label: AllVipOption._LABEL, value: AllVipOption._VALUE },
          ];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (vipLevel) {
            vipList.push({ label: vipLevel.name, value: vipLevel.id });
          });

          setVipOption(vipList);
        }
      });
  }

  return (
    <div>
      <h1 className="page-header">
        {t("BONUS_DEPOSIT")}
        {(
          <NavigationButton
            history={_history}
            url={WebUrl._URL_CREATE_OR_UPDATE_BONUS_DEPOSIT}
            buttonText={t("ADD_BONUS_DETAIL")}
          />
        )}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable data={bonusDepositData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageBonusDeposit;
