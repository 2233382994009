import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  navigateTo,
  stringIsNullOrEmpty,
  numberWithCurrencyFormat,
} from "../../util/Util";
import {
  ApiKey,
  ApiUrl,
  AccessRight,
  WebUrl,
  LanguageKey,
  DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";

/// <summary>
/// Author : -
/// </summary>
const ManageMemberProductUsername = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();

  var _username = useSelector(
    (state) => state["authState"]["userData"]["username"]
  );

  const _dispatch = useDispatch();
  const [memberData, setMemberData] = useState([]);

  const _SELECTED_MEMBER_KEY = "selectedMemberKey";

  const [keyword, setKeyword] = useState("");
  const [selectedUsername, setSelectedUsername] = useState("");
  const [locationStateObj, setLocationStateObj] = useState();
  const [memberId, setMemberId] = useState("");
  const [nodePathMembers, setNodePathMembers] = useState([]);
  const [labelUsername, setLabelUsername] = useState("All [ productUsername - memberUsername (product) ]");
  const [memberListUrl, setMemberListUrl] = useState(
    `${ApiUrl._API_GET_DOWNLINE_BY_REFERRER}?memberId=${memberId}`
  );
  const [tableData, setTableData] = useState([]);

  let _tableColumns = [
    {
      Header: "USERNAME",
      accessor: "username",
      minWidth: 100,
      //Cell: ({ row }) => {
      //    return (
      //        <span
      //            className={classNames({
      //                "clickable-username":
      //                    nodePathMembers.length > 0
      //                        ? nodePathMembers[nodePathMembers.length - 1].id !=
      //                        row.original.id
      //                        : true,
      //            })}
      //            onClick={() => setSelectedUsername(row.original.id)}
      //        >
      //            {row.original.username}
      //        </span>
      //    );
      //},
    },
    {
      Header: "MEMBER_PRODUCT_USERNAME",
      accessor: "memberProductUsername",
      minWidth: 100,
    },
    {
      Header: "FULLNAME",
      accessor: "userFullName",
      minWidth: 100,
    },
    {
      Header: "EMAIL",
      accessor: "email",
      minWidth: 100,
    },
    {
      Header: "WALLET",
      accessor: "primaryCredit",
      Cell: ({ row }) => {
        return (
          <>
            {numberWithCurrencyFormat(
              parseFloat(row.original.primaryCredit),
              3
            )}
          </>
        );
      },
      minWidth: 100,
    },
    {
      Header: "PHONE",
      accessor: "phoneNumber",
      minWidth: 100,
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.status == 1 ? (
              <span className="badge badge-secondary badge-green">
                {t("ACTIVE")}
              </span>
            ) : (
              <span className="badge badge-secondary badge-danger">
                {t("SUSPENDED")}
              </span>
            )}
          </span>
        );
      },
      minWidth: 100,
    },
    //{
    //    id: "action",
    //    Header: "",
    //    Cell: ({ row }) =>
    //        checkIfPermissionExist(
    //            AccessRight._MEMBER_CONTROL_PERMISSION,
    //            true
    //        ) && (
    //            <div className="btn-group m-r-5 m-b-5">
    //                <UncontrolledButtonDropdown>
    //                    <DropdownToggle caret color="default">
    //                        <i className="fas fa-cog"></i>
    //                    </DropdownToggle>
    //                    <DropdownMenu>
    //                        <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_VIEW_ACCOUNT, row.original); }}><span className="text-warning">{t("EDIT")}</span></DropdownItem>
    //                        <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_VIEW_PRODUCT_ACCOUNT, row.original); }}><span className="text-warning">{t("VIEW_PRODUCT_ACCOUNT")}</span></DropdownItem>
    //                        <DropdownItem onClick={() => syncProductData(row.original)}>
    //                            <span className="text-primary">{t("SYNC_PRODUCT")}</span>
    //                        </DropdownItem>
    //                        <DropdownItem
    //                            onClick={() => {
    //                                navigateTo(
    //                                    _history,
    //                                    WebUrl._URL_MEMBER_PRORUCT_MAINTENANCE,
    //                                    row.original
    //                                );
    //                            }}
    //                        >
    //                            <span className="text-primary">{t("VIEW_PRODUCT")}</span>
    //                        </DropdownItem>
    //                    </DropdownMenu>
    //                </UncontrolledButtonDropdown>
    //            </div>
    //        ),
    //    disableSortBy: true,
    //    disableFilters: true,
    //    width: 100,
    //    style: { overflow: "visible" },
    //},
  ];

  /// <summary>
  /// Author : Wong
  /// </summary>
  //useEffect(() => {
  //    let nodePath = [];
  //    let memId = "";
  //    if (tableData.memberNodePath) {
  //        if (tableData.memberNodePath.length > 0) {
  //            nodePath = tableData.memberNodePath;
  //            memId = nodePath[nodePath.length - 1].id;
  //        }
  //    }

  //    setNodePathMembers(nodePath);
  //    setMemberId(memId);
  //    setLocationStateObj({
  //        parentMemberId: memId,
  //    });
  //}, [tableData]);

  /// <summary>
  /// Author : -
  /// </summary>
  //useEffect(() => {
  //    sessionStorage.setItem(_SELECTED_MEMBER_KEY, selectedUsername);
  //    getDownlineByReferrer(selectedUsername);
  //}, [selectedUsername]);

  /// <summary>
  /// Author : -
  /// </summary>
  //async function getDownlineByReferrer(memberId = "") {
  //    setMemberListUrl(
  //        `${ApiUrl._API_GET_DOWNLINE_BY_REFERRER}?memberId=${memberId}`
  //    );
  //}

  /// <summary>
  /// Author : -
  /// </summary>
  //async function syncProductData(data) {
  //    var responseJson = await ApiEngine.post(
  //        `${ApiUrl._API_SYNC_MEMBER_TO_OWNER_PRODUCT_DATA}?id=${data["id"]}`
  //    );
  //    _dispatch(
  //        showMessage(
  //            responseJson[ApiKey._API_SUCCESS_KEY],
  //            t(responseJson[ApiKey._API_MESSAGE_KEY])
  //        )
  //    );
  //}

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function formergetMPUsernameList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_MEMBER_PRODUCT_USERNAME_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let mpUsernameList = [];
      data.map((m) => {
        m.usernameList.map((u, index) => {
          mpUsernameList.push({ productName: m.masterProductNameList[index], username: m.username, mpUsername: u, id: m.id });
        })
      });
      let formattedData = mpUsernameList.map((player) => {
        return { label: player.mpUsername + ' - ' + player.username + " (" + player.productName + ")", value: player.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function getMPUsernameList() {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_MEMBER_PRODUCT_USERNAME_LIST}?keyword=${keyword}`
    );
    _dispatch(setSaveAdminLog("VIEW_MEMBER_PRODUCT_USERNAME", _history.location.pathname, { "keyword": keyword }));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setTableData(data);
    }
  }

  return (
    <div>
      <h1 className="page-header">
        {t("MANAGE_MEMBER_PRODUCT_USERNAME")}
      </h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-5" style={{ margin: "15px" }}>
                <div className="form-group">
                  <label>
                    <b>{t("MEMBER_PRODUCT_USERNAME")}</b>
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                    />
                    <button className="btn btn-primary" style={{ marginLeft: "5px", width: "100px" }} onClick={() => getMPUsernameList()}>{t("SEARCH")}</button>
                  </div>
                  <div style={{ fontSize: "14px" }}>{t("KEY_MP_USERNAME_TO_CONTINUE")}</div>
                  {/*<AsyncPaginate
                                        placeholder={labelUsername}
                                        debounceTimeout={250}
                                        loadOptions={getMPUsernameList}
                                        additional={{
                                            page: 1,
                                        }}
                                        value={selectedUsername}
                                        onChange={(e) => {
                                            if (!stringIsNullOrEmpty(e.value)) {
                                                sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                                            } else {
                                                sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                                            }
                                            setLabelUsername(e.label);
                                            setSelectedUsername(e.value);
                                        }}
                                    />*/}
                </div>
              </div>
            </div>
            <PanelBody>
              <ReactTable
                data={tableData}
                filterable
                columns={_tableColumns}
              />
            </PanelBody>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default ManageMemberProductUsername;