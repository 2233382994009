import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import "../../assets/css/reward.css";

import { useHistory } from "react-router-dom";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { navigateTo, createFormBody } from "../../util/Util";

import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>

const RewardsSetting = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, watch } = useForm({ mode: "onBlur" });

    let _history = useHistory();
    const [settingData, setSettingData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const { loginUserId } = useSelector((state) => ({
        loginUserId: state.authState.userData.userId,
    }));

    const [formData, setFormData] = useState({});

    const daysArr = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];

    /// <summary>
    /// Author : -
    /// </summary>
    function updateProductData() {

        _dispatch(setBusy());
        fetch(ApiUrl._API_GET_REWARDS_SETTING, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setSettingData(responseJson[ApiKey._API_DATA_KEY].data.settings);
                    setCategoryData(responseJson[ApiKey._API_DATA_KEY].data.categories);
                }
            });

        _dispatch(setIdle());
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        updateProductData();

    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {
        let formBody = createFormBody(formData);
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_REWARDS_SETTING,
            formBody
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(
                showMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    t(responseJson[ApiKey._API_MESSAGE_KEY])
                )
            );
        }
        else {
            _dispatch(
                showMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    t(responseJson[ApiKey._API_MESSAGE_KEY])
                )
            );
      }
      _dispatch(setSaveAdminLog("EDIT_REWARDS_SETTING", _history.location.pathname, formData));
    };

    /// <summary>
    /// Author : -
    /// </summary>
    function preSubmit() {
        handleSubmit(submitForm)();
    }

    return (
        <div>
            <h1 className="page-header">{t("Rewards Setting")}</h1>

            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div style={{ overflow: "scroll" }}>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {
                                            daysArr.length > 0 && <>
                                                <th style={{ textAlign: "right" }}>{daysArr[0]}</th>
                                                <th style={{ textAlign: "right" }}>{daysArr[1]}</th>
                                                <th style={{ textAlign: "right" }}>{daysArr[2]}</th>
                                                <th style={{ textAlign: "right" }}>{daysArr[3]}</th>
                                                <th style={{ textAlign: "right" }}>{daysArr[4]}</th>
                                                <th style={{ textAlign: "right" }}>{daysArr[5]}</th>
                                                <th style={{ textAlign: "right" }}>{daysArr[6]}</th>
                                            </>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        categoryData.length > 0 && categoryData.map(
                                            (o, i) => {
                                                return (
                                                    Object.entries(settingData[o.title]).length > 0 && <>
                                                        <tr>
                                                        <th colSpan="8">{o.title}</th>
                                                        </tr>
                                                        {
                                                            Object.entries(settingData[o.title]).map(
                                                                (o2, i2) => {

                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <th style={{ textAlign: "right" }}>
                                                                                    {
                                                                                        o2[1].Name
                                                                                    }
                                                                                </th>
                                                                                {
                                                                                    Object.entries(daysArr).map(
                                                                                        (o3, i3) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <th style={{ textAlign: "right" }}>
                                                                                                        {
                                                                                                            <>
                                                                                                                <input
                                                                                                                    key={o.id + "-" + o2[0] + "-" + o3[1]}
                                                                                                                    name=""
                                                                                                                    type="number"
                                                                                                                    step="0.01"
                                                                                                                    className="form-control"
                                                                                                                    ref={register({
                                                                                                                        required: "Please enter value",
                                                                                                                        min: {
                                                                                                                            value: 0,
                                                                                                                            message: "Min 0.0%",
                                                                                                                        },
                                                                                                                        max: {
                                                                                                                            value: (o2[1].Name == "JJVIP" ? 2 :1.2),
                                                                                                                            message: (o2[1].Name == "JJVIP" ? "Max 2%" : "Max 1.2%"),
                                                                                                                        },
                                                                                                                    })}
                                                                                                                    onChange={(e) => {
                                                                                                                        parseFloat(e.target.value) < 0 ?
                                                                                                                            (e.target.value = 0) : (parseFloat(e.target.value) > (o2[1].Name == "JJVIP" ? 2 : 1.2) ?
                                                                                                                                e.target.value = (o2[1].Name == "JJVIP" ? 2 : 1.2) : e.preventDefault());

                                                                                                                        e.target.name = o.id + "-" + o2[0] + "-" + o3[1];
                                                                                                                        setFormData({...formData, [o.id + "-" + o2[0] + "-" + o3[1]]: e.target.value });
                                                                                                                    }}
                                                                                                                    defaultValue={o2[1][o3[1]]}
                                                                                                                    style={{ textAlign: "right" }} />

                                                                                                                {errors[o.id + "-" + o2[0] + "-" + o3[1]] && (
                                                                                                                    <div className="invalid-feedback">
                                                                                                                        {t(errors[o.id + "-" + o2[0] + "-" + o3[1]].message)}
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </>
                                                                                                        }
                                                                                                    </th>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    )
                                                                                }

                                                                            </tr>
                                                                        </>
                                                                    )
                                                                    /*
                                                                    Object.entries(o2).map(
                                                                        (o3, i3) => {
                                                                        }
                                                                    )*/
                                                                }
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        onClick={() => preSubmit()}
                                        className="btn btn-primary"
                                    >
                                        {t("SUBMIT")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        </form>
                </PanelBody>
            </Panel>
        </div>
    );
};

export default RewardsSetting;
