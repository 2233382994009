import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ContactUsDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors, setValue } = useForm();
  const [contactUsId, setContactUsId] = useState("");

  const [text, setText] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [image, setImage] = useState();
  const [isNewImage, setIsNewImage] = useState(false);
  const [status, setStatus] = useState(true);

  /// <summary>
  /// Author : -
  /// </summary>
  const logoDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setImageFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setIsNewImage(true);
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_COMPANY_CONTACT_US_BY_ID;
      apiUrl += "?id=" + _location.state.id;
      fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setContactUsId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setText(responseJson[ApiKey._API_DATA_KEY]["text"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);

            if (
              !stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["image"])
            ) {
              setImage(responseJson[ApiKey._API_DATA_KEY]["image"]);
            }
          }
        });
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      text: data.text,
      status: data.status,
      isNewImage: isNewImage,
    };

    if (!stringIsNullOrEmpty(imageFile)) {
      params["imageFile"] = imageFile;
    }

    if (stringIsNullOrEmpty(image)) {
      params["imageRemoved"] = true;
    }

    if (!stringIsNullOrEmpty(contactUsId)) {
      params["id"] = contactUsId;
      _dispatch(setSaveAdminLog("EDIT_BO_CONTACT_US", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_CONTACT_US", _history.location.pathname, params));
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_COMPANY_CONTACT_US, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setContactUsId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
      });
  };

  return (
    <div>
      <h1 className="page-header">
        {contactUsId ? t("EDIT_CONTACT_US") : t("ADD_CONTACT_US")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("IMAGE")}</b>
                      </label>
                      <div className="col-md-7">
                        <Dropzone
                          accept={"image/*"}
                          onDrop={(acceptedFiles) => logoDrop(acceptedFiles)}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div
                                className="dropzone"
                                style={{
                                  minHeight: "200px",
                                  textAlign: "center",
                                }}
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <h4 style={{ color: "grey" }}>
                                  {t(
                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                  )}
                                </h4>
                                {!stringIsNullOrEmpty(image) && (
                                  <aside className="thumbsContainer">
                                    <div className="thumb">
                                      <div className="thumbInner">
                                        <img
                                          src={image}
                                          className="dropzone-img"
                                        />
                                      </div>
                                    </div>
                                  </aside>
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        <br />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            setImage("");
                            setImageFile();
                          }}
                        >
                          {t("REMOVE_IMAGE")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("TEXT")}</b>
                      </label>
                      <div className="col-md-7">
                        <textarea
                          className="form-control"
                          rows="5"
                          style={{ marginTop: "10px" }}
                          ref={register({ required: "PLEASE_ENTER_TEXT" })}
                          name="text"
                          defaultValue={text}
                        ></textarea>
                        {errors.text && (
                          <div className="invalid-feedback">
                            {t(errors.text.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("STATUS")}</b>
                      </label>
                      <div className="col-md-7">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="status"
                            id="status"
                            onChange={(e) => setStatus(e.target.checked)}
                            value={true}
                            checked={status}
                            ref={register}
                          />
                          <label htmlFor="status"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default ContactUsDetail;
