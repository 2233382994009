import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { createMultiPartFormBody, navigateTo } from "../../util/Util";
import { ApiKey, ApiUrl, WebUrl, LanguageKey, AccessRight } from "../../util/Constant";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import ToggleButton from "react-toggle-button";
import ApiEngine from "../../util/ApiEngine";
import DatePicker from "react-datepicker";
import moment from "moment";
import { checkIfPermissionExist } from "../../util/PermissionChecker";

/// <summary>
/// Author : -
/// </summary>

const ProductMaintenance = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const [productData, setProductData] = useState([]);
  const [targetSyncProduct, setTargetSyncProduct] = useState();
  const [showSettlementSyncSubModal, setShowSettlementSyncSubModal] = useState(
    false
  );
  const [settleDate, setSettleDate] = useState(moment().add(-1, 'day').toDate());
  const { loginUserId } = useSelector((state) => ({
    loginUserId: state.authState.userData.userId,
  }));

  let _tableColumns = [
    {
      Header: "NAME",
      accessor: "productName",
    },
    {
      Header: "CODE",
      accessor: "productCode",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("MAINTENANCE")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      Header: "UM",
      accessor: "productUm",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <ToggleButton
            value={row.original.productUm}
            onToggle={(value) => {
              updateProductUM(row.original)
            }}
          />
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      Header: "RANK",
      accessor: "rank",
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_PRODUCT_GAME_LIST,
                    row.original
                  );
                }}
              >
                <span className="text-warning">{t("VIEW_GAME_LIST")}</span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  updateProductAssignment(row.original);
                }}
              >
                {row.original.status ? t("DEACTIVATE") : t("ACTIVATE")}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_COMPANY_PRODUCT_ACCOUNT_INFO,
                    row.original
                  );
                }}
              >
                <span className="text-primary">
                  {t("EDIT_ACCOUNT_INFORMATION")}
                </span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_MANAGE_PRODUCT_GAME_SETTING,
                    row.original
                  );
                }}
              >
                <span className="text-primary">{t("MANAGE_GAME_SETTING")}</span>
              </DropdownItem>
              {checkIfPermissionExist(AccessRight._UPDATE_GAMES_RANK_PERMISSION) &&
                row.original.product.hasOwnLobby === true &&
                row.original.product.status === true &&
                row.original.status === true &&
                row.original.productName !== "XE88" &&
                row.original.productName !== "VPower" &&
                row.original.productName !== "MarioClub" &&
                row.original.productName !== "Ace333" &&
                <DropdownItem onClick={() => updateGameList(row.original.product)}>
                  <span className="text-primary">{t("UPDATE_GAME_LIST")}</span>
                </DropdownItem>
              }

              {(checkIfPermissionExist(AccessRight._UPDATE_GAMES_RANK_PERMISSION) && row.original.product.hasOwnLobby) ? <> <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_UPDATE_GAMES_RANK,
                    row.original
                  );
                }}
              >
                <span className="text-primary">{t("MANAGE_GAME_RANK")}</span>

              </DropdownItem></> : <></>}

              {checkIfPermissionExist(AccessRight._UPDATE_GAMES_RANK_PERMISSION) && <><DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_MANAGE_NEW_GAME,
                    row.original
                  );
                }}
              >
                <span className="text-primary">{t("MANAGE_NEW_GAME")}</span>
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_MANAGE_HOT_GAME,
                    row.original
                  );
                }}
              >
                <span className="text-primary">{t("MANAGE_HOT_GAME")}</span>
              </DropdownItem></>}
              {
                row.original.product.status === true &&
                row.original.status === true &&
                row.original.productName === "Mega888" && (
                  <DropdownItem
                    onClick={() => syncSettleGameData(row.original)}
                  >
                    <span className="text-primary">
                      {t("TRIGGER_SETTLE_SYNC_DATE")}
                    </span>
                  </DropdownItem>
                )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  function updateProductAssignment(data) {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_UPDATE_USER_ASSIGNMENT +
        "?userId=" +
        loginUserId +
        "&productId=" +
        data["productId"] +
        "&enable=" +
        !data["status"],
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          updateProductData();
        }
        _dispatch(setIdle());
      });

    let params = {
      userId: loginUserId,
      productId: data["productId"],
      enable: !data["status"]
    }
    _dispatch(setSaveAdminLog("EDIT_PRODUCT_ASSIGNMENT", _history.location.pathname, params));
  }

  const updateGameList = async (data) => {
    let params = {
      "id": data["id"],
      "productCode": data["productCode"],
      "productId": data["productId"]
    };

    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_GAME_LIST, createMultiPartFormBody(params));
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    _dispatch(setIdle());
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function updateProductUM(data) {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_UPDATE_USER_PRODUCT_MAINTENANCE +
      "?productId=" +
      data["productId"] +
      "&um=" +
      !data["productUm"],
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          updateProductData();
        }
        _dispatch(setIdle());
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function updateProductData() {
    fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setProductData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    updateProductData();
  }, []);

  // <summary>
  /// Author : -
  /// </summary>
  function syncSettleGameData(data) {
    setTargetSyncProduct(data);
    setShowSettlementSyncSubModal(true);
  }

  function triggerSettlementDate() {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_TRIGGER_SETTLEMENT_DATE +
      "?productId=" +
      targetSyncProduct["productId"] +
      "&startDate=" +
      moment(settleDate).format("YYYY-MM-DD"),
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        setShowSettlementSyncSubModal(false);
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
  }

  return (
    <div id="product-maintenance">
      <h1 className="page-header">{t("PRODUCT_MAINTENANCE")}</h1>
      <Panel>
        <PanelBody>
          <ReactTable
            filterable data={productData}
            columns={_tableColumns}
          />
        </PanelBody>
      </Panel>
      <Modal
        isOpen={showSettlementSyncSubModal}
        toggle={() => setShowSettlementSyncSubModal(false)}
        style={{ paddingBottom: "100px" }}
        className="modal-lg"
        keyboard={true}
      >
        <ModalHeader toggle={() => setShowSettlementSyncSubModal(false)}>
          {t("SELECT_DATE_TO_SYNC")}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-3">
              <label>
                <b>{t("DATE")}</b>
              </label>
              <div style={{ width: "80%" }}>
                <DatePicker
                  id="settleDatePicker"
                  className="form-control"
                  selected={settleDate}
                  onChange={(startdate) => setSettleDate(startdate)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  triggerSettlementDate();
                }}
              >
                <i className="fas fa-search"></i> {t("SUBMIT")}
              </button>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn btn-inverse-brand"
            onClick={() => setShowSettlementSyncSubModal(false)}
          >
            {t("CLOSE")}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProductMaintenance;
