import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
} from "../../util/Constant";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction"; 
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ApiEngine from "../../util/ApiEngine";
import { createFormBody, createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util.js";

const ConversionRate = props => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  const _history = useHistory();
  const ALL_OPTION_VALUE = "all";
  // const [masterProductList, setMasterProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(ALL_OPTION_VALUE);
  const [conversionRate, setConversionRate] = useState(0);
  const [initCheck, setInitCheck] = useState(false);
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
  } = useForm();

  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  const masterProductList = [
    {
      label: "918Kiss",
      value: "918Kiss",
    },
    {
      label: "D2Poker",
      value: "ArionLabs",
    },
    {
      label: "Mega888",
      value: "Mega888",
    },
    {
      label: "LionKing",
      value: "LionKing"
    },
    {
      label: "VPower",
      value: "VPower"
    }
  ]

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
    setInitCheck(true);
  }, [selectedProduct]);

  async function init() {
    let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=settings";
    var responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {

      let data = responseJson[ApiKey._API_DATA_KEY];
      data.map((keyValuePair, index) => {
        let productName = selectedProduct.replace(/\s+/g,' ').trim();
        if (keyValuePair['key'] === `${productName}`+ "ConversionRate") {
          let conversionRate = keyValuePair['value'];
          setValue("conversionRate", conversionRate);
        }
      });
    }
  }

  const submitForm = async (data, e) => {
    let productName = selectedProduct.replace(/\s+/g,' ').trim();
    let params = {
      conversionRate : data.conversionRate
    };
    _dispatch(setSaveAdminLog("EDIT_CONVERSION_RATE_" + productName, _history.location.pathname, params));
    let formBody = createMultiPartFormBody(params);

    let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_SETTINGS + "?type="+`${productName}`+ "ConversionRate";
    let responseJson = await ApiEngine.post(apiUrl, formBody);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
  }

  return (
    <div>
      <h1 className="page-header">
        {t("CONVERSION_RATE")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          {initCheck && <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="col-lg-8">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("PRODUCT")}</b>
                  </label>
                  <div className="col-4">
                    <div
                      className="form-group"
                      style={{ display: "flex", width: "100%" }}
                    >
                      <div
                        className="input-group"
                        style={{ width: "100%", flexFlow: "nowrap" }}
                      >
                        <div style={{ width: "70%" }}>
                          <Select
                            styles={_OPTION_STYLES}
                            value={masterProductList.filter(
                              (product) => product.label == selectedProduct
                            )}
                            options={masterProductList}
                            onChange={(e) => {
                              setSelectedProduct(e.label);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("CONVERSION_RATE")} 
                    <label style={{ color: "blue", paddingLeft: "5px" }}></label>
                    </b>
                  </label>
                  <div className="col-md-7">
                      <input
                          type="number"
                          step="0.001"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_AMOUNT")}
                          name="conversionRate"
                          onChange={(e) => {
                            setValue("conversionRate", e.target.value)
                          }}
                          ref={register({
                              required: "PLEASE_ENTER_AMOUNT",
                              min: {
                                  value: 0,
                                  message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                              },
                          })}
                      />
                      {errors.conversionRate && (
                        <div className="invalid-feedback">
                            {t(errors.conversionRate.message)}
                        </div>
                      )}
                  </div>
                </div>
                  
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>}
        </div>
      </div>
    </div>
  )
}

export default withRouter(ConversionRate);