import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from "react-select/creatable";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
    ApiKey,
    ApiUrl,
    TransactionType,
    PanelType,
    LanguageKey,
    DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { AsyncPaginate } from "react-select-async-paginate";

import PinInput from 'react-pin-input';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

/// <summary>
/// Author : -
/// </summary>
const ManageRollbackTicket = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _location = useLocation();
    const _dispatch = useDispatch();
    const {
        register,
        unregister,
        handleSubmit,
        errors,
        setValue,
        trigger,
        control,
    } = useForm();
    const [fullScreen, setFullScreen] = useState(false);
    const [selectedMember, setSelectedMember] = useState();
    const [masterProductId, setMasterProductId] = useState([]);
    const [selectedMasterProduct, setSelectedMasterProduct] = useState();
    const [showConfirmMessage, setShowConfirmMessage] = useState(false);
    const [gameRecordId, setGameRecordId] = useState('');

    /// <summary>
    /// Author : -
    /// Editted : -
    /// </summary>
    useEffect(() => {
        register({ name: "masterProductId" }, { required: "PLEASE_ENTER_GAME_RECORD_ID" });

        /// For The Size Difference of Modal And Page
        if (props.id) {
            setFullScreen(true);
        }
        initFormField();
    }, []);


    /// <summary>
    /// Author : -
    /// </summary>
    const initFormField = () => {
        if (stringIsNullOrEmpty(props.id)) {
            setMasterProductId("");
            setValue("masterProductId", "");
            getMasterProduct("", null, { page: 1 });
            setSelectedMasterProduct(null);
        } else {
            setMasterProductId(props.id);
            setValue("masterProductId", props.id);
        }
    };

    /// <summary>
    /// Author : -
    /// Editted : -
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            gameRecordId: gameRecordId,
            masterProductId: masterProductId

        };

        let formBody = createFormBody(params);

        let responseJson = await ApiEngine.post(
            ApiUrl._API_ROLLBACK_TICKET_BY_ID,
            formBody
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setGameRecordId('');
            setSelectedMember(null);
            initFormField();

            if (props.onComplete != null) {
                props.onComplete();
            }
        }

        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );
        setShowConfirmMessage(false);
    };


    async function getMasterProduct(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(
            `${ApiUrl._API_GET_MASTER_PRODUCT}?start=${(page - 1) * DEFAULT_PAGE_SIZE
            }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            let formattedData = data.map((m) => {
                return { label: m.productName, value: m.id };
            });

            return {
                options: formattedData,
                hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
                additional: {
                    page: page + 1,
                },
            };
        }
    }

    async function onConfirm() {
        setShowConfirmMessage(true);
    }

    async function closeForm() {
        setShowConfirmMessage(false);
    }
    return (
        <div>
            <h1 className="page-header">
                {t("MANAGE_ROLLBACK_TICKET")}{" "}
                {props.id ? (
                    <button
                        style={{ float: "right" }}
                        className="btn btn-inverse"
                        onClick={() => {
                            props.toggleModal(false);
                        }}
                    >
                        <i className="fa fa-times"></i>
                    </button>
                ) : (
                    <NavigationButton history={_history} />
                )}
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <div className="row">
                                <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("GAME_RECORD_ID")}</b>
                                        </label>

                                        <div className="col-md-7">
                                            <input
                                                type="number"
                                                value={gameRecordId}
                                                className="form-control"
                                                placeholder={t("PLEASE_ENTER_GAME_RECORD_ID")}
                                                name="gameRecordId"
                                                ref={register({
                                                    required: "PLEASE_ENTER_GAME_RECORD_ID",
                                                    validate: (value) =>
                                                        stringIsNullOrEmpty(value),
                                                })}
                                                onChange={(e) => {
                                                    setGameRecordId(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>
                                                {t("MASTER_PRODUCTS")}
                                            </b>
                                        </label>
                                        <div className="col-md-7">
                                            <AsyncPaginate
                                                name="masterProductId"
                                                placeholder={t("PLEASE_SELECT_MASTER_PRODUCT")}
                                                isDisabled={!stringIsNullOrEmpty(props.id)}
                                                debounceTimeout={250}
                                                loadOptions={getMasterProduct}
                                                additional={{
                                                    page: 1,
                                                }}
                                                value={selectedMasterProduct}
                                                onChange={(e) => {
                                                    setValue("masterProductId", e.value);
                                                    setMasterProductId(e.value);
                                                    setSelectedMasterProduct(e);
                                                    trigger("masterProductId");
                                                }}
                                            />
                                            {errors.masterProductId && (
                                                <div className="invalid-feedback">
                                                    {t(errors.masterProductId.message)}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" onClick={() => onConfirm()}>
                                            {t("SUBMIT")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad" className="confirm-message-model" isOpen={showConfirmMessage}>
                <ModalHeader >
                    {t("CONFIRM_MESSAGE")}
                </ModalHeader>
                <ModalBody>
                    {t("ROLLBACK_TICKET_CONFIRMATION", { value: gameRecordId })}
                </ModalBody>
                <ModalFooter>
                    <div>
                        <button type="submit" className="btn btn-block btn-brand-gradient" style={{ fontSize: "14px" }} onClick={() => closeForm()}>{t("CLOSE")} </button>
                    </div>
                    <div>
                        <button type="submit" className="btn btn-block btn-brand-gradient" style={{ fontSize: "14px" }} onClick={() => submitForm()}>{t("SUBMIT")} </button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ManageRollbackTicket;
