import React, { useState, useEffect, useCallback } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {
  ApiKey,
  ApiUrl,
  TransactionType,
  TransactionStatus,
  PanelType,
  LanguageKey,
  BankingChannelName,
  DEFAULT_PAGE_SIZE
} from "../../util/Constant";
import { setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { AsyncPaginate } from "react-select-async-paginate";

/// <summary>
/// Author : -
/// </summary>

const ServiceChargeReport = (props) => {
  const _DEPOSIT = 1;
  const _WITHDRAWAL = 2;
  const _ADJUSTMENT = 3;
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors } = useForm();
  const [calendarStartDate, setCalendarStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [calendarEndDate, setCalendarEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [withdrawalApprovalModal, setWithdrawalApprovalModal] = useState(false);
  const [auditBankOption, setAuditBankOption] = useState([]);
  const [auditBankId, setAuditBankId] = useState("");
  const [auditBankAccountId, setAuditBankAccountId] = useState("");
  const [transaction, setTransaction] = useState();
  const [bankAccountOption, setBankAccountOption] = useState([]);
  const [showTransButton, setShowTransButton] = useState(false);
  const [transactionModal, setTransactionModal] = useState(false);
  const [transactionType, setTransactionType] = useState();
  const [transactionComponent, setTransactionComponent] = useState();
  const [memberId, setMemberId] = useState();
  const [initFlag, setInitFlag] = useState(false);
  const [transactionApiUrl, setTransactionApiUrl] = useState("");
  const [receiptImages, setReceiptImages] = useState([]);
  const [currentImage, setCurrentImage] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [label, setLabel] = useState("All");
  const _SELECTED_MEMBER_KEY = "selectedMemberKey";

  const _STATUS_OPTIONS = [
    { value: TransactionStatus._OPEN, label: "Open" },
    { value: TransactionStatus._APPROVED, label: "Approved" },
    { value: TransactionStatus._REJECTED, label: "Rejected" },
  ];

  const _TRANSACTION_OPTIONS = [
    { value: TransactionType._DEPOSIT, label: "Deposit" },
  ];

  const _PANEL_OPTIONS = [
    { value: PanelType._MEMBERSITE, label: "MemberSite" },
    { value: PanelType._BACKOFFICE, label: "BackOffice" },
  ];

  const [bankingChannelOption, setBankingChannelOption] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(_STATUS_OPTIONS);
  const [selectedTransaction, setSelectedTransaction] = useState(
    _TRANSACTION_OPTIONS
  );
  const [selectedPanel, setSelectedPanel] = useState(_PANEL_OPTIONS);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [username, setUsername] = useState("");

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
    register({ name: "auditBankId" }, { required: "PLEASE_SELECT_BANK" });
    register(
      { name: "auditBankAccountId" },
      { required: "PLEASE_SELECT_BANK_ACCOUNT" }
    );
  }, [props.username]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (initFlag) {
      getUserTransaction();
    }
  }, [initFlag]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(auditBankId)) {
      getBankAccount();
    }
  }, [auditBankId]);

  /// <summary>
  /// Author : -
  /// </summary>
  async function getBankAccount() {
    var apiUrl = ApiUrl._API_GET_BANK_ACCOUNT_BY_BANK_ID;
    apiUrl += "?bankId=" + auditBankId + "&withdrawal=" + true;
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      const bankAccountList = [];
      responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
        if (bankAccount.withdrawalStatus) {
          bankAccountList.push({
            label: bankAccount.accountNumber,
            value: bankAccount.id,
          });
        }
      });

      setBankAccountOption(bankAccountList);
    } else {
      _dispatch(showMessage(false, t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }
  }

  function prepareReceiptImage(imgString) {
    if (imgString != null) {
      let imgArray = [];
      imgString = "../.." + imgString;
      imgArray.push(imgString);
      setReceiptImages(imgArray);
      openImageViewer(0);
    } else {
      _dispatch(showMessage(false, t("NO_RECEIPT_FOUND")));
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setCalendarStartDate(
      moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss")
    );
    setCalendarEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    if (props.username && props.id) {
      setUsername(props.username);
      setMemberId(props.id);
      setShowTransButton(true);
    }
    try {
      let channelResponseJson = await ApiEngine.get(
        ApiUrl._API_GET_BANKING_CHANNEL
      );
      if (channelResponseJson[ApiKey._API_SUCCESS_KEY]) {
        const bankingChannelList = [];
        channelResponseJson[ApiKey._API_DATA_KEY].forEach(function (
          bankingChannel
        ) {
          bankingChannelList.push({
            label: bankingChannel.name,
            value: bankingChannel.name,
          });
        });

        setBankingChannelOption(bankingChannelList);
      } else {
        throw channelResponseJson[ApiKey._API_MESSAGE_KEY];
      }

      let userBankResponseJson = await ApiEngine.get(ApiUrl._API_GET_USER_BANK);
      if (userBankResponseJson[ApiKey._API_SUCCESS_KEY]) {
        const bankList = [];
        userBankResponseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
          bankList.push({ label: bank.bankName, value: bank.id });
        });
        setAuditBankOption(bankList);
      } else {
        throw userBankResponseJson[ApiKey._API_MESSAGE_KEY];
      }

      setInitFlag(true);
    } catch (ex) {
      _dispatch(showMessage(false, t(ex)));
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const getUserTransaction = async () => {
    let status = [];
    let transactionType = [];
    let channel = [];
    let panel = [];
    if (!stringIsNullOrEmpty(selectedStatus)) {
      selectedStatus.map((selected) => {
        status.push(selected.value);
      });
    }
    if (!stringIsNullOrEmpty(selectedTransaction)) {
      selectedTransaction.map((selected) => {
        transactionType.push(selected.value);
      });
    }

    channel.push(BankingChannelName._CHANNEL_PAYTRUST);

    if (!stringIsNullOrEmpty(selectedPanel)) {
      selectedPanel.map((selected) => {
        panel.push(selected.value);
      });
    }

    let fetchUrl = ApiUrl._API_GET_ALL_USER_TRANSACTION;
    fetchUrl +=
      "?startDate=" +
      calendarStartDate +
      "&endDate=" +
      calendarEndDate +
      "&approvalStatus=" +
      status;
    fetchUrl +=
      "&transactionType=" +
      transactionType +
      "&bankingChannel=" +
      channel +
      "&isGatewayTrans=true" +
      "&panel=" +
      panel  +
      "&v=" +
      Date.now();
      if (username !== "") {
          fetchUrl += "&username=" + label;
      }
    setTransactionApiUrl(fetchUrl);
  };

  let _tableColumns = [
    {
      id: "id",
      Header: "ID",
      accessor: "id",
      minWidth: 100,
      Footer: () => {
        return (
          <span>
            <b>{t("TOTAL")}: </b>
          </span>
        );
      },
    },
    {
      Header: t("DATE"),
      accessor: "submitTime",
      style: {
        whiteSpace: "unset",
        wordBreak: "break-word",
      },
      Cell: ({ row }) => {
        return (
          <>{moment(row.original.submitTime).format("YYYY-MM-DD HH:mm:ss")}</>
        );
      },
      minWidth: 100,
    },
    {
      Header: t("USERNAME"),
      accessor: "memberUsername",
      minWidth: 100,
    },
    {
      Header: t("FULLNAME"),
      accessor: "memberFullName",
      minWidth: 100,
    },
    {
      Header: t("TYPE"),
      accessor: "transactionType",
      minWidth: 100,
      Cell: ({ row }) => {
        return <>{t(row.original.transactionType.toUpperCase())}</>;
      },
    },
    {
      Header: t("STATUS"),
      accessor: "approvalStatusString",
      Cell: ({ row }) => {
        switch (row.original.approvalStatus) {
          case TransactionStatus._APPROVED:
            return (
              <span className="badge badge-secondary badge-green">
                {t("APPROVED")}
              </span>
            );
            break;
          case TransactionStatus._REJECTED:
            return (
              <span className="badge badge-secondary badge-danger">
                {t("REJECTED")}
              </span>
            );
            break;
          default:
            return (
              <span className="badge badge-secondary badge-primary">
                {t(row.original.approvalStatusString)}
              </span>
            );
            break;
        }
      },
      minWidth: 100,
    },
    {
      id: "amount",
      Header: t("AMOUNT"),
      accessor: "amount",
      minWidth: 100,
      Cell: ({ row }) => {
        if (row.original.transactionTypeId != TransactionType._WITHDRAWAL) {
          return (
            <>
              {numberWithCurrencyFormat(
                parseFloat(row.original.amount) +
                  parseFloat(row.original.serviceCharge)
              )}
            </>
          );
        } else {
          return <></>;
        }
      },
      Footer: ({ page }) => {
        const creditSum = page.reduce((sum, currentValue) => {
          if (
            currentValue.original.amount &&
            currentValue.original.transactionTypeId !=
              TransactionType._WITHDRAWAL
          ) {
            return (
              sum +
              parseFloat(currentValue.original.amount) +
              parseFloat(currentValue.original.serviceCharge)
            );
          } else {
            return sum;
          }
        }, 0);
        return (
          <span>
            <b>{numberWithCurrencyFormat(parseFloat(creditSum))}</b>
          </span>
        );
      },
    },
    {
      id: "credit",
      Header: t("CREDIT"),
      accessor: "amount",
      minWidth: 100,
      Cell: ({ row }) => {
        if (row.original.transactionTypeId != TransactionType._WITHDRAWAL) {
          return (
            <>{numberWithCurrencyFormat(parseFloat(row.original.amount))}</>
          );
        } else {
          return <></>;
        }
      },
      Footer: ({ page }) => {
        const creditSum = page.reduce((sum, currentValue) => {
          if (
            currentValue.original.amount &&
            currentValue.original.transactionTypeId !=
              TransactionType._WITHDRAWAL
          ) {
            return sum + parseFloat(currentValue.original.amount);
          } else {
            return sum;
          }
        }, 0);
        return (
          <span>
            <b>{numberWithCurrencyFormat(parseFloat(creditSum))}</b>
          </span>
        );
      },
    },
    {
      id: "serviceChargePercentage",
      Header: t("SERVICE_CHARGE_PERCENTAGE"),
      accessor: "serviceCharge",
      minWidth: 100,
      Cell: ({ row }) => {
        return (
          <>
            {numberWithCurrencyFormat(
              parseFloat(row.original.serviceChargePercentage)
            )}
          </>
        );
      },
      Footer: "",
    },
    {
      id: "serviceCharge",
      Header: t("SERVICE_CHARGE"),
      accessor: "serviceCharge",
      minWidth: 100,
      Cell: ({ row }) => {
        return (
          <>
            {numberWithCurrencyFormat(parseFloat(row.original.serviceCharge))}
          </>
        );
      },
      Footer: ({ page }) => {
        const creditSum = page.reduce((sum, currentValue) => {
          if (
            currentValue.original.serviceCharge &&
            currentValue.original.transactionTypeId !=
              TransactionType._WITHDRAWAL
          ) {
            return sum + parseFloat(currentValue.original.serviceCharge);
          } else {
            return sum;
          }
        }, 0);
        return (
          <span>
            <b>{numberWithCurrencyFormat(parseFloat(creditSum))}</b>
          </span>
        );
      },
    },
    {
      Header: t("CHANNEL"),
      accessor: "channel",
      minWidth: 100,
    },
    {
      Header: t("PANEL"),
      accessor: "panel",
      Cell: ({ row }) => {
        return <>{t(row.original.panel.toUpperCase())}</>;
      },
      minWidth: 100,
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    setAuditBankAccountId("");
    setAuditBankId("");
    register({ name: "auditBankId" }, { required: "PLEASE_SELECT_BANK" });
    register(
      { name: "auditBankAccountId" },
      { required: "PLEASE_SELECT_BANK_ACCOUNT" }
    );
  }, [withdrawalApprovalModal]);

    useEffect(() => {
        sessionStorage.setItem(_SELECTED_MEMBER_KEY, username);
    }, [username]);

    async function getMemberList(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(
            `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
            }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY]["data"];
            let formattedData = data.map((m) => {
                return { label: m.username, value: m.id };
            });

            return {
                options: formattedData,
                hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
                additional: {
                    page: page + 1,
                },
            };
        }
    }

  async function adminLog() {
    let status = [];
    let transactionType = [];
    let channel = [];
    let panel = [];
    if (!stringIsNullOrEmpty(selectedStatus)) {
      selectedStatus.map((selected) => {
        status.push(selected.value);
      });
    }
    if (!stringIsNullOrEmpty(selectedTransaction)) {
      selectedTransaction.map((selected) => {
        transactionType.push(selected.value);
      });
    }

    channel.push(BankingChannelName._CHANNEL_PAYTRUST);

    if (!stringIsNullOrEmpty(selectedPanel)) {
      selectedPanel.map((selected) => {
        panel.push(selected.value);
      });
    }

    let params = {
      "startDate": calendarStartDate,
      "endDate": calendarEndDate,
      "approvalStatus": status,
      "transactionType": transactionType,
      "bankingChannel": channel,
      "isGatewayTrans": "true",
      "panel": panel
    };

    if (username !== "") {
      params["username"] = label;
    }
    _dispatch(setSaveAdminLog("VIEW_SERVICE_CHARGE_REPORT", _history.location.pathname, params));
  }
  return (
    <div>
      <h1 className="page-header">{t("TRANSACTION_REPORT")}</h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <PanelBody>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group" style={{ display: "flex" }}>
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div
                        className="input-group-append"
                        style={{ width: "100%" }}
                      >
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          alwaysShowCalendars={true}
                          timePicker={true}
                          timePickerSeconds={true}
                          onApply={handleEvent}
                          ranges={{
                            Today: [
                              moment().startOf("day"),
                              moment().endOf("day"),
                            ],
                            Yesterday: [
                              moment().subtract(1, "days").startOf("day"),
                              moment().subtract(1, "days").endOf("day"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                            "This Year": [
                              moment().startOf("year"),
                              moment().endOf("year"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={calendarStartDate + " - " + calendarEndDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="input-group mb-3"
                    style={{ flexFlow: "nowrap" }}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-eye"></i>
                      </span>
                    </div>
                    <div
                      className="input-group-append"
                      style={{ width: "100%" }}
                    >
                      <Select
                        styles={{
                          container: () => ({
                            width: "100%",
                          }),
                        }}
                        value={selectedStatus}
                        options={_STATUS_OPTIONS}
                        isMulti
                        onChange={(e) => {
                          setSelectedStatus(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div
                    className="input-group member-group mb-3"
                    style={{ flexFlow: "nowrap" }}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-user"></i>
                      </span>
                    </div>
                    <AsyncPaginate
                    placeholder={label}
                    debounceTimeout={250}
                    loadOptions={getMemberList}
                    additional={{
                      page: 1,
                    }}
                    value={username}s
                    onChange={(e) => {
                      if (!stringIsNullOrEmpty(e.value)) {
                        sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                      } else {
                        sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                      }
                      setLabel(e.label);
                      setUsername(e.value);
                    }}
                  />
                  </div>
                </div>
                <div className="col-lg-2">
                  <button
                    type="button"
                    onClick={() => {
                      getUserTransaction();
                      adminLog();
                    }}
                    className="btn btn-primary"
                  >
                    {t("SEARCH")}
                  </button>
                </div>
              </div>
              {showTransButton && (
                <div className="row m-t-5">
                  <div className="col-lg-12">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={() => {
                        setTransactionModal(true);
                        setTransactionType(_DEPOSIT);
                      }}
                    >
                      {t("DEPOSIT")}
                    </button>
                    <button
                      type="button"
                      style={{ marginLeft: "5px" }}
                      className="btn btn-purple"
                      onClick={() => {
                        setTransactionModal(true);
                        setTransactionType(_WITHDRAWAL);
                      }}
                    >
                      {t("WITHDRAWAL")}
                    </button>
                  </div>
                </div>
              )}
              <hr />
              <ReactTable
                fetchUrl={transactionApiUrl}
                columns={_tableColumns}
                renderFooter={true}
              />
            </PanelBody>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default ServiceChargeReport;
