import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { createFormBody } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>
const AccountShareHolderViewProduct = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _location = useLocation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, watch } = useForm({ mode: "onBlur" });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const [productCode, setProductCode] = useState('');
    const [status, setStatus] = useState(null);
    const [royalty, setRoyalty] = useState(0);
    const [markup, setMarkup] = useState(0);


    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);


    /// <summary>
    /// Author : -
    /// </summary>
    function init() {
        if (_location.state) {
            console.log(_location.state);
            setProductId(_location.state.id);
            setProductName(_location.state.productName);
            setProductCode(_location.state.productCode);
            setStatus(_location.state.status);

            setRoyalty(_location.state.royalty);
            setMarkup(_location.state.markup);

            /*
            setMemberId(_location.state.id);
            setUsername(_location.state.username);

            console.log(_location.state.id);
            console.log('vs');
            console.log(_location.state.referralMemberId);

            if (_location.state.id == _location.state.referralMemberId) {
                setMinPercent(5)
                setMaxPercent(100);
            }
            else {
                setMinPercent(5);
                setMaxPercent(100);
            }

            setSharePercent(_location.state.sharePercent);
            setIsShareHolder(_location.state.isShareHolder);
            setShareHolderDate(_location.state.shareHolderDate);

            if (_location.state.shareHolderDate == null) {
                setSharePercent(100);
            }*/
        }
        else {
            _history.push("/shareholder/products")
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            productId: productId,
            royalty: data.royalty,
            markup: data.markup
        };
        _dispatch(setSaveAdminLog("EDIT_SHAREHOLDER_PRODUCT",_history.location.pathname, params));
        let formBody = createFormBody(params);
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_SHAREHOLDER_PRODUCT,
            formBody
        );
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _history.push(WebUrl._URL_SHAREHOLDERS_INTERNAL_PROCESS);
        }
    };

    /// <summary>
    /// Author : -
    /// </summary>
    function preSubmit() {
        if (royalty != watch("royalty") || markup != watch("markup")) {
            setShowConfirmation(true);
        } else {
            handleSubmit(submitForm)();
        }
    }

    return (
        <div>
            {showConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="danger"
                    title={t("BONUS_COMMISSION_CHANGE_CONFIRMATION")}
                    onConfirm={() => {
                        setShowConfirmation(false);
                        handleSubmit(submitForm)();
                    }}
                    onCancel={() => {
                        setShowConfirmation(false);
                    }}
                ></SweetAlert>
            )}
            <h1 className="page-header">
                {t("SHAREHOLDER_VIEW_PRODUCT")}
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-2">
                                        <h4>{t("PRODUCT_DETAIL")}</h4>
                                    </div>
                                </div>
                                
                                
                                <div className="row">
                                    <div className="col-lg-2">
                                        <label>
                                            <b>{t("PRODUCT_NAME")}</b>
                                        </label>
                                        <input type="text" className="form-control" value={productName} disabled />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-2">
                                        <label>
                                            <b>{t("PRODUCT_CODE")}</b>
                                        </label>
                                        <input type="text" className="form-control" value={productCode} disabled />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-2">
                                        <label>
                                            <b>{t("STATUS")}</b>
                                        </label>
                                        <br/>
                                        {status == 1 ? (
                                            <span className="badge badge-secondary badge-green">
                                                {t("ACTIVE")}
                                            </span>
                                        ) : (
                                            <span className="badge badge-secondary badge-danger">
                                                {t("MAINTENANCE")}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <hr />
                                
                                <div className="row">
                                    <div className="col-lg-2">
                                        <label>
                                            <b>{t("ROYALTY")}</b>
                                        </label>

                                        <br />
                                        <input
                                            type="number"
                                            name="royalty"
                                            ref={register({
                                                required: "PLEASE_ENTER_ROYALTY",
                                                min: {
                                                    value: 0,
                                                    message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0",
                                                },
                                                max: {
                                                    value: 100,
                                                    message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                                },
                                            })}
                                            className="form-control col-lg-6 m-b-5"
                                            placeholder={t("SHARE_PERCENT")}
                                            value={royalty}
                                            onChange={(e) => setRoyalty(e.target.value)}
                                        />
                                        {errors.royalty && (
                                            <div className="invalid-feedback">
                                                {t(errors.royalty.message)}
                                            </div>
                                        )}
                                    </div>
                                </div>


                                <div className="row" style={{display:"none"}}>
                                    <div className="col-lg-2">
                                        <label>
                                            <b>{t("MARKUP")}</b>
                                        </label>

                                        <br />
                                        <input
                                            type="number"
                                            name="markup"
                                            ref={register({
                                                required: "PLEASE_ENTER_MARKUP",
                                                min: {
                                                    value: 0,
                                                    message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0",
                                                },
                                                max: {
                                                    value: 100,
                                                    message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                                },
                                            })}
                                            className="form-control col-lg-6 m-b-5"
                                            placeholder={t("SHARE_PERCENT")}
                                            value={markup}
                                            onChange={(e) => setMarkup(e.target.value)}
                                        />
                                        {errors.markup && (
                                            <div className="invalid-feedback">
                                                {t(errors.markup.message)}
                                            </div>
                                        )}
                                    </div>
                                </div>


                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button
                                                type="button"
                                                onClick={() => preSubmit()}
                                                className="btn btn-primary"
                                            >
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
};

export default withRouter(AccountShareHolderViewProduct);
