import React, { useState } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
  Status,
  DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import {
  stringIsNullOrEmpty,
  numberWithCurrencyFormat,
  isObjectEmpty,
} from "../../util/Util";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { setSaveAdminLog } from "../../redux/AppAction";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const GamePointsAdjustmentReport = (props) => {
  const _dispatch = useDispatch();
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _ALL_VALUE = -1;
  const _OVERALL_KEYS = {
    IN: "TOTAL_IN",
    OUT: "TOTAL_OUT",
  };
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [apiUrl, setApiUrl] = useState("");
  const [memberOption, setMemberOption] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [status, setStatus] = useState(_ALL_VALUE);
  const [initFinish, setInitFinish] = useState(false);
  const [overallInfo, setOverallInfo] = useState({});
  const _STATUS_OPTIONS = [
    { label: t("ALL"), value: _ALL_VALUE },
    { label: t("SUCCESS"), value: Status._ENABLED },
    { label: t("FAILED"), value: Status._DISABLED },
  ];

  const [selectedMember, setSelectedMember] = useState();

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getAdjustmentReport();
  }, [initFinish]);

  /// <summary>
  /// Author : -
  /// </summary>
  async function getAdjustmentReport() {
    let fetchUrl =
      ApiUrl._API_GET_GAME_POINTS_ADJUSTMENT_REPORT +
      "?v=" +
      Date.now() +
      "&StartDate=" +
      startDate +
      "&EndDate=" +
      endDate;

    if (!stringIsNullOrEmpty(selectedMember)) {
      fetchUrl += "&MemberId=" + selectedMember.value;
    }

    setApiUrl(fetchUrl);
  }

  let _tableColumns = [
    {
      Header: "TARGET_MEMBER",
      accessor: "memberName",
      Footer: () => {
        return (
          <span>
            <b>{t("TOTAL")}: </b>
          </span>
        );
      },
    },
    {
      Header: "POINTS_BEFORE",
      accessor: "pointsBefore",
      Cell: ({ row }) => {
        return (
          <>{numberWithCurrencyFormat(parseFloat(row.original.pointsBefore), 3)}</>
        );
      },
    },
    {
      Header: "POINTS_IN",
      accessor: "pointsIn",
      Cell: ({ row }) => {
        return (
          <>{numberWithCurrencyFormat(parseFloat(row.original.pointsIn), 3)}</>
        );
      },
      Footer: () => {
        return (
          <span className="text-success">
            <b>
              {!isObjectEmpty(overallInfo) &&
                overallInfo[_OVERALL_KEYS.IN] &&
                numberWithCurrencyFormat(
                  parseFloat(overallInfo[_OVERALL_KEYS.IN]),
                  3
                )}
            </b>
          </span>
        );
      },
    },
    {
      Header: "POINTS_OUT",
      accessor: "pointsOut",
      Cell: ({ row }) => {
        return (
          <>{numberWithCurrencyFormat(parseFloat(row.original.pointsOut), 3)}</>
        );
      },
      Footer: () => {
        return (
          <span className="text-danger">
            <b>
              {!isObjectEmpty(overallInfo) &&
                overallInfo[_OVERALL_KEYS.OUT] &&
                numberWithCurrencyFormat(
                  parseFloat(overallInfo[_OVERALL_KEYS.OUT]),
                  3
                )}
            </b>
          </span>
        );
      },
    },
    {
      Header: "POINTS_AFTER",
      accessor: "pointsAfter",
      Cell: ({ row }) => {
        return (
          <>
            {numberWithCurrencyFormat(parseFloat(row.original.pointsAfter), 3)}
          </>
        );
      },
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => {
        return row.original.status ? (
          <span className="badge badge-green">{t("SUCCESS")}</span>
        ) : (
          <span className="badge badge-danger">{t("FAILED")}</span>
        );
      },
    },
    {
      Header: "REMARK",
      accessor: "remark",
    },
    {
      Header: "OPERATED_BY",
      accessor: "operatedBy",
    },
    {
      Header: "CREATED_TIME",
      accessor: "createdTime",
      minWidth: 100,
      Cell: ({ row }) => {
        return (
          <>{moment(row.original.createdTime).format("DD-MM-YYYY HH:mm:ss")}</>
        );
      },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function getOverallInfo(info) {
    setOverallInfo(info);
  }

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  async function adminLog() {
    //ApiUrl._API_GET_GAME_POINTS_ADJUSTMENT_REPORT +
    //  "?v=" +
    //  Date.now() +
    //  "&StartDate=" +
    //  startDate +
    //  "&EndDate=" +
    //  endDate;

    //if (!stringIsNullOrEmpty(selectedMember)) {
    //  fetchUrl += "&MemberId=" + selectedMember.value;
    //}

    let params = {
      "startDate": startDate,
      "endDate": endDate
    };

    if (!stringIsNullOrEmpty(selectedMember)) {
      params["selectedMember"] = selectedMember;
    }
    _dispatch(setSaveAdminLog("VIEW_GAME_POINTS_ADJUSTMENT_REPORT", _history.location.pathname, params));
}
  return (
    <div>
      <h1 className="page-header">{t("GAME_POINTS_ADJUSTMENT_REPORT")}</h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          startDate={startDate}
                          endDate={endDate}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                          ranges={{
                            Today: [moment(), moment()],
                            Yesterday: [
                              moment().subtract(1, "days"),
                              moment().subtract(1, "days"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days"),
                              moment(),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days"),
                              moment(),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={startDate + " - " + endDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="input-group mb-3"
                    style={{ flexFlow: "nowrap" }}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-user"></i>
                      </span>
                    </div>
                    <div
                      className="input-group-append"
                      style={{ width: "100%" }}
                    >
                      <AsyncPaginate
                        styles={{
                          container: () => ({
                            width: "100%",
                          }),
                        }}
                        debounceTimeout={250}
                        isDisabled={!stringIsNullOrEmpty(props.id)}
                        loadOptions={getMemberList}
                        additional={{
                          page: 1,
                        }}
                        value={selectedMember}
                        onChange={setSelectedMember}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => { getAdjustmentReport(); adminLog(); }}
                  >
                    {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
              <ReactTable
                fetchUrl={apiUrl}
                columns={_tableColumns}
                getOverallInfo={getOverallInfo}
                renderFooter={true}
              />
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default GamePointsAdjustmentReport;
