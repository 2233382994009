
import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory, useLocation } from "react-router-dom";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { navigateTo, numberWithCurrencyFormat } from "../../util/Util";
import { useForm, Controller } from "react-hook-form";
import { createFormBody } from "../../util/Util";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { setBusy, setIdle, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { showMessage } from "../../redux/AppAction";
import ApiEngine from "../../util/ApiEngine";

import DateTime from 'react-datetime';
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";


/// <summary>
/// Author : -
/// </summary>

const ShareholdersProcessSales = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    const _location = useLocation();
    let _history = useHistory();
    const [productData, setProductData] = useState([]);
    const [categoryData, setCategoryData] = useState({});
    const [categoryTotal, setCategoryTotal] = useState({});
    const [date, setDate] = useState(moment().add(-1, "days").format("DD-MM-YYYY"));
    const [oktoprocess, setOktoprocess] = useState(true);
    const [okcount, setOkcount] = useState(0);
    const [processed, setProcessed] = useState(false);
    const { loginUserId } = useSelector((state) => ({
        loginUserId: state.authState.userData.userId,
    }));
    const { handleSubmit, control } = useForm({ mode: "onBlur" });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [initPage, setInitPage] = useState(true);


    const yesterday = moment().subtract(1, 'day');
    const disableFutureDt = current => {
        return current.isBefore(yesterday);
    };

    let _tableColumns = [
        {
            Header: "NAME",
            accessor: "productName",
            Cell: ({ row }) => (
                <div className="text-left">
                    <div>{row.original.productName}</div>
                    { row.original.id > 0 && <>
                        <div><small>Royalty: {row.original.royalty}%</small></div>
                        <div><small>Markup: {row.original.markup}%</small></div>
                    </>
                    }

                </div>
            ),
            disableSortBy: true,
        },
        {
            Header: "TURNOVER",
            accessor: "netSales",
            Cell: ({ row }) => (
                <div className="text-center">
                    <div>
                        <input type="text" className="form-control text-right" disabled value={row.original.grossSales} />
                    </div>
                </div>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },
        {
            Header: "PAYOUT",
            accessor: "payout",
            Cell: ({ row }) => (
                <div className="text-center">
                    { row.original.payout == null ?
                        <span className="badge bg-danger">NOT_SET</span>
                        :
                        <input type="text" className="form-control text-right" disabled value={row.original.payout} />
                    }
                </div>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },
        {
            Header: "NET PROFIT",
            accessor: "netProfit",
            Cell: ({ row }) => (
                <div className="text-center">

                { row.original.payout == null ? <span className="badge bg-danger">NOT_SET</span> : <input type="text" className="form-control text-right" disabled value={row.original.netProfit} />}
                </div>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    {
                        !processed && <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default">
                            <i className="fas fa-cog"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => {
                                    navigateTo(
                                        _history,
                                        WebUrl._URL_SHAREHOLDERS_PAYOUT_EDIT,
                                        row.original
                                    );
                                }}
                            >
                                <span className="text-primary">
                                    {t("EDIT_PAYOUT")}
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    }
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" },
        },
    ];

    const tableStyle = {
        border: "2px solid #000000"
    };

    const greyStyle = {
        border: "none",
        background: "lightgrey"
    };

    const lineRowStyle = {
        borderTop: "2px solid #000000",
        borderBottom: "2px solid #000000",
        fontWeight: "bold"
    };

    const leftBorderStyle = {
        border: "none",
        borderColor: "#000000",
        borderLeft: "2px solid #000000",
        textAlign: "right"
    };

    const rightBorderStyle = {
        border: "none",
        borderColor: "#000000",
        borderRight: "2px solid #000000",
        textAlign: "right"
    };

    const payoutStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "right",
        background: "#d9edf7"
    };

    const netProfitStyle = {
        border: "none",
        borderColor: "#000000",
        borderLeft: "2px solid #000000",
        textAlign: "right",
        background: "#fcf8e3"
    };

    const balanceStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "right",
        background: "#fcf8e3"
    };

    const textRightStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "right"
    };

    const textLeftStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "left"
    };

    /// <summary>
    /// Author : -
    /// </summary>
    function getProductData(date) {
        _dispatch(setBusy());
        setOkcount(0);
        _dispatch(setSaveAdminLog("VIEW_SHAREHOLDER_ADD_HUAT_VT_WINAMT", _history.location.pathname, { "date": date }));
        fetch(ApiUrl._API_GET_SHAREHOLDER_HUAT_TICKET + "?date=" + date, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setProcessed(responseJson[ApiKey._API_DATA_KEY].processed);
                    let data = responseJson[ApiKey._API_DATA_KEY]["data"];
                    setOktoprocess(true);
                    responseJson[ApiKey._API_DATA_KEY].data.map((product, index) => {
                        if (product.payout == null)
                            setOktoprocess(false);
                    });
                    setProductData(data);

                    let categoryRow = {};
                    let catTotal = {};

                    data.map((e, i) => {
                        let winLose = e.winAmount - e.betAmt;
                        let royaltyAmt = winLose * (e.royalty / 100);
                        let commissionAmt = e.validTurnover * (e.commission / 100);
                        let balanceAmt = royaltyAmt + commissionAmt;
                        var payoutAmt = e.payout;
                        let netProfitAmt = 0;
                        if (e.payout > 0) {
                            netProfitAmt = e.validTurnover;
                            netProfitAmt -= e.payout;
                        }


                        if (!categoryRow[e.categoryName]) {
                            categoryRow[e.categoryName] = {};
                            if (typeof (e.categoryName) !== "undefined") {
                                catTotal[e.categoryName] = { turnover: 0, validTurnover: 0, winlose: 0, royalty: 0, commission: 0, balance: 0, payout: 0, netProfit: 0 };
                            }
                            else {
                                catTotal[e.categoryName] = { turnover: e.grossSales, validTurnover: e.validTurnover, payout: e.payout, netProfit: e.netProfit };
                            }
                        }
                        if (e.productName !== "Settlement") {
                            if (!categoryRow[e.categoryName][e.productName]) {
                                categoryRow[e.categoryName][e.productName] = { turnover: e.grossSales, validTurnover: e.validTurnover, winlose: winLose, royalty: royaltyAmt, balance: balanceAmt, payout: payoutAmt, commission: commissionAmt, netProfit: netProfitAmt };
                            }
                        }
                        if (typeof (e.categoryName) !== "undefined") {
                            catTotal[e.categoryName].turnover += e.grossSales;
                            catTotal[e.categoryName].validTurnover += e.validTurnover;
                            catTotal[e.categoryName].winlose += winLose;
                            catTotal[e.categoryName].royalty += royaltyAmt;
                            catTotal[e.categoryName].balance += balanceAmt;
                            catTotal[e.categoryName].payout += payoutAmt;
                            catTotal[e.categoryName].commission += commissionAmt;
                            catTotal[e.categoryName].netProfit += netProfitAmt;
                        }
                    })
                    setCategoryData(categoryRow);
                    setCategoryTotal(catTotal);
                }
                setInitPage(false);
                _dispatch(setIdle());
            });
    }


    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (_location.state) {
            if (_location.state.date != "") {
                setDate(_location.state.date);
                getProductData(_location.state.date);
            }
        }
    }, []);



    function preSubmit() {
        setShowConfirmation(true);
    }

    return (
        <div>
            <h1 className="page-header">{t("SHAREHOLDERS_PRODUCTS_ADD_HUAT_TICKET")}</h1>
            <Panel>
                <PanelBody>
                    <h2>VT/WinAmt For <div
                        style={{ width: "90px", textAlign: "center", display: "inline-block" }}><Controller
                            control={control}
                            name="date"
                            render={({ onChange, value }) => (
                                <DateTime
                                    isValidDate={disableFutureDt}
                                    value={date}
                                    dateFormat="DD-MM-YYYY"
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (e instanceof moment) {
                                            setDate(e.format("DD-MM-YYYY"));
                                            getProductData(e.format("DD-MM-YYYY"));
                                            onChange(e.format("DD-MM-YYYY"));
                                        }
                                        else {
                                            onChange('');
                                        }
                                    }} />
                            )}
                            onChange={(value) => {
                                setDate(value);
                            }}
                        />
                    </div></h2>


                    {
                        !processed ? (productData.length > 0 && oktoprocess ? <div>
                        </div>
                            :
                            <div className="alert alert-warning m-b-20" role="alert">{initPage ? "Please select date to continue." : "VT/WinAmt are optional and can leave 0 if not required."}</div>) : <div className="alert alert-success m-b-20"><u><b>{date}</b></u> sales processed.</div>
                    }

                    <table key="main-table" className="table table-hover table-process" style={tableStyle}>
                        <tbody>
                            <tr style={lineRowStyle}>
                                <th style={textLeftStyle}>Name</th>
                                <th style={leftBorderStyle}>Turnover</th>
                                <th style={textRightStyle}>V.Turnover</th>
                                <th style={textRightStyle}>Win/Loss</th>
                                <th style={rightBorderStyle}></th>
                            </tr>

                            {Object.keys(categoryData) && Object.keys(categoryData).length > 0 &&
                                Object.keys(categoryData).map((categoryName, i) => {
                                    if (typeof (categoryTotal[categoryName]) !== "undefined") {
                                        return (
                                            <>
                                                {
                                                    categoryName !== "undefined" && <>
                                                        <tr key={i} style={lineRowStyle}>
                                                            <td style={textLeftStyle}>{categoryName} <span style={{float:"right"}}>TOTAL</span></td>
                                                            <td className={(categoryTotal[categoryName].turnover < 0 ? "text-red" : "")} style={leftBorderStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].turnover), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].validTurnover < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].validTurnover), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].winlose < 0 ? "text-red" : "")} style={rightBorderStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].winlose), 3, true)}</td>
                                                            <td></td>
                                                        </tr>
                                                    </>
                                                }

                                                {Object.keys(categoryData[categoryName]) && Object.keys(categoryData[categoryName]).length > 0 &&
                                                    Object.keys(categoryData[categoryName]).map((product, i) => {
                                                        return (
                                                            <tr key={i} style={greyStyle}>
                                                                <td className="process-category" style={{ textAlign: "left", paddingLeft: "20px" }}>{i+1}. {product}</td>
                                                                <td className={(categoryData[categoryName][product].turnover < 0 ? "text-red" : "")} style={leftBorderStyle}>{numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].turnover), 3, true)}</td>
                                                                <td className={(categoryData[categoryName][product].validTurnover < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].validTurnover), 3, true)}</td>
                                                                <td className={(categoryData[categoryName][product].winlose < 0 ? "text-red" : "")} style={rightBorderStyle}>{numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].winlose), 3, true)}</td>
                                                                <td style={textRightStyle}>{
                                                                    !processed && <>
                                                                        {
                                                                            categoryData[categoryName][product].payout == null ?
                                                                        <a style={{ color: "#0078ff", fontSize: "12px", fontWeight: "bold", textDecoration: "underline" }} onClick={() => {
                                                                            navigateTo(
                                                                                _history,
                                                                                WebUrl._URL_SHAREHOLDERS_HUAT_TICKET_EDIT,
                                                                                productData.filter((option) => option.productName === product)[0]
                                                                            );
                                                                        }} href="#set">Edit</a>
                                                                        : 
                                                                        <a style={{ color: "#0078ff", fontSize: "12px", fontWeight: "bold", textDecoration: "underline" }} onClick={() => {
                                                                            console.log(productData.filter((option) => option.productName === product)[0]);
                                                                            navigateTo(
                                                                                _history,
                                                                                WebUrl._URL_SHAREHOLDERS_HUAT_TICKET_EDIT,
                                                                                productData.filter((option) => option.productName === product)[0]
                                                                            );
                                                                        }} href="#set">{numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].payout), 3, true)}</a>
                                                                        }
                                                                    </>
                                                                }  {processed && numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].payout),3,true)}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                })
                            }
                        </tbody>
                    </table>

                </PanelBody>

            </Panel>
        </div>
    );
};

export default ShareholdersProcessSales;