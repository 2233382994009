import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { ApiKey, ApiUrl, LanguageKey, DEFAULT_PAGE_SIZE, } from "../../util/Constant";
import { AsyncPaginate } from "react-select-async-paginate";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { setSaveAdminLog, showMessage } from '../../redux/AppAction';
import Select from "react-select";
import { useHistory } from "react-router-dom";

const ShareholderUnclaimReportList = () => {
    const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
    var _dispatch = useDispatch();
    let _history = useHistory();
    var _username = useSelector(
        (state) => state["authState"]["userData"]["username"]
    );

    const [processedData, setProcessedData] = useState({});
    const [processedUplineTotal, setProcessedUplineTotal] = useState({});
    const [labelUsername, setLabelUsername] = useState("All");
    const [selectedMember, setSelectedMember] = useState("");
    const _SELECTED_MEMBER_KEY = "selectedMemberKey";
    const [processedGrandTotal, setProcessedGrandTotal] = useState({});
    const [temp, setTemp] = useState([]);
    const [initPage, setInitPage] = useState(true);
    const [startDate, setStartDate] = useState(moment().add(-1, "days").startOf('day').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().add(-1, "days").endOf('day').format("YYYY-MM-DD"));
    const [selectedStatus, setSelectedStatus] = useState('false');
    const _STATUS_OPTIONS = [
        { value: "", label: 'All' },
        { value: "true", label: 'Claimed' },
        { value: "false", label: 'Unclaimed' },
    ];
    const [labelStatus, setLabelStatus] = useState("Unclaimed");
    const [selectedStatusSearch, setSelectedStatusSearch] = useState('false');

    const getUnclaimDetails = async (username = null) => {
        let apiUrl = ApiUrl._API_GET_UNCLAIMED_SALES_REPORT + "?startDate=" + startDate + "&endDate=" + endDate; //"&username="
        if (!stringIsNullOrEmpty(selectedMember)) {
            apiUrl += "&username=" + selectedMember;
        }
        if (!stringIsNullOrEmpty(selectedStatus)) {
            apiUrl += "&status=" + selectedStatus;
        }
        apiUrl += "&isListAll=true";
        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            performCalculations(data, username);
            responseJson = await ApiEngine.get(ApiUrl._API_GET_DOWNLINE_BY_REFERRER_USING_USERNAME + "?username=" + username);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY];
                setTemp(data);
            }
            setSelectedStatusSearch(selectedStatus);
        }
        setInitPage(false);
    }

    const performCalculations = (data, username) => {
        setProcessedData("");
        let dataRows = {};
        let userTotal = {};
        let grandTotal = { netProfit: 0 };
        if (data["downlineData"].length != 0) {
            if (username === "Company") {
                data["downlineData"].map((downline, i) => {
                    let uplineUser = data["usernameList"][i];
                    if (!dataRows[uplineUser]) {
                        dataRows[uplineUser] = {};
                        userTotal[uplineUser] = { netProfit: 0, claimedStatus: downline.claimedStatus };
                    }

                    downline.map((player, index) => {
                        userTotal[uplineUser].netProfit += player.netProfit;
                        grandTotal.netProfit += player.netProfit;
                    })
                });
            }
            else {
                data["downlineData"].map((e, i) => {
                    console.log(e.claimedStatus)
                    if (!dataRows[e.username]) {
                        dataRows[e.username] = {};
                        userTotal[e.username] = { netProfit: 0, claimedStatus: e.claimedStatus };
                    }
                    userTotal[e.username].netProfit += e.netProfit;
                    grandTotal.netProfit += e.netProfit;
                });
            }
            setProcessedUplineTotal(userTotal);
            setProcessedData(dataRows);
            console.log(processedUplineTotal);
            setProcessedGrandTotal(grandTotal);
        }
        else {
            if (username !== null) {
                _dispatch(showMessage(false, t("NO_FURTHER_DOWNLINE_DATA_FOUND")));
            }
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const handleEvent = (event, picker) => {
        setStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
        setEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
    };

    /// <summary>
    /// Author : Wong
    /// </summary>
    async function getMemberList(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(
            `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${
            (page - 1) * DEFAULT_PAGE_SIZE
            }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY]["data"];
            let formattedData = data.map((m) => {
                return { label: m.username, value: m.id };
            });

            return {
                options: formattedData,
                hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
                additional: {
                    page: page + 1,
                },
            };
        }
    }

    async function adminLog() {
      let params = {
        "startDate": startDate,
        "endDate": endDate,
        "isListAll":"true"
      };

      if (!stringIsNullOrEmpty(selectedMember)) {
        params["username"] = selectedMember;
      }

      if (!stringIsNullOrEmpty(selectedStatus)) {
        params["status"] = selectedStatus;
      }
      _dispatch(setSaveAdminLog("VIEW_SHAREHOLDER_UNCLAIMED_SALES_REPORT_LIST", _history.location.pathname, params))
    }

    return (
        <div>
            <h1 className="page-header">{t("SHAREHOLDER_UNCLAIM_REPORT_LIST")}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div
                                        className="form-group"
                                        style={{ display: "flex", width: "100%" }}
                                    >
                                        <div className="input-group" style={{ width: "100%" }}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <DateRangePicker
                                                    containerStyles={{ width: "100%" }}
                                                    alwaysShowCalendars={true}
                                                    onApply={handleEvent}
                                                    maxDate={moment().endOf('day')}
                                                    ranges={{
                                                        'Today': [moment().startOf('day'), moment().endOf('day')],
                                                        'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                                        'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                                                        'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                        'This Year': [moment().startOf('year'), moment().endOf('year')]
                                                    }}>
                                                    <input type="text" className="form-control" value={startDate + " - " + endDate} />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <AsyncPaginate
                                            placeholder={labelUsername}
                                            debounceTimeout={250}
                                            loadOptions={getMemberList}
                                            additional={{
                                                page: 1,
                                            }}
                                            value={selectedMember}
                                            onChange={(e) => {
                                                if (!stringIsNullOrEmpty(e.value)) {
                                                    sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                                                } else {
                                                    sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                                                }
                                                setLabelUsername(e.label);
                                                setSelectedMember(e.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div
                                        className="form-group"
                                        style={{ display: "flex", width: "100%" }}
                                    >
                                        <div
                                            className="input-group"
                                            style={{ width: "100%", flexFlow: "nowrap" }}
                                        >
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-tag"></i>
                                                </span>
                                            </div>
                                            <div style={{ width: "70%" }}>
                                                <Select
                                                    styles={{
                                                        container: () => ({
                                                            width: "100%"
                                                        })
                                                    }}
                                                    value={selectedStatus}
                                                    options={_STATUS_OPTIONS}
                                                    onChange={(e) => {
                                                        setSelectedStatus(e.value)
                                                        setLabelStatus(e.label)
                                                    }}
                                                    placeholder={labelStatus}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => { getUnclaimDetails(); adminLog(); }}
                                    >
                                        {t("SEARCH")}
                                    </button>
                                </div>
                            </div>
                            {Object.keys(processedData).length > 0 && <>
                                <hr />
                                <div className="card-table table-responsive">
                                    <table className="table table-kpi-summary">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "left" }}>{t("USERNAME")}</th>
                                                <th style={{ textAlign: "center" }}>{t("CLAIMED_STATUS")}</th>
                                                <th style={{ textAlign: "right" }}>{t("NET_PROFIT")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(processedData).map((_username, i) => {
                                                    if (typeof (processedUplineTotal[_username]) !== "undefined") {
                                                        return (
                                                            <>
                                                                <tr key={i}>
                                                                    <td>{_username}</td>
                                                                    <td style={{ textAlign: "center" }}>{stringIsNullOrEmpty(selectedStatusSearch) ? t("ALL") : processedUplineTotal[_username].claimedStatus == true ? t("CLAIMED") : t("NOT_CLAIMED")}</td>
                                                                    <td className={processedUplineTotal[_username].netProfit < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUplineTotal[_username].netProfit), 3, true)}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                })
                                            }
                                            <tr>
                                                <td>{t("GRAND_TOTAL")}</td>
                                                <td></td>
                                                <td className={processedGrandTotal.netProfit < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedGrandTotal.netProfit), 3, true)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            }
                            {Object.keys(processedData).length == 0 && <>
                                <hr />
                                <div className="card-table table-responsive">
                                    <table className="table table-kpi-summary">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "left" }}>{t("USERNAME")}</th>
                                                <th style={{ textAlign: "right" }}>{t("NET_PROFIT")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="2">{initPage ? t("SELECT_DATE_AND_CONTINUE") : t("NO_REPORT_FOR_THE_DATE")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            }
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default ShareholderUnclaimReportList;