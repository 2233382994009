import React, { useEffect, useState } from "react";
import { ApiUrl, ApiKey, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { createFormBody } from "../../util/Util";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : Wong
/// </summary>
const ManageSystemSetting = () => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const { register, handleSubmit, errors, setValue } = useForm();
  const _dispatch = useDispatch();
  let _history = useHistory();
  const [systemSetting, setSystemSetting] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState();

  const _COLUMNS = [
    {
      Header: "KEY",
      accessor: "key",
      disableSortBy: true,
    },
    {
      Header: "VALUE",
      accessor: "value",
      disableSortBy: true,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <button
          className="btn btn-primary"
          onClick={() => setSelectedSetting(row.original)}
        >
          {t("EDIT")}
        </button>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : Wong
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : Wong
  /// </summary>
  const init = async () => {
    await ApiEngine.get(ApiUrl._API_GET_SYSTEM_SETTINGS).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        setSystemSetting(response[ApiKey._API_DATA_KEY]);
      }
    });
  };

  /// <summary>
  /// Author : Wong
  /// </summary>
  const submitForm = async (data) => {
    await ApiEngine.post(
      ApiUrl._API_UPDATE_SYSTEM_SETTING,
      createFormBody({ value: data.settingValue, id: selectedSetting.id })
    ).then((response) => {
      _dispatch(
        showMessage(
          response[ApiKey._API_SUCCESS_KEY],
          t(response[ApiKey._API_MESSAGE_KEY])
        )
      );

      if (response[ApiKey._API_SUCCESS_KEY]) {
        setSelectedSetting(null);
        init();
      }
    });

    let params = {
      id: selectedSetting.id,
      value: data.settingValue
    };
    _dispatch(setSaveAdminLog("EDIT_BO_SYSTEM_SETTINGS", _history.location.pathname, params));
  };

  return (
    <>
      <div>
        <h1 className="page-header">{t("MANAGE_SYSTEM_SETTING")}</h1>
        <Panel>
          <PanelBody>
            <ReactTable data={systemSetting} columns={_COLUMNS} />
          </PanelBody>
        </Panel>
      </div>
      <Modal isOpen={selectedSetting != null} toggle={setSelectedSetting}>
        <ModalHeader>{t("EDIT")}</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="form-group row m-b-15">
              <label className="col-md-4 col-form-label">{t("KEY")}</label>
              <div className="col-md-7">
                <input
                  type="text"
                  disabled
                  name="settingKey"
                  defaultValue={selectedSetting?.key}
                  className="form-control"
                  ref={register}
                />
              </div>
            </div>
            <div className="form-group row m-b-15">
              <label className="col-md-4 col-form-label">{t("VALUE")}</label>
              <div className="col-md-7">
                <input
                  name="settingValue"
                  id="settingValue"
                  className="form-control"
                  defaultValue={selectedSetting?.value}
                  ref={register({
                    required: "REQUIRED_FIELD",
                  })}
                />
                {errors.settingValue && (
                  <div className="invalid-feedback">
                    {t(errors.settingValue.message)}
                  </div>
                )}
              </div>
            </div>
            <button
              className="btn btn-default mr-2"
              type="button"
              onClick={() => setSelectedSetting(null)}
            >
              {t("CANCEL")}
            </button>
            <button className="btn btn-success" type="submit">
              {t("SUBMIT")}
            </button>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ManageSystemSetting;
