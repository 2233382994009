import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { createFormBody, navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  ContentTypeString,
  LanguageKey,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ConfigurationLanguageDetail from "./ConfigurationLanguageDetail";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import { setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>
const ManageGamePointContentLanguage = (props) => {
  const { handleSubmit, errors, register, content, setValue, clearErrors } = useForm();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [data, setData] = useState([]);
  const _dispatch = useDispatch();
  const [
    languageDetailModalVisibility,
    setLanguageDetailModalVisibility,
  ] = useState(false);
  let _history = useHistory();
  const _EXCLUDE_TITLE_TYPE = [
    ContentTypeString._VIP_BENEFIT_CONTENT,
    ContentTypeString._VIP_CRITERIA,
  ];
  const _EXCLUDE_CONTENT_TYPE = [
    ContentTypeString._VIP_BENEFIT,
    ContentTypeString._PRODUCT_CATEGORY,
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [languageDetailId, setLanguageDetailId] = useState("");
  const [tableColumns, setTableColumns] = useState([]);
  const [text, setText] = useState("");
  const [languageOption, setLanguageOption] = useState([]);
  const [platformOption, setPlatformOption] = useState("");
  const [languageId, setLanguageId] = useState("");
  const [gamePointContentData, setGamePointContentData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editPlatformId, setEditPlatformId] = useState("");
  const [editLanguageId, setEditLanguageId] = useState("");
  const [gamePointLanguageId, setGamePointLanguageId] = useState("");
  const [editContent, setEditContent] = useState("");
  let _tableColumns = [
    {
      Header: "LANGUAGE",
      accessor: "languageName",
      disableSortBy: true,
    },
    {
      Header: "CONTENT",
      accessor: "content",
      disableSortBy: true,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  setEditModal(true);
                  setGamePointLanguageId(row.original.id);
                  setEditPlatformId(row.original.platformId);
                  setEditLanguageId(row.original.languageId);
                  setEditContent(row.original.content);
                }}
              >
                <span className="text-warning">{t("EDIT")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];
  const onSubmit = async (data) => {

    let params = {
      platformId: props.id,
      languageId: languageId,
      content: data.text
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_GAME_POINT_CONTENT_LANGUAGE,
      createFormBody(params)
    );
    _dispatch(setSaveAdminLog("CREATE_" + props.type + "_Language", _history.location.pathname, params));
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
    setIsOpen(false);
    getGamePointContent();
  };
  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getConfigLanguage();
    getLanguage();
    getPlatform();
    getGamePointContent();
    /// Used to Exclude the column of table
    if (_EXCLUDE_CONTENT_TYPE.includes(props.type)) {
      _tableColumns.splice(1, 1);
    } else if (_EXCLUDE_TITLE_TYPE.includes(props.type)) {
      _tableColumns.splice(2, 1);
    }
    setTableColumns(_tableColumns);
  }, []);

  async function getLanguage() {
    await fetch(ApiUrl._API_GET_LANGUAGE, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const languageList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (language) {
            languageList.push({ label: language.name, value: language.id });
          });
          setLanguageOption(languageList);
        }
      });
  }
  async function getPlatform() {
    await fetch(ApiUrl._API_GET_GAME_POINT_CONTENT, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          var id = props.id - 1;
          console.log(responseJson[ApiKey._API_DATA_KEY]);
          console.log(id);
          setPlatformOption(responseJson[ApiKey._API_DATA_KEY][id]['platform']);
        }
      });
  }
  /// <summary>
  /// Author : -
  /// </summary>
  const getConfigLanguage = async () => {
    await fetch(
      ApiUrl._API_GET_CONFIG_LANGUAGE_BY_TYPE_ID +
      "?type=" +
      props.type +
      "&id=" +
      props.id,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  };
  async function getGamePointContent() {

    var apiUrl = ApiUrl._API_GET_GAME_POINT_LANGUAGE;
    apiUrl += "?id=" + props.id
    fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setGamePointContentData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });

  }

  const editSubmit = async (data) => {

    let params = {
      gamePointId: gamePointLanguageId,
      platformId: editPlatformId,
      languageId: editLanguageId,
      content: data.text
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_GAME_POINT_LANGUAGE_BY_ID,
      createFormBody(params)
    );
    _dispatch(setSaveAdminLog("EDIT_" + props.type + "_Language", _history.location.pathname, params));
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );

    setEditModal(false);
    getGamePointContent();
  };


  return (
    <div>
      <Panel>
        <PanelBody>
          <button
            type="button"
            onClick={() => {
              setLanguageDetailId("");
              setIsOpen(true);
            }}
            className="btn btn-purple"
          >
            {t("ADD_LANGUAGE")}
          </button>
          <ReactTable data={gamePointContentData} columns={_tableColumns} minRows={1} />
        </PanelBody>
      </Panel>
      <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={isOpen} centered>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            {t("ADD_LANGUAGE")}
          </ModalHeader>
          <ModalBody style={{ minHeight: "30vh" }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("PLATFORM")}</b>
                  </label>
                  <div className="col-md-7">
                    <label>
                      {platformOption}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("LANGUAGE")}</b>
                  </label>
                  <div className="col-md-7">
                    <Select
                      name="languageId"
                      options={languageOption}
                      placeholder={
                        languageOption.filter(
                          (option) => option.value == languageId
                        )[0] !== undefined
                          ? languageOption.filter(
                            (option) =>
                              option.value == languageId
                          )[0].label
                          : ""
                      }
                      value={languageOption.filter(
                        (option) => option.value == languageId
                      )}
                      onChange={(e) => {
                        setLanguageId(e.value);
                        setValue("languageId", e.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("CONTENT")}</b>
                  </label>
                  <div className="col-md-7">
                    <textarea
                      className="form-control"
                      rows="5"
                      style={{ marginTop: "10px" }}
                      ref={register({ required: "PLEASE_ENTER_TEXT" })}
                      name="text"
                      defaultValue={text}
                    ></textarea>
                    {errors.content && (
                      <div className="invalid-feedback">
                        {t(errors.content.message)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-white"
              onClick={() => setIsOpen(false)}
            >
              {t("CLOSE")}
            </button>
            <button type="submit" className="btn btn-primary">
              {t("SUBMIT")}
            </button>
          </ModalFooter>
        </form>
      </Modal>
      <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={editModal} centered>

        <form onSubmit={handleSubmit(editSubmit)}>
          <ModalHeader>
            {t("EDIT_LANGUAGE")}
          </ModalHeader>
          <ModalBody style={{ minHeight: "30vh" }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("PLATFORM")}</b>
                  </label>
                  <div className="col-md-7">
                    <label>
                      {platformOption}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("LANGUAGE")}</b>
                  </label>
                  <div className="col-md-7">
                    <Select
                      name="editLanguageId"
                      options={languageOption}
                      placeholder={
                        languageOption.filter(
                          (option) => option.value == editLanguageId
                        )[0] !== undefined
                          ? languageOption.filter(
                            (option) =>
                              option.value == editLanguageId
                          )[0].label
                          : ""
                      }
                      defaultValue={languageOption.filter(
                        (option) => option.value == editLanguageId
                      )}
                      value={languageOption.filter(
                        (option) => option.value == editLanguageId
                      )}
                      onChange={(e) => {
                        setEditLanguageId(e.value);
                        setValue("editLanguageId", e.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("CONTENT")}</b>
                  </label>
                  <div className="col-md-7">
                    <textarea
                      className="form-control"
                      rows="5"
                      style={{ marginTop: "10px" }}
                      ref={register({ required: "PLEASE_ENTER_TEXT" })}
                      name="text"
                      defaultValue={editContent}
                    ></textarea>
                    {errors.content && (
                      <div className="invalid-feedback">
                        {t(errors.editContent.message)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-white"
              onClick={() => setEditModal(false)}
            >
              {t("CLOSE")}
            </button>
            <button type="submit" className="btn btn-primary">
              {t("SUBMIT")}
            </button>
          </ModalFooter>
        </form>
      </Modal>

    </div>

  );
};

export default ManageGamePointContentLanguage;
